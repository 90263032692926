@import '../../../assets/style/_variables.less';

.times-up {
    text-align: center;
    max-width: 95%;
    margin: 0px auto;

    h2 {
        color: #FA4B3F;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 48px;
        text-align: center;
        margin: 48px auto 0 auto;
    }

    p {
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 48px;
        color: #000000;
        margin: 24px auto;
    }

    .answer-crct {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #000000;
    }

    @media screen and (max-width:500px) {
        h2 {
            font-size: 24px;
            line-height: 24px;
            margin: 24px auto 0 auto;
        }

        p {
            font-size: 24px;
            line-height: 24px;
            margin: 24px auto;

            img {
                max-width: 30px;
            }
        }

        .answer-crct {
            font-size: 18px;
            line-height: 20px;
        }
    }
}

.correct-answer {
    text-align: center;
    max-width: 95%;
    margin: 0px auto;

    h2 {
        color: #57D22C;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 48px;
        text-align: center;
        margin: 48px auto 0 auto;
    }

    p {
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 48px;
        color: #000000;
        margin: 24px auto;
    }

    @media screen and (max-width:500px) {
        h2 {
            font-size: 24px;
            line-height: 24px;
            margin: 24px auto 0 auto;
        }

        p {
            font-size: 24px;
            line-height: 24px;
            margin: 24px auto;

            img {
                max-width: 30px;
            }
        }

        .answer-crct {
            font-size: 18px;
            line-height: 20px;
        }
    }
}

.incorect-answer {
    text-align: center;
    max-width: 95%;
    margin: 0px auto;

    h2 {
        color: #FA4B3F;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 48px;
        text-align: center;
        margin: 48px auto 0 auto;
    }

    p {
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 48px;
        color: #000000;
        margin: 24px auto;
    }

    .answer-crct {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #000000;
    }

    @media screen and (max-width:500px) {
        h2 {
            font-size: 24px;
            line-height: 24px;
            margin: 24px auto 0 auto;
        }

        p {
            font-size: 24px;
            line-height: 24px;
            margin: 24px auto;

            img {
                max-width: 30px;
            }
        }

        .answer-crct {
            font-size: 18px;
            line-height: 20px;
        }
    }
}