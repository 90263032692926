@import '../../../../assets/style/_variables.less';

.section-bmc {
    width: 100%;
    margin: 0px auto;
    font-family: @font-family;
    overflow: hidden;

    h2 {
        font-weight: bold;
        font-size: 48px;
        line-height: normal;
        max-width: 1200px;
        width: 98%;
        text-align: center;
        margin: 48px auto 96px auto;

        @media screen and (max-width: 720px) {
            font-size: 30px;
            margin: 48px auto;
            width: 95%;
        }

        @media screen and (max-width: 500px) {
            font-size: 20px;
            margin: 24px auto;
            width: 90%;
        }
    }

    .section-bmc-holder {
        width: 100%;
        display: block;
        margin: 0px auto;
        text-align: center;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 900px;
        margin-bottom: 20px;
        cursor: pointer;
        animation-duration: 2s;
        animation-fill-mode: both;
        margin-bottom: 144px;

        .just-for-anime {
            animation-name: bounceInLeft;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-delay: 2s;
            animation-direction: alternate;
        }

        .sequence-board-info-talk-mentor {
            display: inline-grid;
            grid-template-columns: auto 1fr;
            grid-gap: 5px;
            font-family: @font-family;
            font-size: 18px;
            width: 100%;
            margin-bottom: 24px;
            justify-content: center;
            align-content: center;
            align-items: center;
            max-width: 600px;
            margin-top: 48px;

            .avatars-backg-holder {
                width: 120px;
                height: 120px;
                max-width: 550px;
                display: block;
                display: grid;
                padding-top: 17px;
                border-radius: 20px;
                margin: 0px auto;
                margin-right: 19px;
                grid-column: 1;
                align-items: end;
                animation-name: bounceInLeft;
                animation-duration: 1s;
                animation-fill-mode: both;
                animation-delay: 1s;
                animation-direction: alternate;

                img {
                    width: 100%;
                    max-width: 80px;
                    position: relative;
                }
            }

            .hi-msg {
                grid-column: 0.5;
                text-align: left;
                background-color: #7b35df;
                color: #ffffff;
                padding: 24px;
                border-top-left-radius: 26px;
                border-top-right-radius: 26px;
                border-bottom-right-radius: 26px;
                max-width: -moz-fit-content;
                max-width: 660px;
                align-self: center;
                display: grid;
                align-items: center;
                animation-name: bounceInLeft;
                animation-duration: 1s;
                animation-fill-mode: both;
                animation-delay: 1s;
                animation-direction: alternate;
            }
        }

        label {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 18px;
            text-transform: uppercase;
            color: #C0B2D4;
            text-align: left;
        }

        .input-holder-text {
            max-width: 450px;
            margin: 0px auto;
            text-align: left;

            @media screen and (max-width: 500px) {
                max-width: 300px;
            }
        }

        .text-area {
            border: 1px solid #7b35df;
            border-radius: 12px;
            color: #7b35df;
            font-weight: bold;
            font-size: 20px;
            line-height: 20px;
            padding: 20px 20px 0px 20px;
            margin-top: 8px;

            &:focus::-webkit-input-placeholder {
                color: transparent;
            }

            &:focus:-moz-placeholder {
                color: transparent;
            }

            /* FF 4-18 */
            &:focus::-moz-placeholder {
                color: transparent;
            }

            /* FF 19+ */
            &:focus:-ms-input-placeholder {
                color: transparent;
            }
        }

        ::-webkit-input-placeholder {
            color: #7b35df;
        }

        ::-moz-placeholder {
            color: #7b35df;
        }

        :-ms-input-placeholder {
            color: #7b35df;
        }

        .hint-modal-holder-bmc {
            display: flex;
            justify-content: center;
            -webkit-animation-name: bounceIn;
            -moz-animation-name: bounceIn;
            -o-animation-name: bounceIn;
            animation-name: bounceIn;
            animation-delay: 1s;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-direction: alternate;
            display: flex;
            justify-content: center;
            align-content: center;

            .signUp {
                background: #EBC336;
                border: 1px solid #EBC336;
                height: 90px;
                min-width: 90px;
                border-radius: 50%;
                font-size: 35px;
                font-weight: bold;
                position: relative;

                img {
                    max-width: 60px;
                }

                span {
                    position: relative;
                    top: -5px;
                }

                &:hover,
                &:focus {
                    background: #EBC336 !important;
                    border: 1px solid #EBC336;
                    height: 90px;
                    min-width: 90px;
                    border-radius: 50%;
                    font-size: 35px;
                    font-weight: bold;
                    position: relative;
                    cursor: pointer;
                    color: white !important;
                }
            }
        }
    }

    .button-landing {
        text-align: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: white;
        padding-bottom: 15px;
        padding-top: 15px;
        border-top: 1px solid #d4d4d4;
        right: 0;
        margin-bottom: @layout-footer-height;

        .buttonMakeAcc {
            color: white;
            background-color: #7b35df;
            font-weight: bold;
            cursor: pointer;
            border: 1px solid #7b35df;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
            border-radius: 7px;
            margin: 5px auto;
            padding: 10px 15px;
            height: auto;
            width: auto;
            min-width: 150px;
            font-size: 20px;
            text-transform: uppercase;

            &:disabled {
                border: none !important;
            }
        }

        @media screen and (max-width: 600px) {
            padding-bottom: 7px;
            padding-top: 7px;

            .signIn {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }

            .buttonMakeAcc {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }
        }

        .buttonMakeAcc {
            margin-right: 10px;
        }
    }
}