@import '../../../assets/style/_variables.less';

.section-avatars {
  width: 100%;
  margin: 0px auto;
  .toolText {
    position: relative;
    .tooltipShow {
        display: none;
        position: absolute;
        top: -90px;
        right: 10px;
        color: white;
        background: #7b35df;
        padding: 5px;
        border-radius: 5px;
        font-size: 13px;
        width: 90%;
        max-width: 350px;
    }
  }
  .hoverme {
    position: relative;
    border-bottom: 1px solid black;
  }
  .hoverme:hover +.tooltipShow {
      display: block;
  }

  .section-one-avatars {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    // display: grid;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 900px;
    margin-bottom: 20px;
    cursor: pointer;
    animation-duration: 2s;
    animation-fill-mode: both;
    margin-bottom: @layout-footer-height * 2 + 94px;

    @media screen and (max-width: 600px) {
      margin-bottom: @layout-footer-height * 2 + 59px;
    }

    @media screen and (max-width: 600px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: normal;
      font-family: @font-family;
      max-width: 1200px;
      width: 98%;
      text-align: center;
      margin: 96px auto;
      -webkit-animation-name: bounceInLeft;
      -moz-animation-name: bounceInLeft;
      -o-animation-name: bounceInLeft;
      animation-name: bounceInLeft;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;

      @media screen and (max-width: 720px) {
        font-size: 30px;
      }

      @media screen and (max-width: 500px) {
        font-size: 24px;
        margin: 48px auto;
      }
    }

    .avatars-holder {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .avatars-backg-holder {
        min-height: 204px;
        height: 100%;
        -webkit-animation-name: bounceInRight;
        -moz-animation-name: bounceInRight;
        -o-animation-name: bounceInRight;
        animation-name: bounceInRight;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }

      .selected {
        background: @primary-color;
        width: 100%;
        max-width: 204px;
        margin: 5px;
        border-radius: 26px;
        border: 1px solid @primary-color;
        display: flex;
        justify-content: center;
        padding-top: 27px;
        position: relative;
        min-height: 204px;
        height: 100%;

        img {
          max-width: 147px;
          position: absolute;
          bottom: 0;
        }
      }
    }


  }

  .button-landing {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    padding-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid #d4d4d4;
    right: 0;
    margin-bottom: @layout-footer-height;

    @media screen and (max-width: 600px) {
      padding-bottom: 7px;
      padding-top: 7px;

      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }
  }

  .fadeInBottom {
    animation-name: fadeInBottom;
  }

  @keyframes fadeInBottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }

    to {
      opacity: 1;
    }
  }


  @-webkit-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
    }

    60% {
      -webkit-transform: translateX(20px);
    }

    80% {
      -webkit-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -moz-transform: translateX(-2000px);
    }

    60% {
      -moz-transform: translateX(20px);
    }

    80% {
      -moz-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -o-transform: translateX(-2000px);
    }

    60% {
      opacity: 1;
      -o-transform: translateX(20px);
    }

    80% {
      -o-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      transform: translateX(-2000px);
    }

    60% {
      transform: translateX(20px);
    }

    80% {
      transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }



  @-webkit-keyframes bounceInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
    }

    60% {
      -webkit-transform: translateX(-20px);
    }

    80% {
      -webkit-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInRight {
    0% {
      opacity: 0;
      -moz-transform: translateX(2000px);
    }

    60% {
      -moz-transform: translateX(-20px);
    }

    80% {
      -moz-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInRight {
    0% {
      opacity: 0;
      -o-transform: translateX(2000px);
    }

    60% {
      -o-transform: translateX(-20px);
    }

    80% {
      -o-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInRight {
    0% {
      opacity: 0;
      transform: translateX(2000px);
    }

    60% {
      transform: translateX(-20px);
    }

    80% {
      transform: translateX(5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

}