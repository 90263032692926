@import "../../../assets/style/_variables.less";

.salary-mode {
  width: 100%;
  margin: 0px auto;
  .toolText {
    position: relative;
    z-index: 11111;
    .tooltipShow {
      display: none;
      position: absolute;
      top: -70px;
      right: 15%;
      color: white;
      background: #7b35df;
      padding: 5px;
      border-radius: 5px;
      font-size: 13px;
      width: 90%;
      max-width: 350px;
      @media screen and (max-width: 500px) {
        right: 20px;
      }
    }
  }
  .hoverme {
    position: relative;
    border-bottom: 1px solid black;
    cursor: pointer;
  }
  .hoverme:hover + .tooltipShow {
    display: block;
  }

  .salary-mode-section {
    width: 95%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: @layout-footer-height * 2 + 94px;

    @media screen and (max-width: 800px) {
      margin-bottom: @layout-footer-height * 2 + 59px;
    }

    @media screen and (max-width: 800px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: normal;
      font-family: @font-family;
      max-width: 950px;
      text-align: center;
      margin: 48px auto;
      -webkit-animation-name: bounceIn;
      -moz-animation-name: bounceIn;
      -o-animation-name: bounceIn;
      animation-name: bounceIn;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;

      @media screen and (max-width: 800px) {
        font-size: 30px;
        margin: 56px auto;

        img {
          max-width: 30px !important;
          margin-left: -10px !important;
        }
      }

      @media screen and (max-width: 500px) {
        font-size: 22px;
        margin: 24px auto;

        img {
          max-width: 20px !important;
          margin-left: -10px !important;
        }
      }

      img {
        max-width: 50px;
        margin-left: -20px;
      }
    }

    p {
      font-size: 28px;
      margin-bottom: 0px;

      @media screen and (max-width: 800px) {
        font-size: 20px;
      }

      @media screen and (max-width: 700px) {
        font-size: 18px;
      }
    }

    .button-landing {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;
      right: 0;
      margin-bottom: @layout-footer-height;

      @media screen and (max-width: 800px) {
        padding-bottom: 7px;
        padding-top: 7px;
      }

      .signIn {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-duration: 0.5s;
        animation-delay: 1.5s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }

      .buttonMakeAcc {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-duration: 0.5s;
        animation-delay: 1.5s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }
    }

    .button-salary-holder {
      animation-duration: 2s;
      animation-fill-mode: both;

      @media screen and (max-width: 800px) {
        padding: 10px 10px;
      }

      button {
        display: block;
        margin: 0px auto;
        // max-width: 430px !important;
        max-width: 740px;
        width: 100%;
        padding: 24px 0px;
        border-radius: 32px;
        box-shadow: 0 10px 0 #5825a1, 0 7px 7px 1px rgba(0, 0, 0, 0.4);
        transition: all 0.15s ease-in-out;
        font-family: Ubuntu, monospace;
        text-transform: none;
        font-size: 36px;
        line-height: 48px;
        font-weight: bold;
        -webkit-animation-name: bounceInLeft;
        -moz-animation-name: bounceInLeft;
        -o-animation-name: bounceInLeft;
        animation-name: bounceInLeft;
        animation-duration: 1s;
        animation-delay: 1s;
        animation-fill-mode: both;
        animation-direction: alternate;

        @media screen and (max-width: 800px) {
          font-size: 27px;
          padding: 15px 0px;
        }

        @media screen and (max-width: 500px) {
          font-size: 14px;
        }
      }

      button:hover {
        background-color: #57d22c !important;
        box-shadow: 0 10px 0 #46ae22, 0 7px 7px 1px rgba(0, 0, 0, 0.4);
        border-color: #57d22c;
        color: white !important;
      }

      .button-selected {
        display: block;
        margin: 0px auto;
        // max-width: 430px !important;
        width: 100%;
        padding: 24px 0px;
        border-radius: 32px;
        box-shadow: 0 10px 0 #46ae22;
        background: #57d22c !important;
        color: white !important;
        border: 1px solid #46ae22;
        transition: all 0.15s ease-in-out;
        font-family: Ubuntu, monospace;
        text-transform: none;
        font-size: 36px;
        line-height: 48px;
        font-weight: bold;
        height: auto;

        @media screen and (max-width: 800px) {
          font-size: 27px;
          padding: 15px 0px;
        }

        @media screen and (max-width: 500px) {
          font-size: 14px;
        }
      }

      button.margin-b {
        margin-bottom: 47px;

        @media screen and (max-width: 600px) {
          margin-bottom: 27px;
        }
      }
    }
  }

  @-webkit-keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.3);
    }

    50% {
      -webkit-transform: scale(1.05);
    }

    70% {
      -webkit-transform: scale(0.9);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }

  @-moz-keyframes bounceIn {
    0% {
      opacity: 0;
      -moz-transform: scale(0.3);
    }

    50% {
      -moz-transform: scale(1.05);
    }

    70% {
      -moz-transform: scale(0.9);
    }

    100% {
      opacity: 1;
      -moz-transform: scale(1);
    }
  }

  @-o-keyframes bounceIn {
    0% {
      opacity: 0;
      -o-transform: scale(0.3);
    }

    50% {
      -o-transform: scale(1.05);
    }

    70% {
      -o-transform: scale(0.9);
    }

    100% {
      opacity: 1;
      -o-transform: scale(1);
    }
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0.3);
    }

    50% {
      transform: scale(1.05);
    }

    70% {
      transform: scale(0.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
    }

    60% {
      -webkit-transform: translateX(20px);
    }

    80% {
      -webkit-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -moz-transform: translateX(-2000px);
    }

    60% {
      -moz-transform: translateX(20px);
    }

    80% {
      -moz-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -o-transform: translateX(-2000px);
    }

    60% {
      opacity: 1;
      -o-transform: translateX(20px);
    }

    80% {
      -o-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      transform: translateX(-2000px);
    }

    60% {
      transform: translateX(20px);
    }

    80% {
      transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    60% {
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
