@import "../../../assets/style/_variables.less";

.button-landing {
    left: 0;
}

.modal-content-employee {
    .back-to-back {
        position: absolute;
        z-index: 0;
        width: 100%;
        top: 0;
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;
    }

    .avatars-backg-holder {
        margin-top: 64px;
        z-index: 1;

        @media screen and (max-width: 500px) {
            max-width: 150px;
            border-radius: 20px;
            max-height: 150px;
            position: relative;
            height: 150px;
            margin-top: 24px;

            img {
                position: absolute;
                position: absolute;
                width: 100%;
                bottom: 0;
                max-width: 100px;
            }
        }
    }

    //--Nikola pisao css :D jer Kaldrma nema fugu :D :D
    .content-container {
        display: flex;
        flex-direction: row !important;
        justify-content: flex-start;
        align-items: center;
        font-family: @font-family;
        margin-bottom: 24px;
        font-size: 16px;
        max-width: 90%;
        margin: auto;

        @media screen and (max-width: 500px) {
            margin-bottom: 8px;
        }

        &:nth-child(1) {
            margin-top: 34px;
            justify-content: center;
        }

        .static {
            width: 150px;
            color: #fff !important;
            background-color: @primary-color;
            padding: 8px 20px 8px 10px;
            margin-right: 20px;
            border-radius: 10px;
        }

        .dynamic {
            font-weight: bold;
            text-align: left !important;
            flex: 1;
        }

        .coin-image img {
            max-width: 20px;
            vertical-align: middle;
            margin-left: 5px;
        }
    }

    //-- Izbrisati ovo sta ne bude trebalo Micko brate moj <3
    .contentHeader h3 {
        font-weight: bold;
        font-size: 32px;
        line-height: 32px;
        font-family: @font-family;
        max-width: 800px;
        width: 98%;
        text-align: center;
        margin: 32px auto 24px auto;
    }

    .contentPrice {
        font-family: @font-family-secondary;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 26px;
        margin: 0px auto 24px auto;
        text-align: center;
        color: #57d22c;

        img {
            max-width: 30px;
            margin-left: 10px;
            position: relative;
            top: -2px;
        }

        @media screen and (max-width: 654px) {
            margin: 0px 15px 16px 15px;
            font-size: 17px;
        }
    }

    .contentDescription {
        font-family: @font-family-secondary;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        max-width: 355px;
        width: 100%;
        margin: 0px auto;
    }

    .ant-btn-primary {
        color: white;
        background-color: #7b35df;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid #7b35df;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        border-radius: 7px !important;
        margin: 5px auto;
        padding: 10px 15px !important;
        height: auto !important;
        width: auto;
        min-width: 150px;
        font-size: 20px !important;
        text-transform: uppercase;
    }

    .ant-btn-action {
        display: none !important;
    }

    .ant-modal-footer {
        text-align: center;
        border: none;
        padding: 0;
        padding-bottom: 32px;
        margin: 24px auto 0px auto;
    }

    .industry-backg-holder {
        width: 100%;
        max-width: 600px;
        height: 200px;
        background: #efe6fc;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #7b35df;
        display: grid;
        padding-top: 25px;
        padding-bottom: 25px;
        border-top-right-radius: 26px;
        border-top-left-radius: 26px;
        grid-template-columns: auto;
        align-items: flex-start;

        img {
            max-width: 200px;
            width: 100%;
            height: auto;
        }
    }

    .ant-modal {
        max-width: 570px;
        width: 96% !important;
        top: 30px;
        padding-bottom: 30px !important;
    }

    .ant-modal-body {
        padding: 0;
        border-top-right-radius: 26px;
        border-top-left-radius: 26px;
        background: #ffffff;
    }

    .ant-modal-content {
        border-radius: 26px;
        // border: 1px solid @primary-color;
        border-left: 0;
    }
}
