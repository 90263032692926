@import '../../../assets/style/_variables.less';

.business-model {
    margin-bottom: @layout-footer-height + 110px;
    height: 100%;
    overflow: visible;
    min-height: 80vh;

    .title-bmc {
        color: white;
        background-color: @primary-color;
        padding: 20px;
        text-indent: 50px;
        width: 100%;
        text-align: left;
        font-size: 48px;
        margin-bottom: 0;

        @media screen and (max-width: 950px) {
            padding: 20px;
            text-indent: 70px;
            font-size: 36px;
        }

        @media screen and (max-width: 610px) {
            padding: 10px;
            text-indent: 30px;
            font-size: 24px;
        }
    }

    .bmc-description {
        padding: 20px;
        background-color: @primary-color;
        color: white;
        font-size: 18px;

        @media screen and (max-width: 610px) {
            padding: 14px;
            font-size: 14px;
        }
    }

    ul,
    ol {
        list-style: none;
        padding: 0;
    }

    ol.numbered {
        list-style: decimal;
        margin-left: 0;
    }

    .bmc {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas:
            "kp kp ka ka vp vp cr cr cseg cseg"
            "kp kp kr kr vp vp c c cseg cseg"
            "cst cst cst cst cst rs rs rs rs rs";
        column-gap: 0;
        row-gap: 0;
        text-align: left;

        .key-partners {
            grid-area: kp;
            border-top: 1px solid #7b35df;
            border-left: none;
        }

        .test-kp {
            pointer-events: none;
            opacity: 0.6;
            background: #dadada;
            filter: alpha(opacity=50);
            zoom: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
            cursor: not-allowed;
            grid-area: kp;
            border-top: 1px solid #7b35df;
            border-left: none;
        }

        .key-activities {
            grid-area: ka;
            border-top: 1px solid #7b35df;
        }

        .test-ka {
            pointer-events: none;
            opacity: 0.6;
            background: #dadada;
            filter: alpha(opacity=50);
            zoom: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
            cursor: not-allowed;
            grid-area: ka;
            border-top: 1px solid #7b35df;
        }

        .key-resources {
            grid-area: kr;
        }

        .test-kr {
            pointer-events: none;
            opacity: 0.6;
            background: #dadada;
            filter: alpha(opacity=50);
            zoom: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
            cursor: not-allowed;
            grid-area: kr;
        }

        .value-propositions {
            grid-area: vp;
            border-top: 1px solid #7b35df;
        }

        .test-vp {
            pointer-events: none;
            opacity: 0.6;
            background: #dadada;
            filter: alpha(opacity=50);
            zoom: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
            cursor: not-allowed;
            grid-area: vp;
            border-top: 1px solid #7b35df;
        }

        .customer-relationships {
            grid-area: cr;
            border-top: 1px solid #7b35df;
        }

        .test-cr {
            pointer-events: none;
            opacity: 0.6;
            background: #dadada;
            filter: alpha(opacity=50);
            zoom: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
            cursor: not-allowed;
            grid-area: cr;
            border-top: 1px solid #7b35df;
        }

        .channels {
            grid-area: c;
        }

        .test-c {
            pointer-events: none;
            opacity: 0.6;
            background: #dadada;
            filter: alpha(opacity=50);
            zoom: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
            cursor: not-allowed;
            grid-area: c;
        }

        .customer-segments {
            grid-area: cseg;
            border-top: 1px solid #7b35df;
            border-right: 1px solid #7b35df;
            border-right: none;
        }

        .test-cseg {
            pointer-events: none;
            opacity: 0.6;
            background: #dadada;
            filter: alpha(opacity=50);
            zoom: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
            cursor: not-allowed;
            grid-area: cseg;
            border-top: 1px solid #7b35df;
            border-right: 1px solid #7b35df;
            border-right: none;
        }

        .cost-structure {
            grid-area: cst;
            border-left: none;
        }

        .test-cst {
            pointer-events: none;
            opacity: 0.6;
            background: #dadada;
            filter: alpha(opacity=50);
            zoom: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
            cursor: not-allowed;
            grid-area: cst;
            border-left: none;
        }

        .revenue-streams {
            grid-area: rs;
            border-right: 1px solid #7b35df;
            border-right: none;
        }

        .test-rs {
            pointer-events: none;
            opacity: 0.6;
            background: #dadada;
            filter: alpha(opacity=50);
            zoom: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
            -moz-opacity: 0.5;
            -khtml-opacity: 0.5;
            cursor: not-allowed;
            grid-area: rs;
            border-right: 1px solid #7b35df;
            border-right: none;
        }
    }

    .block {
        border-left: 1px solid #7b35df;
        border-bottom: 1px solid #7b35df;
        padding: 16px;
        position: relative;

        @media screen and (max-width: 610px) {
            padding: 5px;
        }

        .pen-image {
            max-width: 15px;
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;

            @media screen and (max-width: 600px) {
                max-width: 10px;
            }
        }
    }

    .title,
    .subtitle {
        color: #333;
    }

    .ingress p {
        @media screen and (max-width: 950px) {
            font-size: 12px;
        }

        @media screen and (max-width: 610px) {
            font-size: 10px;
        }

        @media screen and (max-width: 430px) {
            font-size: 8px;
        }
    }

    .title {
        font-size: 28px;

        @media screen and (max-width: 950px) {
            font-size: 18px;
        }

        @media screen and (max-width: 610px) {
            font-size: 12px;
        }

        @media screen and (max-width: 430px) {
            font-size: 9px;
        }
    }

    .button-landing {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: white;
        padding-bottom: 15px;
        padding-top: 15px;
        border-top: 1px solid #d4d4d4;
        right: 0;
        margin-bottom: @layout-footer-height;

        @media screen and (max-width: 600px) {
            padding-bottom: 7px;
            padding-top: 7px;

            .signIn {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }

            .buttonMakeAcc {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }
        }
    }
    .certificateHide{
        display: flex;
        position: absolute;
        left: -10000px;
    }
}