@import "../../assets/style/_variables.less";

.office {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - (25px + 51px)) !important;
    width: 100vw;
    padding-bottom: 25px;
    background: url("../../assets/images/office-backgorund.png");
    background-size: cover;

    .img-holder {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 850px;
        width: auto;
        // overflow: auto;

        .office-img {
            height: 100%;
            animation: zoom-out 1s;
            &::selection {
                background: transparent;
            }
            ::-moz-selection {
                background: transparent;
            }

            @keyframes zoom-out {
                from {
                    transform: scale(4);
                }

                to {
                    transform: scale(1);
                }
            }
        }

        .sequence-1 {
            top: 54%;
            left: 48%;
        }
        .sequence-2 {
            top: 49%;
            left: 57%;
        }
        .sequence-3 {
            top: 37%;
            left: 26.5%;
        }
        .sequence-4 {
            top: 52%;
            left: 38.5%;
        }
        .sequence-other {
            top: 16.5%;
            left: 34%;
        }

        // other elements
        .budget {
            left: 87%;
            top: 48%;
        }

        .bmc {
            top: 16%;
            left: 56%;
        }

        .buisiness-plan {
            top: 23%;
            left: 23%;
        }

        .elevator-pitch {
            top: 56%;
            left: 12%;
        }

        @media (max-width: 1600px) {
            height: 700px;

            .sequence-4 {
                left: 37%;
            }
            .sequence-other {
                top: 15%;
                left: 33%;
            }

            .budget {
                left: 86%;
                top: 48%;
            }

            .buisiness-plan {
                top: 22%;
                left: 22%;
            }
        }
    }

    // animation
    .animation {
        transform: scale(1);
        animation: pulse 2s infinite, transparent 3s;
        border: 5px solid @green-light-color;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 24px;
        position: absolute;
        width: 80px;
        height: 80px;

        @keyframes transparent {
            from {
                opacity: 0;
            }
            to {
                opacity: 0.9;
            }
        }

        .title {
            font-size: 16px;
            line-height: 1;
            pointer-events: none;
            position: absolute;
            top: 90px;
            color: #000;
            opacity: 0;
            transition: 0.3s;
            &::selection {
                background: transparent;
            }
            ::-moz-selection {
                background: transparent;
            }
            

            @media (max-width: 715px) {
                opacity: 1;
            }
        }

        &:hover {
            .title {
                opacity: 1;
                transition: 0.3s;
            }
        }

        &::selection {
            background: transparent;
        }
        ::-moz-selection {
            background: transparent;
        }

        &::before {
            content: "";
            position: absolute;
            width: 140%;
            height: 140%;
            border: 2px solid @green-light-color;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @keyframes pulse {
            0% {
                transform: scale(0.95);
            }

            70% {
                transform: scale(1);
            }

            100% {
                transform: scale(0.95);
            }
        }
    }

    .light-bulb {
        position: absolute;
        top: 50px;
        left: 50px;
        transform: scale(1);
        transition: 0.3s;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
            transition: 0.3s;
        }
    }

    .ant-spin-spinning {
        display: inline-block;
        opacity: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 50%;
        text-align: center;
        z-index: 111;
    }

    .spinner-back {
        // background-color: fade(white, 60%);
        background-color: #fff;
        width: 100%;
        display: inline-block;
        opacity: 1;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        text-align: center;
        z-index: 111;
        height: 100vh;
    }

    .spinner-non-back {
        display: none;
    }

    .ant-spin-dot-item {
        background-color: @primary-color;
    }


    @media (max-width: 715px) {
        width: 100vw;
        overflow-x: auto;
        justify-content: flex-start;
        margin-top: 20px;
    }
}
