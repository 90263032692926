@import '../../../../../assets/style/_variables.less';

.ofSwitch {
    height: 100%;
    min-height: 100vh;
    background-image: url('../../../../../assets//images/office_bg.jpg')!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    margin-bottom: 75px;
    @media screen and (max-width:800px) {
        margin-bottom: 125px;
    }
}
.toSwitch {
    height: auto;
    min-height: 40vh;
    background-attachment: fixed;
    width: 70%;
    margin: 0px auto;
    background: white;
    margin-top: 5%;
    border: 1px solid #7b35df;
    border-radius: 12px;
    // margin-bottom: 5%;
    max-height: 80vh;
    overflow-y: auto;
    .ant-spin-spinning {
        display: inline-block;
        opacity: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 50%;
        text-align: center;
        z-index: 111;
    }

    .spinner-back {
        // background-color: fade(white, 60%);
        background-color: #fff;
        width: 100%;
        display: inline-block;
        opacity: 1;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        text-align: center;
        z-index: 111;
        height: 100vh;
    }

    .spinner-non-back {
        display: none;
    }

    .ant-spin-dot-item {
        background-color: @primary-color;
    }
}

.bg1 {
    background-image: url('../../../../../assets/images/sequenceBackground/bg1.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.bg2 {
    background-image: url('../../../../../assets/images/sequenceBackground/value.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.bg3 {
    background-image: url('../../../../../assets/images/sequenceBackground/customer-segments.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.bg4 {
    background-image: url('../../../../../assets/images/sequenceBackground/key-activities.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.bg5 {
    background-image: url('../../../../../assets/images/sequenceBackground/costs.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.bg6 {
    background-image: url('../../../../../assets/images/sequenceBackground/channels.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.bg7 {
    background-image: url('../../../../../assets/images/sequenceBackground/customer-relationships.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.bg8 {
    background-image: url('../../../../../assets/images/sequenceBackground/revenue-streams.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.bg9 {
    background-image: url('../../../../../assets/images/sequenceBackground/key-resources.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.bg10 {
    background-image: url('../../../../../assets/images/sequenceBackground/key-partners.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.bg11 {
    background-image: url('../../../../../assets/images/sequenceBackground/final-sequence.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.sequence-holder {
    max-width: 800px;
    width: 90%;
    margin: 0px auto;
    text-align: center;
    overflow-x: hidden;

    .questions-for-all {
        display: inline-grid;
        grid-template-columns: auto 1fr;
        grid-gap: 5px;
        font-family: @font-family;
        font-size: 18px;
        width: 100%;
        align-items: start;
        margin-top: 24px;

        .avatars-backg-holder {
            width: 120px;
            height: 120px;
            display: grid;
            padding-top: 17px;
            border-radius: 20px;
            margin: 0px auto;
            margin-right: 19px;
            grid-column: 1;
            align-items: end;

            @media screen and (max-width:800px) {
                width: 100px;
                height: 100px;
                margin-right: 10px;
            }

            @media screen and (max-width:600px) {
                width: 70px;
                height: 70px;
            }

            img {
                width: 100%;
                max-width: 80px;
                position: relative;

                @media screen and (max-width:600px) {
                    width: 60px;
                }

                @media screen and (max-width:600px) {
                    width: 40px;
                }
            }
        }

        .question-holder {
            width: 100%;
            font-size: 18px;
            line-height: 24px;
            text-align: left;
            background-color: #7b35df;
            color: #ffffff;
            padding: 21px;
            border-top-left-radius: 26px;
            border-top-right-radius: 26px;
            border-bottom-right-radius: 26px;
            max-width: 660px;
            align-self: baseline;
            display: grid;
            align-items: center;
            height: 100%;
            max-width: -moz-fit-content;

            @media screen and (max-width:800px) {
                font-size: 17px;
                line-height: 22px;
            }

            @media screen and (max-width: 600px) {
                font-size: 17px;
                line-height: 22px;
            }

            @media screen and (max-width:420px) {
                font-size: 14px;
                line-height: 22px;
                padding: 15px;
            }
        }
    }

    .three-items {
        justify-content: space-between;
        align-items: center;
        max-width: 140px;
        margin: 0px auto;
        position: fixed;
        left: 0px;
        bottom: 120px;
        z-index: 11;

        @media screen and (max-width:600px) {
            bottom: 80px;
        }
    }

    .mentor-modal-holder {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-duration: 1s;
        animation-delay: 1s;
        animation-fill-mode: both;
        animation-direction: alternate;

        .signUp {
            background: #57D22C;
            border: 1px solid #57D22C;
            height: 60px;
            min-width: 60px;
            border-radius: 50%;
            font-size: 30px;
            padding: 0;
            animation-name: pulse;
            -webkit-animation-name: pulse;
            -moz-animation-name: pulse;
            -o-animation-name: pulse;
            animation-duration: 1s;
            animation-iteration-count: infinite;

            @media screen and (max-width:800px) {
                height: 50px;
                min-width: 50px;
                padding: 0px 0px;
            }

            @media screen and (max-width:450px) {
                height: 35px;
                min-width: 35px;
                font-size: 20px;
            }

            &:hover,
            &:focus {
                background: #57D22C !important;
                border: 1px solid #57D22C;
                height: 60px;
                min-width: 60px;
                border-radius: 50%;
                font-size: 30px;
                cursor: pointer;
                color: white !important;
                animation: none;

                @media screen and (max-width:800px) {
                    height: 50px;
                    min-width: 50px;
                }

                @media screen and (max-width:450px) {
                    height: 35px;
                    min-width: 35px;
                    font-size: 20px;
                }
            }
        }

        .signUpDisbled {
            background: #d9d9d9;
            border: 1px solid#d9d9d9;
            height: 60px;
            min-width: 60px;
            border-radius: 50%;
            font-size: 30px;
            padding: 0;
            cursor: not-allowed;

            @media screen and (max-width:800px) {
                height: 50px;
                min-width: 50px;
                padding: 0px 0px;
            }

            @media screen and (max-width:450px) {
                height: 35px;
                min-width: 35px;
                font-size: 20px;
            }

            &:hover,
            &:focus {
                background: #d9d9d9 !important;
                border: 1px solid#d9d9d9;
                height: 60px;
                min-width: 60px;
                border-radius: 50%;
                font-size: 30px;
                cursor: pointer;
                color: white !important;
                cursor: not-allowed;

                @media screen and (max-width:800px) {
                    height: 50px;
                    min-width: 50px;
                }

                @media screen and (max-width:450px) {
                    height: 35px;
                    min-width: 35px;
                    font-size: 20px;
                }
            }
        }
    }

    .hint-modal-holder {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-delay: 1s;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-direction: alternate;

        .pauseButton {
            cursor: pointer;
        }
        .clickedPause {
            opacity: 0.5;
        }

        .signUp {
            background: none;
            border: none;
            height: auto;
            min-width: auto;
            font-size: 25px;
            font-weight: bold;
            position: relative;
            box-shadow: none;
            animation-name: pulse;
            -webkit-animation-name: pulse;
            -moz-animation-name: pulse;
            -o-animation-name: pulse;
            animation-duration: 1s;
            animation-iteration-count: infinite;

            @media screen and (max-width:800px) {

                padding: 0px 0px;
            }

            img {
                max-width: 60px;

                @media screen and (max-width:800px) {
                    max-width: 50px;
                }

                @media screen and (max-width:450px) {
                    max-width: 35px;
                }
            }

            span {
                position: relative;
                top: -5px;
            }

            &:hover,
            &:focus {
                background: none !important;
                border: none !important;
                height: auto !important;
                min-width: auto !important;
                font-size: 25px !important;
                font-weight: bold !important;
                position: relative !important;
                box-shadow: none !important;
                cursor: pointer !important;
                animation: none;
            }
        }

    }

    .timer-holder {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-delay: 1s;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-direction: alternate;
        border-radius: 50%;
        color: black !important;
        background: #efe6fc;
        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
    }

    /*ANIMATIONS*/

    @keyframes pulse {
        from {
            transform: scale(1);
        }

        50% {
            transform: scale(0.85);
        }

        to {
            transform: scale(1);
        }
    }

    @-webkit-keyframes pulse {
        from {
            transform: scale(1);
        }

        50% {
            transform: scale(0.85);
        }

        to {
            transform: scale(1);
        }
    }

    @-moz-keyframes pulse {
        from {
            transform: scale(1);
        }

        50% {
            transform: scale(0.85);
        }

        to {
            transform: scale(1);
        }
    }

    @-o-keyframes pulse {
        from {
            transform: scale(1);
        }

        50% {
            transform: scale(0.85);
        }

        to {
            transform: scale(1);
        }
    }


    @-webkit-keyframes bounceIn {
        0% {
            opacity: 0;
            -webkit-transform: scale(.3);
        }

        50% {
            -webkit-transform: scale(1.05);
        }

        70% {
            -webkit-transform: scale(.9);
        }

        100% {
            opacity: 1;
            -webkit-transform: scale(1);
        }
    }

    @-moz-keyframes bounceIn {
        0% {
            opacity: 0;
            -moz-transform: scale(.3);
        }

        50% {
            -moz-transform: scale(1.05);
        }

        70% {
            -moz-transform: scale(.9);
        }

        100% {
            opacity: 1;
            -moz-transform: scale(1);
        }
    }

    @-o-keyframes bounceIn {
        0% {
            opacity: 0;
            -o-transform: scale(.3);
        }

        50% {
            -o-transform: scale(1.05);
        }

        70% {
            -o-transform: scale(.9);
        }

        100% {
            opacity: 1;
            -o-transform: scale(1);
        }
    }

    @keyframes bounceIn {
        0% {
            opacity: 0;
            transform: scale(.3);
        }

        50% {
            transform: scale(1.05);
        }

        70% {
            transform: scale(.9);
        }

        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    @-webkit-keyframes bounceInRight {
        0% {
            opacity: 0;
            -webkit-transform: translateX(2000px);
        }

        60% {
            -webkit-transform: translateX(-20px);
        }

        80% {
            -webkit-transform: translateX(5px);
        }

        100% {
            opacity: 1;
            -webkit-transform: translateX(0);
        }
    }

    @-moz-keyframes bounceInRight {
        0% {
            opacity: 0;
            -moz-transform: translateX(2000px);
        }

        60% {
            -moz-transform: translateX(-20px);
        }

        80% {
            -moz-transform: translateX(5px);
        }

        100% {
            opacity: 1;
            -moz-transform: translateX(0);
        }
    }

    @-o-keyframes bounceInRight {
        0% {
            opacity: 0;
            -o-transform: translateX(2000px);
        }

        60% {
            -o-transform: translateX(-20px);
        }

        80% {
            -o-transform: translateX(5px);
        }

        100% {
            opacity: 1;
            -o-transform: translateX(0);
        }
    }

    @keyframes bounceInRight {
        0% {
            opacity: 0;
            transform: translateX(2000px);
        }

        60% {
            transform: translateX(-20px);
        }

        80% {
            transform: translateX(5px);
        }

        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
}