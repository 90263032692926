.chart-comp-bld {
    padding-top: 25px;
    width: 100%;
    max-width: 600px;
    border-radius: 12px;
    margin: 0px auto;
    text-align: center;

    div {
        margin: 0px auto;
    }
}