@import '../../../../assets/style/_variables.less';

.bussines-plan {
    font-weight: bold;
    text-transform: uppercase;
    h2 {
        text-align: center;
    }
    p {
        font-size: 18px;
        margin: 0;
    }
    .bpHolder {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        border-bottom: 1px solid grey;
    }
}