@import "../../assets/style/_variables.less";

.privacy-policy {
    border: 1px solid @green-color;
    background: #fff;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 75%;
    margin: 20px 0 0 0;
    padding: 50px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @media (max-width: 700px) {
        width: 100%;
        padding: 20px;
    }

    h3 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 50px;
    }

    .content {
        overflow-y: auto;
        text-align: left;
        font-weight: normal;
        font-size: 18px;
        margin: 0;
        flex: 1;
        padding-right: 10px;
        overflow-x: hidden;
        width: 100%;

        .bold {
            font-weight: bold;
        }

        .mb-L {
            margin-bottom: 30px;
        }

        .mb-S {
            margin-bottom: 10px;
        }

        .underline {
            text-decoration: underline;
        }

        p {
            font-size: 18px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 10px;
        }

        a {
            word-break: break-word;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: @primary-color;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .buttons {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-top: 20px;
        .decline {
            background: #fff;
            color: @primary-color;
        }
    }
}
