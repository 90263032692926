@import '../../../assets/style/_variables.less';

body::-webkit-scrollbar {
  width: 0.5px;
}

.modal-content-bmc {
  .button-landing {
    margin: 48px auto;
    display: block;
    text-align: center;
    margin-bottom: 0;

    .buttonMakeAcc {
      min-width: 145px;
      padding: 5px 10px;

      @media screen and (max-width: 800px) {
        margin-top: 24px;
      }

      @media screen and (max-width: 400px) {
        font-size: 16px;
        min-width: 180px;
      }
    }
  }

  .ant-btn:empty {
    display: none;
  }

  textarea {
    width: 100%;
    border: 2px solid @primary-color;
    font-size: 17px;
    padding: 10px;
    border-radius: 12px;
    min-height: 100px;
  }

  textarea::-webkit-scrollbar {
    width: 0.5px;
  }

  textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  textarea:focus {
    outline: none;
  }

  .contentHeader {
    h3 {
      font-family: @font-family-secondary;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      margin: 24px auto;
      text-align: center;
      margin-bottom: 48px;

      @media screen and (max-width: 800px) {
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 24px;
      }

      @media screen and (max-width: 400px) {
        font-size: 18px;
        line-height: 18px;
      }
    }
  }


  .ant-modal {
    max-width: 570px;
    width: 96% !important;
    top: 30px;
    padding-bottom: 0px !important;
  }

  .ant-modal-body {
    padding: 0;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    background: #ffffff;
  }

  .ant-modal-content {
    border-radius: 12px;
    border: 1px solid @primary-color;
    padding: 20px;
  }


}