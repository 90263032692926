@import '../../../../../assets/style/_variables.less';

.chart-comp-acc {
    padding-top: 25px;
    width: 100%;
    max-width: 600px;
    border-radius: 12px;
    margin: 0px auto;
    text-align: center;

    div {
        margin: 0px auto;
    }

    .accelerator-holder {
        overflow: hidden;
    }
}