@import '../../../assets/style/_variables.less';

.name-industry {
  width: 100%;
  margin: 0px auto;


  .name-section-industry {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
    margin-bottom: @layout-footer-height * 2 + 94px;

    @media screen and (max-width: 600px) {
      margin-bottom: @layout-footer-height * 2 + 59px;

      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    .industry-backg-holder {
      max-width: 300px;

      @media screen and (max-width: 600px) {
        width: 95%;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: normal;
      font-family: @font-family;
      max-width: 1200px;
      width: 98%;
      text-align: center;
      margin: 48px auto;
      -webkit-animation-name: bounceInRight;
      -moz-animation-name: bounceInRight;
      -o-animation-name: bounceInRight;
      animation-name: bounceInRight;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;

      @media screen and (max-width: 720px) {
        font-size: 30px;
        margin: 48px auto;
      }

      @media screen and (max-width: 400px) {
        font-size: 26px;
        margin: 24px auto;
      }
    }

    .button-landing {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;
      right: 0;
      margin-bottom: @layout-footer-height;

      @media screen and (max-width: 600px) {
        padding-bottom: 7px;
        padding-top: 7px;
      }

      .signIn {
        -webkit-animation-name: fadeInDown;
        -moz-animation-name: fadeInDown;
        -o-animation-name: fadeInDown;
        animation-name: fadeInDown;
        animation-duration: 1s;
        animation-delay: 2s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }

      .buttonMakeAcc {
        -webkit-animation-name: fadeInDown;
        -moz-animation-name: fadeInDown;
        -o-animation-name: fadeInDown;
        animation-name: fadeInDown;
        animation-duration: 1s;
        animation-delay: 2s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }
    }

    .name-industry-holder {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 48px;
      width: 100%;
      max-width: 800px;
      -webkit-animation-name: bounceInLeft;
      -moz-animation-name: bounceInLeft;
      -o-animation-name: bounceInLeft;
      animation-name: bounceInLeft;
      animation-duration: 1s;
      animation-delay: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;

      @media screen and (max-width: 720px) {
        margin-bottom: 48px;
      }

      @media screen and (max-width: 400px) {
        margin-bottom: 24px;
      }
    }

    .name-input-holder-industry {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 800px;
      font-family: @font-family;
      -webkit-animation-name: bounceIn;
      -moz-animation-name: bounceIn;
      -o-animation-name: bounceIn;
      animation-name: bounceIn;
      animation-duration: 1s;
      animation-delay: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;

      label {
        text-transform: uppercase;
        font-size: 18px;
        font-style: normal;
        color: #c0b2d4;
      }
    }

    .ant-input-number {
      width: 100%;
    }

    .ant-form {
      text-align: left;
      padding: 0px 30px;
      padding-bottom: 0;
      width: 100%;
      max-width: 350px;
    }

    .ant-col-offset-8 {
      margin-left: 0px;
    }

    .ant-input {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum', 'tnum';
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 10px 10px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid @primary-color;
      border-radius: 5px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;

      &::placeholder {
        color: @primary-color;
        font-weight: bold;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }

    .ant-input-affix-wrapper {
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 10px 10px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid @primary-color;
      border-radius: 5px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }

    .ant-input-number-input-wrap {
      border: 1px solid @primary-color;
      border-radius: 5px;
      padding: 10px 10px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 1.5715;
    }

    .ant-input-number-input {
      height: auto;
    }

    .ant-select-selector {
      border: 1px solid @primary-color  !important;
      border-radius: 5px !important;
      padding: 6px 5px !important;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      height: auto !important;
    }

    .ant-form-item {
      margin-bottom: 20px;
    }

    .ant-select-arrow {
      color: rgb(87 36 179);
    }

    .sc-networks {
      padding: 0px 30px;
    }

    .ant-form-item-label {
      text-align: left !important;
    }

    span {
      font-weight: bold;
    }
  }

  /*ANIMATIONS*/

  @-webkit-keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(.3);
    }

    50% {
      -webkit-transform: scale(1.05);
    }

    70% {
      -webkit-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }

  @-moz-keyframes bounceIn {
    0% {
      opacity: 0;
      -moz-transform: scale(.3);
    }

    50% {
      -moz-transform: scale(1.05);
    }

    70% {
      -moz-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -moz-transform: scale(1);
    }
  }

  @-o-keyframes bounceIn {
    0% {
      opacity: 0;
      -o-transform: scale(.3);
    }

    50% {
      -o-transform: scale(1.05);
    }

    70% {
      -o-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -o-transform: scale(1);
    }
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(.3);
    }

    50% {
      transform: scale(1.05);
    }

    70% {
      transform: scale(.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
    }

    60% {
      -webkit-transform: translateX(20px);
    }

    80% {
      -webkit-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -moz-transform: translateX(-2000px);
    }

    60% {
      -moz-transform: translateX(20px);
    }

    80% {
      -moz-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -o-transform: translateX(-2000px);
    }

    60% {
      opacity: 1;
      -o-transform: translateX(20px);
    }

    80% {
      -o-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      transform: translateX(-2000px);
    }

    60% {
      transform: translateX(20px);
    }

    80% {
      transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes bounceInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
    }

    60% {
      -webkit-transform: translateX(-20px);
    }

    80% {
      -webkit-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInRight {
    0% {
      opacity: 0;
      -moz-transform: translateX(2000px);
    }

    60% {
      -moz-transform: translateX(-20px);
    }

    80% {
      -moz-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInRight {
    0% {
      opacity: 0;
      -o-transform: translateX(2000px);
    }

    60% {
      -o-transform: translateX(-20px);
    }

    80% {
      -o-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInRight {
    0% {
      opacity: 0;
      transform: translateX(2000px);
    }

    60% {
      transform: translateX(-20px);
    }

    80% {
      transform: translateX(5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }

  @-moz-keyframes fadeInDown {
    0% {
      opacity: 0;
      -moz-transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateY(0);
    }
  }

  @-o-keyframes fadeInDown {
    0% {
      opacity: 0;
      -o-transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      -o-transform: translateY(0);
    }
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

}