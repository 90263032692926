@import "../../../assets/style/_variables.less";

.ant-layout {
  background: none;
}

.survey-to {
  width: 100%;
  margin: 0px auto;
  background-image: url("../../../assets/images/bg9.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  background-attachment: fixed;

  .survey-question {
    margin-bottom: 140px;
  }

  @media screen and (max-width: 1000px) {
    background-position: right;
  }

  .radio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0 0;

    .radio-buttons {
      display: flex;
    }

    .radio-text {
      width: 20%;
      font-size: 14px;
      color: @primary-color;
      text-align: center;

      @media (max-width: 600px) {
        text-align: center;
      }
    }

    .radio-text-row {
      margin-left: 10px;
      font-size: 18px;
      color: #7b35df;
    }

    .ant-radio-group {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding: 0 20px;

      label {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        font-size: 16px;
        color: @primary-color !important;
        background-color: unset;
        border: 1px solid @primary-color;
        border-radius: 32px;
        margin: 0 !important;

        svg {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -130%);

          path {
            fill: #5825a1;
          }
        }

        &.ant-radio-button-wrapper-checked {
          background-color: @primary-color;
          color: #fff !important;

          svg {
            path {
              fill: @primary-color;
            }
          }
        }
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: space-between;

      .radio-text {
        width: 90%;

        &.start-text {
          text-align: left;
          margin-left: auto;
        }

        &.end-text {
          text-align: right;
          margin-right: auto;
        }
      }

      .ant-radio-group {
        flex: unset;
        margin: 70px 0px 20px 0;
        width: 95%;
      }
    }
  }

  label {
    display: flex;
    justify-content: flex-start;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 50px !important;
    color: #000 !important;

    &::before {
      display: none !important;
    }

    @media (max-width: 600px) {
      justify-content: space-between;
      text-align: center;
      margin-bottom: 40px !important;
    }
  }

  .button-landing {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1111;
    background-color: white;
    padding-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid #d4d4d4;
    right: 0;
    margin-bottom: @layout-footer-height;

    @media screen and (max-width: 600px) {
      padding-bottom: 7px;
      padding-top: 7px;

      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }
  }

  .survey-to-complete {
    width: 100%;
    margin: 0px auto;
    text-align: left;
    display: block;
    max-width: 800px;
    margin-bottom: 0;
    z-index: 11;

    @media (max-width: 600px) {
      text-align: center;
    }

    .ant-input {
      border: 2px solid @primary-color;
      color: @primary-color;
      font-weight: bold;
      border-radius: 12px;
      width: 100%;
      // max-width: 450px;
      margin: 0px auto;
      flex: 1;
      display: flex;
      min-height: 200px;
      padding: 10px;
      font-size: 16px;

      // &:focus {
      //     border: 2px solid @primary-color;
      //     color: @primary-color;
      //     font-weight: bold;
      //     border-radius: 12px;
      //     width: 100%;
      //     // max-width: 450px;
      //     margin: 0px auto;
      //     flex: 1;
      //     display: flex;
      //     min-height: 150px;
      //     padding: 10px;
      //     font-size: 16px;
      //     outline: none;
      // }
    }

    h2 {
      font-weight: bold;
      font-size: 48px;
      line-height: normal;
      font-family: @font-family;
      max-width: 1200px;
      width: 90%;
      text-align: center;
      margin: 96px auto;
      margin-bottom: 96px;

      @media screen and (max-width: 800px) {
        font-size: 32px;
        margin: 24px auto;
        text-align: center;
      }

      @media screen and (max-width: 400px) {
        font-size: 38px;
      }
    }
  }

  .imageOnHover {
    max-width: 350px;
    width: 100%;
    margin: 20px 0px;
    float: right;
    position: relative;
    bottom: 120px;
    left: 20px;
    z-index: 11;
    img {
      max-width: 200px;
      width: 100%;
      margin: 0px auto;
    }
  }
  .onHoverText {
    display: none;
    position: absolute;
    right: 50px;
    top: -100px;
    background: #7b35df;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 12px;
  }
  .imageOnHover:hover {
    cursor: pointer;
  }

  .imageOnHover:hover > .onHoverText {
    display: block !important;
  }
  .continue-button {
    margin-top: -120px;
  }
}
