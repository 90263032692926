@import '../../../assets/style/_variables.less';

body::-webkit-scrollbar {
  width: 0.5px;
}

.button-landing {
  left: 0;
}

.modal-content-sequence {
  .modal-sequence-section {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-family: @font-family;

    .button-modal-sequence {
      text-align: center;
      width: 100%;

      @media screen and (max-width: 800px) {
        display: block;
      }

      h2 {
        text-align: center;
        color: @green-color;
        font-size: 48px;
        line-height: 48px;
        font-weight: bold;
        margin: 96px 0px 24px 0px;

        @media screen and (max-width: 800px) {
          font-size: 36px;
          line-height: 36px;
          margin: 56px 0px 24px 0px;
        }

        @media screen and (max-width: 400px) {
          font-size: 24px;
          margin: 24px 0px 24px 0px;
          line-height: 24px;
        }
      }

      .class-coins {
        font-size: 48px;
        line-height: 48px;
        font-weight: bold;
        color: #000000;
        margin: 0px 0px 24px 0px;

        @media screen and (max-width: 800px) {
          font-size: 36px;
          line-height: 36px;

          img {
            max-width: 40px;
          }
        }

        @media screen and (max-width: 400px) {
          font-size: 24px;
          line-height: 36px;

          img {
            max-width: 30px;
          }
        }
      }
    }

  }

  .ant-btn-primary {
    color: white;
    background-color: #7b35df;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #7b35df;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 7px !important;
    margin: 0px auto;
    padding: 10px 15px !important;
    height: auto !important;
    width: auto;
    min-width: 150px;
    font-size: 20px !important;
    text-transform: uppercase;
  }

  .ant-btn-action {
    display: none !important;
  }

  .ant-modal {
    max-width: 600px;
    width: 100% !important;
    top: 15%;

    @media screen and (max-width: 850px) {
      width: 95% !important;
    }
  }

  .ant-modal-body {
    padding: 0;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    background: #ffffff;
  }

  .ant-modal-content {
    border-radius: 26px;
    border: 0;
    border: 1px solid @green-color;
  }

  .ant-modal-footer {
    text-align: center;
    border: none;
    padding: 0;
    padding-bottom: 96px;
    margin: 0px auto;

    @media screen and (max-width: 800px) {
      padding-bottom: 56px;
    }

    @media screen and (max-width: 400px) {
      padding-bottom: 24px;
    }
  }

}