@import '../../../assets/style/_variables.less';

body::-webkit-scrollbar {
  width: 0.5px;
}

.button-landing {
  left: 0;
}

.modal-content-view {
  .back-to-back {
    position: absolute;
    z-index: 0;
    width: 100%;
    top: 0;
    border-top-left-radius: 26px;
    border-top-right-radius: 26px;
  }

  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
    color: #ffffff;
    background: #7b35df;
  }

  .general-info .equipmentList h2 {
    margin: 24px auto;
  }

  .general-info .image-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 450px;
  }

  .business-model {
    margin: 40px 0px;
    height: 100%;
    overflow: visible;
    min-height: 20vh;
    max-width: 760px;
    width: 100%;
  }

  .title-bmc {
    padding: 10px !important;
    text-indent: 10px !important;
    font-size: 22px !important;
  }

  .title {
    font-size: 14px !important;
  }

  .test-kp {
    border-left: 1px solid @primary-color  !important;
  }

  .test-cst {
    border-left: 1px solid @primary-color  !important;
  }

  .test-cseg {
    border-right: 1px solid @primary-color  !important;
  }

  .customer-segments {
    border-right: 1px solid @primary-color  !important;
  }

  .test-rs {
    border-right: 1px solid @primary-color  !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
    font-weight: bold;
  }

  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
    border: 1px solid #c4c4c4;
    border-bottom: none;
  }

  .ant-tabs-top>.ant-tabs-nav::before,
  .ant-tabs-bottom>.ant-tabs-nav::before,
  .ant-tabs-top>div>.ant-tabs-nav::before,
  .ant-tabs-bottom>div>.ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0;
    content: '';
  }

  .ant-tabs-tab {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;

    &:hover {
      color: @primary-color;
    }
  }

  .ant-tabs-tab-btn {
    &::selection {
      background: lemonchiffon;
    }

    &:hover {
      color: @primary-color;
    }
  }

  .startup-name span {
    border-radius: 6px;
    font-size: 18px;
    line-height: 20px;
    padding: 10px;
  }

  .signUp {
    padding: 7px 7px;
    min-width: 110px;
    font-size: 14px;
  }

  .raising {
    max-width: 137px !important;
  }

  .ant-rate {
    font-size: 12px;
  }

  .avatars-backg-holder {
    max-width: 128px !important;
    border-radius: 20px;
    padding-top: 27px;
    height: 128px !important;
    margin-top: 0px !important;
    z-index: 1;

    @media screen and (max-width: 500px) {
      max-width: 150px;
      border-radius: 20px;
      max-height: 150px;
      position: relative;
      height: 150px;
      margin-top: 24px;

      img {
        position: absolute;
        position: absolute;
        width: 100%;
        bottom: 0;
        max-width: 100px;
      }
    }

    img {
      max-width: 94px !important;
    }
  }

  //--Nikola pisao css :D jer Kaldrma nema fugu :D :D
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: @font-family;
    margin-bottom: 24px;
    font-size: 16px;

    @media screen and (max-width: 500px) {
      margin-bottom: 8px;
    }

    &:nth-child(1) {
      margin-top: 34px;
    }

    .static {
      color: #ABABAB;
    }

    .dynamic {
      font-weight: bold;
    }

    .coin-image img {
      max-width: 20px;
      vertical-align: middle;
      margin-left: 5px;
    }
  }

  //-- Izbrisati ovo sta ne bude trebalo Micko brate moj <3 
  .contentHeader h3 {
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    font-family: @font-family;
    max-width: 800px;
    width: 98%;
    text-align: center;
    margin: 32px auto 24px auto;
  }

  .contentPrice {
    font-family: @font-family-secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 26px;
    margin: 0px auto 24px auto;
    text-align: center;
    color: #57d22c;

    img {
      max-width: 30px;
      margin-left: 10px;
      position: relative;
      top: -2px;
    }

    @media screen and (max-width: 654px) {
      margin: 0px 15px 16px 15px;
      font-size: 17px;
    }
  }

  .contentDescription {
    font-family: @font-family-secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    max-width: 355px;
    width: 100%;
    margin: 0px auto;
  }

  .ant-btn-primary {
    color: white;
    background-color: #7b35df;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #7b35df;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 7px !important;
    margin: 5px auto;
    padding: 10px 15px !important;
    height: auto !important;
    width: auto;
    min-width: 150px;
    font-size: 20px !important;
    text-transform: uppercase;
  }

  .ant-btn-action {
    display: none !important;
  }

  .ant-modal-footer {
    text-align: center;
    border: none;
    padding: 0;
    padding-bottom: 32px;
    margin: 24px auto 0px auto;
  }

  .industry-backg-holder {
    width: 100%;
    max-width: 600px;
    height: 200px;
    background: #efe6fc;
    border: none;
    border-radius: 0;
    border-bottom: 0 !important;
    display: grid;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    grid-template-columns: auto;
    align-items: flex-start;

    img {
      max-width: 200px;
      width: 100%;
      height: auto;
    }
  }

  .ant-modal {
    max-width: 800px;
    width: 96% !important;
    top: 30px;
    padding-bottom: 0px !important;
    border: 0 !important;
    margin-bottom: 50px;
  }

  .ant-modal-body {
    padding: 0;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    background: #ffffff;
  }

  .ant-modal-content {
    border-left: 0;
    padding: 20px;
    border: 1px solid @primary-color  !important;
    border-radius: 12px !important;
  }


}