@import "../../../assets/style/_variables.less";

.ant-notification-notice-icon img {
    max-width: 50px;
}

.ant-notification-notice-message {
    margin-left: 70px !important;
}

.ant-notification-notice-description {
    margin-left: 70px !important;
}

.ant-notification-notice {
    border: 1px solid #7c36df;
    border-radius: 12px;
}

.select-bill {
    width: 100%;
    margin: 0px auto;
    display: grid;
    align-content: center;

    .select-bill-section {
        width: 95%;
        display: block;
        margin: 0px auto;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1200px;
        margin-bottom: @layout-footer-height * 2 + 94px;

        @media screen and (max-width: 800px) {
            margin-top: @layout-footer-height;
            margin-bottom: @layout-footer-height * 2 + 59px;
        }

        @media screen and (max-width: 800px) {
            .signIn {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }

            .buttonMakeAcc {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }
        }

        .totalCount {
            display: inline-block;
            margin: 24px auto;
            width: 100%;
            max-width: 450px;
            animation-duration: 2s;
            animation-fill-mode: both;

            .total-count-text {
                background-color: @primary-color;
                max-width: 186px;
                padding: 5px;
                margin: 0px auto;
                margin-top: 64px;
                color: white;
                font-size: 18px;
                border-top-left-radius: 26px;
                border-top-right-radius: 26px;
            }

            .total-count-number {
                max-width: 244px;
                padding: 32px;
                border: 1px solid @primary-color;
                border-radius: 26px;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 20px;
                color: #57d22c;
                margin: 0px auto;
                background: white;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 25px;
                    margin-left: 10px;
                }

                @media screen and (max-width: 600px) {
                    padding: 24px;
                }
            }
        }

        .bill-text {
            border: 1px solid @primary-color;
            color: black;
            width: 100%;
            max-width: 355px;
            border-radius: 12px;
            background: white;

            h3 {
                font-family: @font-family;
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                line-height: 32px;
                text-align: center;
                color: #000000;
                margin: 32px 0px 8px 0px;

                @media screen and (max-width: 800px) {
                    margin: 16px 0px 8px 0px;
                    font-size: 18px;
                }
            }

            .nexth3 {
                margin-top: -8px;
            }

            p {
                font-family: @font-family-secondary;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 26px;
                margin: 0px 32px 32px 32px;

                img {
                    max-width: 25px;
                    margin-left: 10px;
                }

                @media screen and (max-width: 800px) {
                    margin: 0px 15px 16px 15px;
                    font-size: 17px;
                }
            }

            .green {
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 20px;
                color: #57d22c;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        h2 {
            font-weight: bold;
            font-size: 48px;
            line-height: normal;
            font-family: @font-family;
            max-width: 1200px;
            text-align: center;
            margin: 96px auto 48px auto;
            width: 100%;

            @media screen and (max-width: 800px) {
                font-size: 30px;
                margin: 0px 0px 20px 0px;
            }

            @media screen and (max-width: 500px) {
                font-size: 22px;
            }
        }

        .calendar {
            position: relative;
            width: auto;
            height: auto;
            margin-bottom: 48px;

            .current-month {
                position: absolute;
                top: 60%;
                left: 50%;
                width: 140%;
                margin: auto;
                transform: translate(-50%, -50%);
                background: @primary-color;
                color: #fff;
                margin: 0px auto;
                padding: 14px 0px;
                border-radius: 32px;
                transition: all 0.15s ease-in-out;
                font-family: Ubuntu, monospace;
                text-transform: capitalize;
                font-size: 26px;
                line-height: 48px;
                font-weight: bold;
                box-shadow: 0 10px 0 #5825a1, 0 7px 7px 1px rgba(0, 0, 0, .4);
            }
        }

        .button-landing {
            position: fixed;
            bottom: 0;
            width: 100%;
            background-color: white;
            padding-bottom: 15px;
            padding-top: 15px;
            border-top: 1px solid #d4d4d4;
            right: 0;
            margin-bottom: @layout-footer-height;

            @media screen and (max-width: 800px) {
                padding-bottom: 7px;
                padding-top: 7px;
            }
        }

        .button-bill-holder {
            animation-duration: 2s;
            animation-fill-mode: both;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 32px;
            grid-auto-rows: minmax(100px, auto);
            width: 100%;

            @media screen and (max-width: 800px) {
                padding: 10px 10px;
                grid-gap: 8px;
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: 400px) {
                padding: 10px 10px;
                grid-gap: 8px;
                grid-template-columns: repeat(1, 1fr);
            }

            div {
                cursor: pointer;
            }
        }
    }

    .fadeInBottom-payment {
        animation-name: fadeInBottom-payment;
    }

    @keyframes fadeInBottom-payment {
        from {
            opacity: 0;
            transform: translateY(10%);
        }

        to {
            opacity: 1;
        }
    }
}
