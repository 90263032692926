.header-main {
  .signIn {
    border: none;
    color: black;
  }

  .logo-place {
    cursor: pointer;
  }

  .inline {
    display: flex;
  }

  @media screen and (max-width: 450px) and (min-width: 300px) {
    .signIn {
      margin: 3px;
      padding: 6px 10px;
      font-size: 14px;
      font-weight: bold;
    }

    .signUp {
      margin: 3px;
      padding: 6px 10px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 300px) and (min-width: 200px) {
    .signIn {
      margin: 2px;
      padding: 4px 8px;
      font-size: 13px;
      font-weight: bold;
    }

    .signUp {
      margin: 2px;
      padding: 4px 8px;
      font-size: 13px;
      font-weight: bold;
    }
  }
}
