@import '../../../assets/style/_variables.less';

.ant-modal-content {
    overflow: hidden;
}

.modal-content-equipment {
    .video1 {
        width: 100%;
    }
}

.positive-feedback {
    .image-holder-random {
        width: 100%;
        // min-height: 200px;
        // max-height: 200px;
        position: relative;
        margin-bottom: 25px;
        // background-image: url('../../../assets/images/bg-pos.png');
        background-size: 235px auto;
        text-align: center;
        animation: movimiento 3.5s linear infinite;



        h1 {
            margin: 0px auto;
            font-size: 50px;
            padding: 0;
            color: #b3f792;
            text-shadow: 0 0.1em 20px #000000, 0.05em -0.03em 0 #000000, 0.05em 0.005em 0 #000000, 0em 0.08em 0 #000000, 0.05em 0.08em 0 #000000, 0px -0.03em 0 #000000, -0.03em -0.03em 0 #000000, -0.03em 0.08em 0 #000000, -0.03em 0 0 #000000;
            position: relative;
            top: 50px;
            display: block;
            text-align: center;
            left: 0;
            right: 0;

            span {
                transform: scale(0.9);
                display: inline-block;
            }

            span:first-child {
                animation: bop 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite alternate;
            }

            span:last-child {
                animation: bopB 1s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite alternate;
            }
        }

        @keyframes movimiento {
            0% {
                background-position: 0 0;
            }

            100% {
                background-position: 235px 235px;
            }
        }


        @keyframes bop {
            0% {
                transform: scale(0.9);
            }

            50%,
            100% {
                transform: scale(1);
            }
        }

        @keyframes bopB {
            0% {
                transform: scale(0.9);
            }

            80%,
            100% {
                transform: scale(1) rotateZ(-3deg);
            }
        }
    }

    .mentor-vision {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-direction: alternate;

        .mentor-text-random {
            color: black;
            width: 100%;
            border-radius: 24px;
            padding-bottom: 30px;

            h3 {
                background-color: @primary-color;
                font-family: @font-family;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 22px;
                text-align: left;
                color: #ffffff;
                margin: 0px 0px 32px 0px;
                padding: 32px;
                border-top-left-radius: 24px;
                border-top-right-radius: 24px;

                @media screen and (max-width: 800px) {
                    font-size: 22px;
                }
            }
        }

        .text-speach-random {
            width: 100%;
            padding: 0px 32px 0px;

            .mentor-talk-random {
                display: inline-grid;
                grid-template-columns: auto 1fr;
                grid-gap: 5px;
                font-family: @font-family-secondary;
                font-size: 18px;
                width: 100%;
                z-index: 1111;
                position: relative;

                @media screen and (max-width: 600px) {
                    font-size: 16px;
                }

                .avatars-backg-holder {
                    width: 82px;
                    height: 82px;
                    padding-top: 22px;
                    border-radius: 20px;
                    -webkit-animation-name: bounceIn;
                    -moz-animation-name: bounceIn;
                    -o-animation-name: bounceIn;
                    animation-name: bounceIn;
                    animation-duration: 1s;
                    animation-fill-mode: both;
                    animation-direction: alternate;

                    img {
                        width: 100%;
                        max-width: 50px;
                        position: relative;
                    }
                }

                .hi-msg-random {
                    grid-column: 1 / 2;
                    text-align: left;
                    background-color: @primary-color;
                    color: #ffffff;
                    padding: 24px;
                    border-top-left-radius: 26px;
                    border-top-right-radius: 26px;
                    border-bottom-right-radius: 26px;
                    // max-width: 220px;
                    align-self: baseline;
                    display: grid;
                    align-items: center;
                    height: 100%;
                    max-height: 69px;
                    -webkit-animation-name: bounceIn;
                    -moz-animation-name: bounceIn;
                    -o-animation-name: bounceIn;
                    animation-name: bounceIn;
                    animation-duration: 1s;
                    animation-fill-mode: both;
                    animation-direction: alternate;
                    width: fit-content;

                    @media screen and (max-width: 600px) {
                        padding: 16px;
                    }
                }

                .expl-msg-random {
                    grid-column: 2;
                    text-align: left;
                    background-color: @primary-color;
                    color: #ffffff;
                    padding: 24px;
                    border-top-left-radius: 26px;
                    border-top-right-radius: 26px;
                    border-bottom-right-radius: 26px;
                    max-width: 550px;
                    line-height: 26px;
                    -webkit-animation-name: bounceIn;
                    -moz-animation-name: bounceIn;
                    -o-animation-name: bounceIn;
                    animation-name: bounceIn;
                    animation-duration: 1s;
                    animation-fill-mode: both;
                    animation-direction: alternate;

                    @media screen and (max-width: 600px) {
                        padding: 16px;
                    }
                }
            }

            .user-talk-random {
                display: inline-grid;
                grid-template-columns: 1fr auto;
                grid-gap: 5px;
                font-family: @font-family-secondary;
                font-size: 18px;
                line-height: 21px;
                width: 100%;

                @media screen and (max-width: 600px) {
                    font-size: 16px;
                }

                .avatars-backg-holder {
                    width: 82px;
                    height: 82px;
                    padding-top: 22px;
                    border-radius: 20px;
                    margin: 0px auto;
                    margin-left: 19px;
                    grid-column: 2;
                    grid-row: 1;
                    max-width: 82px;

                    img {
                        width: 100%;
                        max-width: 50px;
                    }
                }

                .hi-msg-user-random {
                    grid-column: 1;
                    grid-row: 1;
                    justify-self: end;
                    text-align: left;
                    background-color: #EFE6FC;
                    color: #000000;
                    padding: 24px;
                    border-top-left-radius: 26px;
                    border-top-right-radius: 26px;
                    border-bottom-left-radius: 26px;
                    max-width: 550px;

                    @media screen and (max-width: 600px) {
                        padding: 16px;
                    }
                }
            }
        }





    }
}

.negative-feedback {
    .image-holder-random {
        width: 100%;
        // min-height: 200px;
        // max-height: 200px;
        position: relative;
        margin-bottom: 25px;
        // background-image: url('../../../assets/images/bg-neg.png');
        background-size: 235px auto;
        text-align: center;
        animation: movimiento 3.5s linear infinite;



        h1 {
            margin: 0px auto;
            font-size: 50px;
            padding: 0;
            color: red;
            text-shadow: 0 0.1em 20px #000000, 0.05em -0.03em 0 #000000, 0.05em 0.005em 0 #000000, 0em 0.08em 0 #000000, 0.05em 0.08em 0 #000000, 0px -0.03em 0 #000000, -0.03em -0.03em 0 #000000, -0.03em 0.08em 0 #000000, -0.03em 0 0 #000000;
            position: relative;
            top: 50px;
            display: block;
            text-align: center;
            left: 0;
            right: 0;

            span {
                transform: scale(0.9);
                display: inline-block;
            }

            span:first-child {
                animation: bop 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite alternate;
            }

            span:last-child {
                animation: bopB 1s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite alternate;
            }
        }

        @keyframes movimiento {
            0% {
                background-position: 0 0;
            }

            100% {
                background-position: 235px 235px;
            }
        }


        @keyframes bop {
            0% {
                transform: scale(0.9);
            }

            50%,
            100% {
                transform: scale(1);
            }
        }

        @keyframes bopB {
            0% {
                transform: scale(0.9);
            }

            80%,
            100% {
                transform: scale(1) rotateZ(-3deg);
            }
        }
    }

    .mentor-vision {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-direction: alternate;

        .mentor-text-random {
            color: black;
            width: 100%;
            border-radius: 24px;
            padding-bottom: 30px;

            h3 {
                background-color: @primary-color;
                font-family: @font-family;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 22px;
                text-align: left;
                color: #ffffff;
                margin: 0px 0px 32px 0px;
                padding: 32px;
                border-top-left-radius: 24px;
                border-top-right-radius: 24px;

                @media screen and (max-width: 800px) {
                    font-size: 22px;
                }
            }
        }

        .text-speach-random {
            width: 100%;
            padding: 0px 32px 0px;

            .mentor-talk-random {
                display: inline-grid;
                grid-template-columns: auto 1fr;
                grid-gap: 5px;
                font-family: @font-family-secondary;
                font-size: 18px;
                width: 100%;
                z-index: 1111;
                position: relative;

                @media screen and (max-width: 600px) {
                    font-size: 16px;
                }

                .avatars-backg-holder {
                    width: 82px;
                    height: 82px;
                    padding-top: 22px;
                    border-radius: 20px;
                    -webkit-animation-name: bounceIn;
                    -moz-animation-name: bounceIn;
                    -o-animation-name: bounceIn;
                    animation-name: bounceIn;
                    animation-duration: 1s;
                    animation-fill-mode: both;
                    animation-direction: alternate;

                    img {
                        width: 100%;
                        max-width: 50px;
                        position: relative;
                    }
                }

                .hi-msg-random {
                    grid-column: 1 / 2;
                    text-align: left;
                    background-color: @primary-color;
                    color: #ffffff;
                    padding: 24px;
                    border-top-left-radius: 26px;
                    border-top-right-radius: 26px;
                    border-bottom-right-radius: 26px;
                    //max-width: 220px;
                    width: fit-content;
                    align-self: baseline;
                    display: grid;
                    align-items: center;
                    height: 100%;
                    max-height: 69px;
                    -webkit-animation-name: bounceIn;
                    -moz-animation-name: bounceIn;
                    -o-animation-name: bounceIn;
                    animation-name: bounceIn;
                    animation-duration: 1s;
                    animation-fill-mode: both;
                    animation-direction: alternate;

                    @media screen and (max-width: 600px) {
                        padding: 16px;
                    }
                }

                .expl-msg-random {
                    grid-column: 2;
                    text-align: left;
                    background-color: @primary-color;
                    color: #ffffff;
                    padding: 24px;
                    border-top-left-radius: 26px;
                    border-top-right-radius: 26px;
                    border-bottom-right-radius: 26px;
                    max-width: 550px;
                    line-height: 26px;
                    -webkit-animation-name: bounceIn;
                    -moz-animation-name: bounceIn;
                    -o-animation-name: bounceIn;
                    animation-name: bounceIn;
                    animation-duration: 1s;
                    animation-delay: 2s;
                    animation-fill-mode: both;
                    animation-direction: alternate;

                    @media screen and (max-width: 600px) {
                        padding: 16px;
                    }
                }
            }

            .user-talk-random {
                display: inline-grid;
                grid-template-columns: 1fr auto;
                grid-gap: 5px;
                font-family: @font-family-secondary;
                font-size: 18px;
                line-height: 21px;
                width: 100%;

                @media screen and (max-width: 600px) {
                    font-size: 16px;
                }

                .avatars-backg-holder {
                    width: 82px;
                    height: 82px;
                    padding-top: 22px;
                    border-radius: 20px;
                    margin: 0px auto;
                    margin-left: 19px;
                    grid-column: 2;
                    grid-row: 1;
                    max-width: 82px;

                    img {
                        width: 100%;
                        max-width: 50px;
                    }
                }

                .hi-msg-user-random {
                    grid-column: 1;
                    grid-row: 1;
                    justify-self: end;
                    text-align: left;
                    background-color: #EFE6FC;
                    color: #000000;
                    padding: 24px;
                    border-top-left-radius: 26px;
                    border-top-right-radius: 26px;
                    border-bottom-left-radius: 26px;
                    max-width: 550px;

                    @media screen and (max-width: 600px) {
                        padding: 16px;
                    }
                }
            }
        }


        @-webkit-keyframes bounceIn {
            0% {
                opacity: 0;
                -webkit-transform: scale(.3);
            }

            50% {
                -webkit-transform: scale(1.05);
            }

            70% {
                -webkit-transform: scale(.9);
            }

            100% {
                opacity: 1;
                -webkit-transform: scale(1);
            }
        }

        @-moz-keyframes bounceIn {
            0% {
                opacity: 0;
                -moz-transform: scale(.3);
            }

            50% {
                -moz-transform: scale(1.05);
            }

            70% {
                -moz-transform: scale(.9);
            }

            100% {
                opacity: 1;
                -moz-transform: scale(1);
            }
        }

        @-o-keyframes bounceIn {
            0% {
                opacity: 0;
                -o-transform: scale(.3);
            }

            50% {
                -o-transform: scale(1.05);
            }

            70% {
                -o-transform: scale(.9);
            }

            100% {
                opacity: 1;
                -o-transform: scale(1);
            }
        }

        @keyframes bounceIn {
            0% {
                opacity: 0;
                transform: scale(.3);
            }

            50% {
                transform: scale(1.05);
            }

            70% {
                transform: scale(.9);
            }

            100% {
                opacity: 1;
                transform: scale(1);
            }
        }






    }
}