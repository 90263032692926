@import '../../../assets/style/_variables.less';

.chart-comp {
  padding-top: 25px;
  width: 100%;
  max-width: 520px;
  border-radius: 12px;
  margin: 0px auto;

  div {
    margin: 0px auto;
  }
}