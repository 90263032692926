@import '../../../../assets/style/_variables.less';

.leaderboard {

    .thead {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 0px auto;
        font-size: 22px;
        font-weight: bold;

        @media screen and (max-width: 500px) {
            font-size: 16px;
        }
    }

    .table {
        width: 100%;
        margin-bottom: 20px;
    }

    .players-list {
        display: inline-block;
        background: #EFE6FC;
        border: 1px solid @primary-color;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 8px;
        width: 100%;

        @media screen and (max-width: 500px) {
            margin-bottom: 10px;
        }

        .number-list {
            font-weight: bold;
            margin-right: 10px;
            font-size: 15px;

            @media screen and (max-width: 800px) {
                font-size: 14px;
            }

            @media screen and (max-width: 600px) {
                font-size: 13px;
                margin-right: 5px;
            }
        }

        p {
            margin: 0 !important;
            float: left;
            font-size: 16px;
            overflow-wrap: anywhere;

            @media screen and (max-width: 500px) {
                float: none;
            }

            @media screen and (max-width: 800px) {
                font-size: 15px;
            }

            @media screen and (max-width: 600px) {
                font-size: 14px;
            }

            @media screen and (max-width: 500px) {
                float: none;
            }

            @media screen and (max-width: 400px) {
                font-size: 12px;
            }
        }

        .right-links {
            float: right;

            span {
                margin: 0;
                padding: 0;
                color: @primary-color;
                font-weight: bold;

                img {
                    max-width: 30px;
                    vertical-align: middle;

                    @media screen and (max-width: 800px) {
                        max-width: 25px;
                    }

                    @media screen and (max-width: 400px) {
                        max-width: 20px;
                    }
                }
            }
        }
    }



    .players-list-matched {
        display: inline-block;
        background: @primary-color;
        border: 1px solid @primary-color;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 8px;
        width: 100%;
        color: white;

        @media screen and (max-width: 500px) {
            margin-bottom: 10px;
        }

        .number-list {
            font-weight: bold;
            margin-right: 10px;
            font-size: 15px;

            @media screen and (max-width: 800px) {
                font-size: 14px;
            }

            @media screen and (max-width: 600px) {
                font-size: 13px;
                margin-right: 5px;
            }
        }

        p {
            margin: 0 !important;
            float: left;
            font-size: 16px;
            overflow-wrap: anywhere;

            @media screen and (max-width: 500px) {
                float: none;
            }

            @media screen and (max-width: 800px) {
                font-size: 15px;
            }

            @media screen and (max-width: 600px) {
                font-size: 14px;
            }

            @media screen and (max-width: 500px) {
                float: none;
            }

            @media screen and (max-width: 400px) {
                font-size: 12px;
            }
        }

        .right-links {
            float: right;

            span {
                margin: 0;
                padding: 0;
                font-weight: bold;

                img {
                    max-width: 30px;
                    vertical-align: middle;

                    @media screen and (max-width: 800px) {
                        max-width: 25px;
                    }

                    @media screen and (max-width: 400px) {
                        max-width: 20px;
                    }
                }
            }
        }
    }
}