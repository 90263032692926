@import '../../assets/style/_variables.less';

.custom-error {
  span {
    font-size: 26px !important;
  }

  .ant-message-notice-content {
    padding: 15px 20px;
    border: 2px solid red;
  }
}

.forma-reg-signIn {
  max-width: 549px;
  width: 100%;
  border-radius: 36px;
  box-shadow: 0px 5px 35px -10px #bfbfbf;
  border: 1px solid #ebebeb;
  margin: 0px auto;
  padding-bottom: 20px;
  background: white;
  font-family: @font-family;
  margin-top: @layout-footer-height;
  margin-bottom: @layout-footer-height + 25px;

  .ant-spin-spinning {
    display: inline-block;
    opacity: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;
    text-align: center;
    z-index: 111;
  }

  .spinner-back {
    background-color: fade(white, 60%);
    width: 100%;
    display: inline-block;
    opacity: 1;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    text-align: center;
    z-index: 111;
    height: 100vh;
  }

  .spinner-non-back {
    display: none;
  }

  .ant-spin-dot-item {
    background-color: @primary-color;
  }

  .signIn {
    font-size: 20px;
    color: #fff;
    background: @primary-color;
    border: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 7px;
    padding: 10px;
    height: auto;
    width: 100%;
    max-width: 100px;
    font-weight: bold;
    margin: 0;
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-form {
    text-align: left;
    padding: 20px 30px;
    padding-bottom: 0;

    @media screen and (max-width: 500px) {
      padding: 0px 24px;
    }
  }

  .ant-col-offset-8 {
    margin-left: 0px;
  }

  .ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 17px 13px;
    color: @primary-color;
    background-color: #fff;
    background-image: none;
    border: 1px solid @primary-color;
    border-radius: 5px;
    transition: all 0.3s;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
  }

  .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 17px 13px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid @primary-color;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .ant-input-number-input-wrap {
    border: 1px solid @primary-color;
    border-radius: 5px;
    padding: 10px 10px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
  }

  .ant-input-number-input {
    height: auto;
  }

  label {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    color: #c0b2d4 !important;
  }

  .ant-select-selector {
    border: 1px solid @primary-color  !important;
    border-radius: 5px !important;
    padding: 6px 5px !important;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    height: auto !important;
  }

  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-select-arrow {
    color: rgb(87 36 179);
  }

  .sc-networks {
    padding: 0px 30px;

    @media screen and (max-width: 500px) {
      padding: 0px 24px;
    }
  }

  .ant-form-item-label {
    text-align: left !important;
  }

  span {
    font-weight: bold;
  }

  .sc-networks-p {
    text-align: left;
    margin: 0px;
    padding-top: 0;
    color: @primary-color;
    font-family: @font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    @media screen and (max-width: 300px) {
      font-size: 14px;
    }
  }

  .sc-networks-images img {
    max-width: 30px;

    @media screen and (max-width: 300px) {
      max-width: 25px;
    }
  }

  .sc-networks-images {
    text-align: left;
    margin-bottom: 15px;
    margin-top: 10px;
  }
}

.forma-reg-signIn h1 {
  text-align: left;
  padding: 20px 30px;
  border-bottom: 1px solid gainsboro;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;

  @media screen and (max-width: 500px) {
    padding: 20px 24px;
  }
}

.add-me {
  float: right;
  max-width: 30px;
}

@media screen and (max-width: 600px) {
  .forma-reg-signIn {
    max-width: 449px;
    width: 96%;
    border-radius: 20px;
    box-shadow: 0px 4px 22px -8px grey;
    border: 1px solid #e8e8e8;
    margin: 0px auto;
    margin-top: @layout-header-height - @layout-footer-height - 20px;
    margin-bottom: @layout-footer-height + 20px;

    .ant-input {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum', 'tnum', 'tnum';
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 5px 5px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 12px;
      line-height: 1.1715;
      background-color: #fff;
      background-image: none;
      border: 1px solid @primary-color;
      border-radius: 5px;
      transition: all 0.3s;
      height: 34px;
    }

    .ant-input-affix-wrapper {
      padding: 5px 5px;
      height: 34px;
    }

    .ant-input-number-input-wrap {
      padding: 5px 5px;
      height: 34px;
    }

    .ant-select-selector {
      padding: 5px 5px !important;
      height: 34px;
    }

    #basic_password {
      height: auto;
    }

    h2 {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    .ant-btn-primary {
      font-size: 15px;
      padding: 10px;
      height: auto;
      width: 100%;
      max-width: 80px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .ant-form {
      padding-top: 0;
    }

    .ant-input-number-input {
      padding: 5px 5px;
      font-size: 12px;
      line-height: 1.1715;
    }

    .ant-input-password input {
      height: 20px;
    }

    h1 {
      font-size: 25px;
    }
  }
}