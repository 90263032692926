@import '../../../assets/style/_variables.less';

.modal-content-mentor-intro {
  background-image: url('../../../assets/images/right-bubble.png'), url('../../../assets/images/left-bubble.png');
  background-position: right bottom, left top;
  background-repeat: no-repeat, no-repeat;
  background-size: contain, contain;
  background-color: white;
}

.ant-modal-mask,
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: #ffffffd4;
  filter: alpha(opacity=50);
}

.button-landing {
  left: 0;
}

.modal-content-mentor-intro {


  .hidde {
    display: none;
  }

  .ant-modal-content {
    background: none !important;
    box-shadow: none;
  }

  .ant-modal-body {
    background: none !important;
  }

  .meet-mentor-section {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;


    .button-mentor-holder {
      display: flex;
      justify-content: space-between;
      max-width: 830px;
      width: 100%;

      @media screen and (max-width: 800px) {
        display: block;
      }
    }

    .mentor-text {
      color: black;
      width: 100%;
      border-radius: 24px;
      padding-bottom: 24px;

      h3 {
        background: none;
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 32px;
        text-align: center;
        color: #000000;
        margin: 0px 0px 32px 0px;
        padding: 32px;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;

        @media screen and (max-width: 800px) {
          font-size: 18px;
          padding: 16px;
        }
      }

      .contentHeader {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }
    }

    .text-speach {
      width: 100%;
      padding: 0px 32px 0px;

      @media screen and (max-width: 800px) {
        padding: 0px 16px 0px;
      }


      .mentor-talk {
        display: inline-grid;
        grid-template-columns: auto 1fr;
        grid-gap: 5px;
        font-family: @font-family-secondary;
        font-size: 18px;
        width: 100%;

        .avatars-backg-holder {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 1s;
        }

        @media screen and (max-width: 600px) {
          font-size: 16px;
        }

        .fadeFirst {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 1s;
        }


        .fadeSecond {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 4s;
        }

        .fadeThird {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 7s;
        }

        .fadeFourth {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 9s;
        }

        .fadeFifth {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 11s;
        }


        .avatars-backg-holder {
          width: 82px;
          height: 82px;
          display: grid;
          padding-top: 0px;
          border-radius: 20px;
          margin: 0px auto;
          margin-right: 19px;
          grid-column: 1;
          justify-content: center;
          align-items: end;

          @media screen and (max-width: 500px) {
            width: 60px;
            height: 60px;
            padding-top: 14px;
            border-radius: 14px;
            margin: 0px auto;
            margin-right: 8px;
          }

          img {
            width: 100%;
            max-width: 50px;
            position: relative;

            @media screen and (max-width: 500px) {
              max-width: 40px;
              top: -3px;
            }
          }
        }

        .hi-msg {
          grid-column: 2;
          text-align: left;
          background-color: @primary-color;
          color: #ffffff;
          padding: 16px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-right-radius: 26px;
          max-width: 650px;
          align-self: baseline;
          display: grid;
          align-items: center;
          height: 100%;
          width: fit-content;
          width: 100%;

          @media screen and (max-width: 800px) {
            padding: 14px;
            margin-bottom: 5px;
          }

          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
        }

        .expl-msg {
          grid-column: 2;
          text-align: left;
          background-color: @primary-color;
          color: #ffffff;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-right-radius: 26px;
          max-width: 650px;
          line-height: 26px;
          margin-top: 10px;
          width: fit-content;

          @media screen and (max-width: 600px) {
            padding: 16px;
            font-size: 14px;
          }
        }
      }

      .user-talk {
        display: inline-grid;
        grid-template-columns: 1fr auto;
        grid-gap: 5px;
        font-family: @font-family-secondary;
        font-size: 18px;
        line-height: 26px;
        width: 100%;

        @media screen and (max-width: 600px) {
          font-size: 16px;
        }

        .fadeSecond {
          -webkit-animation-name: bounceInRight;
          -moz-animation-name: bounceInRight;
          -o-animation-name: bounceInRight;
          animation-name: bounceInRight;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 2.5s;
        }

        .avatars-backg-holder {
          width: 82px;
          height: 82px;
          display: grid;
          padding-top: 0;
          border-radius: 20px;
          margin: 0px auto;
          margin-left: 19px;
          grid-column: 2;
          grid-row: 1;
          max-width: 82px;
          align-items: end;
          -webkit-animation-name: bounceInRight;
          -moz-animation-name: bounceInRight;
          -o-animation-name: bounceInRight;
          animation-name: bounceInRight;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 2.5s;

          @media screen and (max-width: 500px) {
            width: 60px;
            height: 60px;
            padding-top: 12px;
            border-radius: 14px;
            margin: 0px auto;
            margin-right: 8px;
          }

          img {
            width: 100%;
            max-width: 50px;
            bottom: -0.5px;
            position: relative;

            @media screen and (max-width: 500px) {
              max-width: 40px;
            }
          }
        }

        .hi-msg-user {
          grid-column: 1;
          grid-row: 1;
          justify-self: end;
          text-align: left;
          background-color: #EFE6FC;
          color: #000000;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-left-radius: 26px;
          max-width: 550px;

          @media screen and (max-width: 600px) {
            padding: 16px;
            font-size: 14px;
          }
        }
      }

      .mentorMore {

        .avatars-backg-holder {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 6s;
        }

        .fadeSecond {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 6s;
        }

        .fadeThird {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 6.5s;
        }
      }

      .userMore {

        .avatars-backg-holder {
          -webkit-animation-name: bounceInRight;
          -moz-animation-name: bounceInRight;
          -o-animation-name: bounceInRight;
          animation-name: bounceInRight;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 8.5s;
        }

        .fadeSecond {
          -webkit-animation-name: bounceInRight;
          -moz-animation-name: bounceInRight;
          -o-animation-name: bounceInRight;
          animation-name: bounceInRight;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 8.5s;
        }
      }


      .mentorMore2 {

        .avatars-backg-holder {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 11s;
        }

        .fadeSecond {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 11s;
        }

        .fadeThird {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
          animation-delay: 12s;
        }
      }
    }


    /*ANIMATIONS*/

    @-webkit-keyframes bounceIn {
      0% {
        opacity: 0;
        -webkit-transform: scale(.3);
      }

      50% {
        -webkit-transform: scale(1.05);
      }

      70% {
        -webkit-transform: scale(.9);
      }

      100% {
        opacity: 1;
        -webkit-transform: scale(1);
      }
    }

    @-moz-keyframes bounceIn {
      0% {
        opacity: 0;
        -moz-transform: scale(.3);
      }

      50% {
        -moz-transform: scale(1.05);
      }

      70% {
        -moz-transform: scale(.9);
      }

      100% {
        opacity: 1;
        -moz-transform: scale(1);
      }
    }

    @-o-keyframes bounceIn {
      0% {
        opacity: 0;
        -o-transform: scale(.3);
      }

      50% {
        -o-transform: scale(1.05);
      }

      70% {
        -o-transform: scale(.9);
      }

      100% {
        opacity: 1;
        -o-transform: scale(1);
      }
    }

    @keyframes bounceIn {
      0% {
        opacity: 0;
        transform: scale(.3);
      }

      50% {
        transform: scale(1.05);
      }

      70% {
        transform: scale(.9);
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    @-webkit-keyframes bounceInLeft {
      0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
      }

      60% {
        -webkit-transform: translateX(20px);
      }

      80% {
        -webkit-transform: translateX(-5px);
      }

      100% {
        opacity: 1;
        -webkit-transform: translateX(0);
      }
    }

    @-moz-keyframes bounceInLeft {
      0% {
        opacity: 0;
        -moz-transform: translateX(-2000px);
      }

      60% {
        -moz-transform: translateX(20px);
      }

      80% {
        -moz-transform: translateX(-5px);
      }

      100% {
        opacity: 1;
        -moz-transform: translateX(0);
      }
    }

    @-o-keyframes bounceInLeft {
      0% {
        opacity: 0;
        -o-transform: translateX(-2000px);
      }

      60% {
        opacity: 1;
        -o-transform: translateX(20px);
      }

      80% {
        -o-transform: translateX(-5px);
      }

      100% {
        opacity: 1;
        -o-transform: translateX(0);
      }
    }

    @keyframes bounceInLeft {
      0% {
        opacity: 0;
        transform: translateX(-2000px);
      }

      60% {
        transform: translateX(20px);
      }

      80% {
        transform: translateX(-5px);
      }

      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @-webkit-keyframes bounceInRight {
      0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
      }

      60% {
        -webkit-transform: translateX(-20px);
      }

      80% {
        -webkit-transform: translateX(5px);
      }

      100% {
        opacity: 1;
        -webkit-transform: translateX(0);
      }
    }

    @-moz-keyframes bounceInRight {
      0% {
        opacity: 0;
        -moz-transform: translateX(2000px);
      }

      60% {
        -moz-transform: translateX(-20px);
      }

      80% {
        -moz-transform: translateX(5px);
      }

      100% {
        opacity: 1;
        -moz-transform: translateX(0);
      }
    }

    @-o-keyframes bounceInRight {
      0% {
        opacity: 0;
        -o-transform: translateX(2000px);
      }

      60% {
        -o-transform: translateX(-20px);
      }

      80% {
        -o-transform: translateX(5px);
      }

      100% {
        opacity: 1;
        -o-transform: translateX(0);
      }
    }

    @keyframes bounceInRight {
      0% {
        opacity: 0;
        transform: translateX(2000px);
      }

      60% {
        transform: translateX(-20px);
      }

      80% {
        transform: translateX(5px);
      }

      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }






  }

  .ant-modal {
    // max-width: 830px;
    width: 100% !important;
    top: 30px;
    padding-bottom: 30px !important;

    @media screen and (max-width: 850px) {
      width: 95% !important;
    }
  }

  .ant-modal-body {
    padding: 0;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    background: #ffffff;
  }

  .ant-modal-content {
    border-radius: 26px;
    border: 0;
  }

  .ant-modal-footer {
    text-align: center;
    border: none;
    padding: 0;
    padding-bottom: 32px;
    margin: 8px auto 0px auto;
    -webkit-animation-name: bounceIn;
    -moz-animation-name: bounceIn;
    -o-animation-name: bounceIn;
    animation-name: bounceIn;
    animation-duration: 1s;
    animation-delay: 7s;
    animation-fill-mode: both;
    animation-direction: alternate;
  }

  .ant-btn-primary {
    color: white;
    background-color: #7b35df;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #7b35df;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 7px !important;
    margin: 0px auto;
    padding: 10px 15px !important;
    height: auto !important;
    width: auto;
    min-width: 150px;
    font-size: 20px !important;
    text-transform: uppercase;
  }

  .ant-btn-action {
    display: none !important;
  }

}