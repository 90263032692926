@import '../../../assets/style/_variables.less';

.industry-type {
  width: 100%;
  margin: 0px auto;

  .industry-type-section {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: 40px;
    margin-bottom: @layout-footer-height + 94px;

    @media screen and (max-width: 1100px) {
      width: 95%;
    }

    @media screen and (max-width: 600px) {
      margin-bottom: @layout-footer-height + 59px;
    }

    @media screen and (max-width: 600px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    .signIn {
      margin-top: 24px;
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: normal;
      font-family: @font-family;
      max-width: 800px;
      width: 98%;
      text-align: center;
      margin: 48px auto;
      -webkit-animation-name: bounceInRight;
      -moz-animation-name: bounceInRight;
      -o-animation-name: bounceInRight;
      animation-name: bounceInRight;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;

      @media screen and (max-width: 720px) {
        font-size: 30px;
      }

      @media screen and (max-width: 500px) {
        font-size: 24px;
        margin: 48px auto;
      }
    }

    .button-landing {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;
      right: 0;
      margin-bottom: @layout-footer-height;

      @media screen and (max-width: 600px) {
        padding-bottom: 7px;
        padding-top: 7px;
      }

      .signIn {
        -webkit-animation-name: fadeInUp;
        -moz-animation-name: fadeInUp;
        -o-animation-name: fadeInUp;
        animation-name: fadeInUp;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-direction: alternate;
        margin-top: 0;
      }

      .buttonMakeAcc {
        -webkit-animation-name: fadeInUp;
        -moz-animation-name: fadeInUp;
        -o-animation-name: fadeInUp;
        animation-name: fadeInUp;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }
    }

    .industry-type-holder {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      grid-auto-rows: minmax(100px, auto);
      margin-bottom: 50px;
      width: 100%;
      max-width: 1100px;

      @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 400px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .company-holder {
      width: 100%;
      max-width: 250px;
      margin: 0px auto;
      cursor: pointer;
      -webkit-animation-name: bounceIn;
      -moz-animation-name: bounceIn;
      -o-animation-name: bounceIn;
      animation-name: bounceIn;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;

      .industry-backg-holder {
        width: 100%;
        max-width: 250px;
        background: #efe6fc;
        border-radius: 15px;
        border: 1px solid #7b35df;
        display: flex;
        justify-content: center;
        padding-top: 35px;
        padding-bottom: 25px;
        height: 190px;
        max-height: 190px;

        img {
          width: auto;
          max-width: 100px;
          display: block;
          margin: 0px auto;
          height: initial;
          align-self: center;
          max-height: 100%;
        }
      }

      h2 {
        font-size: 22px;
        font-weight: bold;
        margin: 24px auto;
        height: 45px;
        max-height: 45px;
        justify-content: center;
        display: flex;
        align-items: center;
      }

      .selected {
        background: @primary-color;
        width: 100%;
        max-width: 250px;
        border-radius: 15px;
        border: 1px solid #7b35df;
        display: flex;
        justify-content: center;
        padding-top: 35px;
        padding-bottom: 25px;
        margin: 0px auto;
        height: 190px;
        max-height: 190px;

        img {
          width: auto;
          max-width: 100px;
          display: block;
          margin: 0px auto;
          height: initial;
          align-self: center;
          max-height: 100%;
        }
      }
    }
  }


  /*ANIMATIONS*/
  @-webkit-keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(.3);
    }

    50% {
      -webkit-transform: scale(1.05);
    }

    70% {
      -webkit-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }

  @-moz-keyframes bounceIn {
    0% {
      opacity: 0;
      -moz-transform: scale(.3);
    }

    50% {
      -moz-transform: scale(1.05);
    }

    70% {
      -moz-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -moz-transform: scale(1);
    }
  }

  @-o-keyframes bounceIn {
    0% {
      opacity: 0;
      -o-transform: scale(.3);
    }

    50% {
      -o-transform: scale(1.05);
    }

    70% {
      -o-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -o-transform: scale(1);
    }
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(.3);
    }

    50% {
      transform: scale(1.05);
    }

    70% {
      transform: scale(.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounceInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
    }

    60% {
      -webkit-transform: translateX(-20px);
    }

    80% {
      -webkit-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInRight {
    0% {
      opacity: 0;
      -moz-transform: translateX(2000px);
    }

    60% {
      -moz-transform: translateX(-20px);
    }

    80% {
      -moz-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInRight {
    0% {
      opacity: 0;
      -o-transform: translateX(2000px);
    }

    60% {
      -o-transform: translateX(-20px);
    }

    80% {
      -o-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInRight {
    0% {
      opacity: 0;
      transform: translateX(2000px);
    }

    60% {
      transform: translateX(-20px);
    }

    80% {
      transform: translateX(5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }

  @-moz-keyframes fadeInUp {
    0% {
      opacity: 0;
      -moz-transform: translateY(20px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateY(0);
    }
  }

  @-o-keyframes fadeInUp {
    0% {
      opacity: 0;
      -o-transform: translateY(20px);
    }

    100% {
      opacity: 1;
      -o-transform: translateY(0);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

}