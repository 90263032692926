@import '../../../../assets/style/_variables.less';

.game-statistics {
    font-weight: bold;
    text-transform: uppercase;

    .stats {
        display: flex;
        width: 90%;
        flex-wrap: wrap;
        margin: 0px auto;
    }
}