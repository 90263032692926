.mentor-help {
    font-style: normal;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 26px !important;
    color: #000000;
    margin: 48px auto;
    text-align: center;
    padding-left: 48px;
    padding-right: 48px;
}