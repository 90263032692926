@import '../../../assets/style/_variables.less';

body::-webkit-scrollbar {
  width: 0.5px;
}

.button-landing {
  left: 0;
}

.modal-content-mentor-s {
  .meet-mentor-section {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;

    .button-mentor-holder {
      display: flex;
      justify-content: space-between;
      max-width: 830px;
      width: 100%;

      @media screen and (max-width: 800px) {
        display: block;
      }
    }

    .mentor-text {
      color: black;
      width: 100%;
      border-radius: 24px;
      padding-bottom: 24px;

      h3 {
        background-color: @primary-color;
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 22px;
        text-align: left;
        color: #ffffff;
        margin: 0px 0px 32px 0px;
        padding: 32px;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;

        @media screen and (max-width: 800px) {
          font-size: 18px;
          padding: 16px;
        }
      }
    }

    .text-speach {
      width: 100%;
      padding: 0px 32px 0px;

      @media screen and (max-width: 800px) {
        padding: 0px 16px 0px;
      }


      .mentor-talk {
        display: inline-grid;
        grid-template-columns: auto 1fr;
        grid-gap: 5px;
        font-family: @font-family-secondary;
        font-size: 18px;
        width: 100%;

        @media screen and (max-width: 600px) {
          font-size: 16px;
        }

        .avatars-backg-holder {
          width: 82px;
          height: 82px;
          display: grid;
          padding-top: 22px;
          border-radius: 20px;
          margin: 0px auto;
          margin-right: 19px;
          grid-column: 1;

          @media screen and (max-width: 500px) {
            width: 60px;
            height: 60px;
            padding-top: 14px;
            border-radius: 14px;
            margin: 0px auto;
            margin-right: 8px;
          }

          img {
            width: 100%;
            max-width: 50px;
            top: 1.5px;
            position: relative;

            @media screen and (max-width: 500px) {
              max-width: 40px;
              top: 0;
            }
          }
        }

        .hi-msg {
          grid-column: 1 / 2;
          text-align: left;
          background-color: @primary-color;
          color: #ffffff;
          padding: 16px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-right-radius: 26px;
          max-width: 170px;
          align-self: baseline;
          display: grid;
          align-items: center;
          height: 100%;

          @media screen and (max-width: 800px) {
            padding: 14px;
            margin-bottom: 5px;
          }

          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
        }

        .expl-msg {
          grid-column: 2;
          text-align: left;
          background-color: @primary-color;
          color: #ffffff;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-right-radius: 26px;
          max-width: 550px;
          line-height: 21px;
          margin-top: 10px;

          @media screen and (max-width: 600px) {
            padding: 16px;
            font-size: 14px;
          }
        }
      }

      .user-talk {
        display: inline-grid;
        grid-template-columns: 1fr auto;
        grid-gap: 5px;
        font-family: @font-family-secondary;
        font-size: 18px;
        line-height: 21px;
        width: 100%;

        @media screen and (max-width: 600px) {
          font-size: 16px;
        }

        .avatars-backg-holder {
          width: 82px;
          height: 82px;
          display: grid;
          padding-top: 22px;
          border-radius: 20px;
          margin: 0px auto;
          margin-left: 19px;
          grid-column: 2;
          grid-row: 1;
          max-width: 82px;

          @media screen and (max-width: 500px) {
            width: 60px;
            height: 60px;
            padding-top: 12px;
            border-radius: 14px;
            margin: 0px auto;
            margin-right: 8px;
          }

          img {
            width: 100%;
            max-width: 50px;

            @media screen and (max-width: 500px) {
              max-width: 40px;
            }
          }
        }

        .hi-msg-user {
          grid-column: 1;
          grid-row: 1;
          justify-self: end;
          text-align: left;
          background-color: #EFE6FC;
          color: #000000;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-left-radius: 26px;
          max-width: 550px;

          @media screen and (max-width: 600px) {
            padding: 16px;
            font-size: 14px;
          }
        }
      }
    }

    .ant-btn-primary {
      color: white;
      background-color: #7b35df;
      font-weight: bold;
      cursor: pointer;
      border: 1px solid #7b35df;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      border-radius: 7px !important;
      margin: 0px auto;
      padding: 10px 15px !important;
      height: auto !important;
      width: auto;
      min-width: 150px;
      font-size: 20px !important;
      text-transform: uppercase;
    }

    .ant-btn-action {
      display: none !important;
    }

  }

  .ant-modal {
    max-width: 830px;
    width: 100% !important;
    top: 30px;
    padding-bottom: 30px !important;

    @media screen and (max-width: 850px) {
      width: 95% !important;
    }
  }

  .ant-modal-body {
    padding: 0;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    background: #ffffff;
  }

  .ant-modal-content {
    border-radius: 26px;
    border: 0;
  }

  .ant-modal-footer {
    text-align: center;
    border: none;
    padding: 0;
    padding-bottom: 32px;
    margin: 8px auto 0px auto;
  }

}