@import '../../../../assets//style/_variables.less';

.counter-holder {
    background: @primary-color;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(30px, auto);
    border-radius: 12px;
    max-width: 180px;
    margin: 0px auto;
    position: relative;
    top: -27px;
    align-items: center;
    height: 54px;
    width: 206px;
    .minus-holder {
        color: white;
        font-size: 35px;
        font-weight: bold;
    }
    .plus-holder {
        color: white;
        font-size: 35px;
        font-weight: bold;
    }
    input {
        width: 67px;
        height: 38px;
        text-align: center;
        background: #EFE6FC;
        border: 1px solid #5825A1;
        border-radius: 4px;
        cursor: text;
        font-weight: bold;
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  -moz-appearance: textfield;
  margin: 0; 
}