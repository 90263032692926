@import '../../../../assets/style/_variables.less';

.list-all-players {
    width: 100%;

    .players-list {
        display: flex;
        background: #EFE6FC;
        border: 1px solid #7b35df;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 8px;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 5px;

        .signUp {
            font-family: @font-family;
            color: @primary-color;
            background-color: none !important;
            background: none !important;
            font-weight: bold;
            cursor: pointer;
            border: none !important;
            text-shadow: none !important;
            box-shadow: none !important;
            border-radius: 7px;
            margin: 0;
            padding: 0;
            height: auto;
            width: auto;
            min-width: auto;
            font-size: 18px;
            text-transform: uppercase;
            margin-right: 10px;
        }

        .number-list {
            font-weight: bold;
            margin-right: 10px;
            font-size: 15px;
        }

        p {
            margin: 0 !important;
            float: left;
            font-size: 15px;
            margin-block-start: 0 !important;
            margin-block-end: 0 !important;
            max-height: 45px;
            align-self: center;
            display: flex;
        }

        .right-links {
            float: right;
            clear: letf;
        }
    }


}