@import '../../../assets/style/_variables.less';

.certificate{
  overflow: visible;
  padding: 80px 100px;
  background-color: #EFE6FC;
  vertical-align: top;
  .content{
    font-family: Ubuntu monospace;
    border-radius: 10px;
    box-shadow: #5d5d5d;
    padding: 40px 0 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    font-weight: 200;
    background-color: #ffffff;
    .content-logo{
      img{
        width: 20%;
      }
      margin-bottom: 40px;
    }
    .content-name{

      .certificate-title{
          font-size: 72px;
          color: @primary-color;
          margin-bottom: 0;
      }
      .name-title{
          font-size:28px;
          margin-bottom: 0;
      }
      .name{
          font-size: 72px;
          text-transform: uppercase;
          color: @primary-color;
          letter-spacing: 5px;
          margin-bottom: 0;
          padding: 0 50px;
      }
    }
    .content-footer{
      .footer-text{
          font-size:28px;
          padding-bottom: 80px;
      }
    }
    .footer-text{
        .footer-content{
          background-color: @primary-color;
          font-size: 16px;
          text-transform: uppercase;
          color: #ffffff;
        }
    }
    .footer-images{
      div{
        display: flex;
        justify-content: space-around;
      }
    }
  }
}