@import '../../../assets/style/_variables.less';

.lists {
  line-height: 26px;

  li {
    margin-top: 15px;
    line-height: 26px;
    list-style-type: disc;

    @media screen and (max-width: 600px) {
      line-height: 18px;
      margin-top: 7px;
    }
  }

  p {
    margin-bottom: 10px;
  }
}

.legal-aspects {
  width: 100%;
  margin: 0px auto;
  display: grid;
  align-content: center;

  .legal-aspects-section {
    width: 95%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: @layout-footer-height * 2 + 94px;

    @media screen and (max-width: 800px) {
      margin-bottom: @layout-footer-height * 2 + 59px;
    }

    @media screen and (max-width: 800px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    .legal-text {
      background-color: @primary-color;
      color: white;
      width: 100%;
      max-width: 592px;
      border: 1px solid #eaeaea;
      border-radius: 12px;

      h3 {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        margin: 64px 0px 32px 0px;

        @media screen and (max-width: 800px) {
          margin: 32px 0px 16px 0px;
          font-size: 22px;
        }
      }

      p {
        font-family: @font-family-secondary;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        margin: 0px 40px 64px 40px;

        @media screen and (max-width: 800px) {
          margin: 0px 20px 32px 20px;
          font-size: 17px;
        }
      }
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: normal;
      font-family: @font-family;
      max-width: 1200px;
      text-align: center;
      margin: 48px auto;
      -webkit-animation-name: bounceIn;
      -moz-animation-name: bounceIn;
      -o-animation-name: bounceIn;
      animation-name: bounceIn;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;

      @media screen and (max-width: 800px) {
        font-size: 30px;
        margin: 56px auto;
      }

      @media screen and (max-width: 500px) {
        font-size: 22px;
        margin: 24px auto;
      }
    }

    .fadeLeft {
      -webkit-animation-name: bounceInLeft;
      -moz-animation-name: bounceInLeft;
      -o-animation-name: bounceInLeft;
      animation-name: bounceInLeft;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;
    }

    .fadeRight {
      -webkit-animation-name: bounceInRight;
      -moz-animation-name: bounceInRight;
      -o-animation-name: bounceInRight;
      animation-name: bounceInRight;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;
    }

    .legal-text:hover {
      background-color: #57d22c !important;
      border-color: #57d22c;
      color: white !important;
    }

    .legal-text-selected {
      background-color: #57d22c !important;
      color: white;
      width: 100%;
      max-width: 592px;
      border: 1px solid #eaeaea;
      border-radius: 12px;

      h3 {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        margin: 64px 0px 32px 0px;

        @media screen and (max-width: 800px) {
          margin: 32px 0px 16px 0px;
          font-size: 22px;
        }
      }

      p {
        font-family: @font-family-secondary;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        margin: 0px 40px 64px 40px;

        @media screen and (max-width: 800px) {
          margin: 0px 20px 32px 20px;
          font-size: 17px;
        }
      }
    }

    .button-landing {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;
      right: 0;
      margin-bottom: @layout-footer-height;

      @media screen and (max-width: 800px) {
        padding-bottom: 7px;
        padding-top: 7px;
      }
    }

    .button-legal-holder {
      animation-duration: 2s;
      animation-fill-mode: both;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 800px) {
        padding: 10px 10px;
        display: block;
      }

      .legal-text:first-of-type {
        margin-right: 17px;
      }

      .legal-text-selected:first-of-type {
        margin-right: 17px;
      }

      div {
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }



  @-webkit-keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(.3);
    }

    50% {
      -webkit-transform: scale(1.05);
    }

    70% {
      -webkit-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }

  @-moz-keyframes bounceIn {
    0% {
      opacity: 0;
      -moz-transform: scale(.3);
    }

    50% {
      -moz-transform: scale(1.05);
    }

    70% {
      -moz-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -moz-transform: scale(1);
    }
  }

  @-o-keyframes bounceIn {
    0% {
      opacity: 0;
      -o-transform: scale(.3);
    }

    50% {
      -o-transform: scale(1.05);
    }

    70% {
      -o-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -o-transform: scale(1);
    }
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(.3);
    }

    50% {
      transform: scale(1.05);
    }

    70% {
      transform: scale(.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }


  @-webkit-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
    }

    60% {
      -webkit-transform: translateX(20px);
    }

    80% {
      -webkit-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -moz-transform: translateX(-2000px);
    }

    60% {
      -moz-transform: translateX(20px);
    }

    80% {
      -moz-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -o-transform: translateX(-2000px);
    }

    60% {
      opacity: 1;
      -o-transform: translateX(20px);
    }

    80% {
      -o-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      transform: translateX(-2000px);
    }

    60% {
      transform: translateX(20px);
    }

    80% {
      transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes bounceInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
    }

    60% {
      -webkit-transform: translateX(-20px);
    }

    80% {
      -webkit-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInRight {
    0% {
      opacity: 0;
      -moz-transform: translateX(2000px);
    }

    60% {
      -moz-transform: translateX(-20px);
    }

    80% {
      -moz-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInRight {
    0% {
      opacity: 0;
      -o-transform: translateX(2000px);
    }

    60% {
      -o-transform: translateX(-20px);
    }

    80% {
      -o-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInRight {
    0% {
      opacity: 0;
      transform: translateX(2000px);
    }

    60% {
      transform: translateX(-20px);
    }

    80% {
      transform: translateX(5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }



}