@import '../../assets/style/_variables.less';

.ant-btn:hover,
.ant-btn:focus {
  color: #7b35df !important;
  background: none !important;
  border-color: none !important;
}

.buttonMakeAcc {
  font-family: @font-family;
  color: white;
  background-color: #7b35df;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid #7b35df;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  border-radius: 7px;
  margin: 5px;
  padding: 10px 15px;
  height: auto;
  width: auto;
  min-width: 150px;
  font-size: 18px;
  text-transform: uppercase;

  &:hover {
    background: white !important;
    color: #7b35df !important;
    border: 1px solid #7b35df;
  }

  &:disabled {
    background: none !important;
    color: #d9d9d9 !important;
  }

  &:focus {
    background: white !important;
    color: #7b35df !important;
    border: 1px solid #7b35df;
  }
}

.signIn {
  font-family: @font-family;
  color: #7b35df;
  background-color: white;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid #7b35df;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  border-radius: 7px;
  margin: 5px;
  padding: 10px 15px;
  height: auto;
  width: auto;
  min-width: 150px;
  font-size: 18px;
  text-transform: uppercase;

  &:hover {
    background: #7b35df !important;
    color: white !important;
    border: 1px solid #7b35df;
  }

  &:focus {
    border: 1px solid #7b35df !important;
    background: white !important;
    box-shadow: none !important;
    outline: 0 !important;
    color: #7b35df !important;
  }

  &::selection {
    border: 1px solid #7b35df;
    box-shadow: none !important;
    outline: 0 !important;
    color: #7b35df !important;
  }
}

.signUp {
  font-family: @font-family;
  color: white;
  background-color: #7b35df;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid #7b35df;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  border-radius: 7px;
  margin: 5px;
  padding: 10px 15px;
  height: auto;
  width: auto;
  min-width: 150px;
  font-size: 20px;
  text-transform: uppercase;

  &:hover {
    background: white !important;
    color: #7b35df !important;
    border: 1px solid #7b35df;
  }

  &:focus {
    border: 1px solid #7b35df;
    color: #7b35df;
  }
}