@import '../../../assets/style/_variables.less';

.select-accelerator {
  width: 100%;
  margin: 0px auto;
  display: grid;
  align-content: center;

  .toolText {
    position: relative;
    z-index: 11111;
    .tooltipShow {
      display: none;
      position: absolute;
      top: 50px;
      right: 15%;
      color: black;
      background: #ececec;
      padding: 5px;
      border-radius: 5px;
      font-size: 15px;
      width: 90%;
      max-width: 350px;
      box-shadow: 1px 2px 6px -2px #5d5d5d;
      line-height: 20px;
        @media screen and (max-width: 500px) {
          right: 20px
        }
    }
  }
  .hoverme {
    position: relative;
    border-bottom: 1px solid black;
    cursor: pointer;
  }
  .hoverme:hover +.tooltipShow {
      display: block;
  }

  .select-accelerator-section {
    width: 95%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-top: @layout-header-height;
    margin-bottom: @layout-footer-height * 2 + 94px;

    @media screen and (max-width: 800px) {
      margin-top: @layout-footer-height;
      margin-bottom: @layout-footer-height * 2 + 59px;
    }

    @media screen and (max-width: 800px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    .accelerator-text {
      background-color: @primary-color;
      color: white;
      width: 100%;
      max-width: 592px;
      border: 1px solid #eaeaea;
      border-radius: 12px;

      h3 {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        margin: 64px auto 32px auto;
        width: 90%;
        text-align: center;

        @media screen and (max-width: 800px) {
          margin: 26px auto;
          font-size: 22px;
        }
      }

      p {
        font-family: @font-family-secondary;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        margin: 0px 40px 64px 40px;

        @media screen and (max-width: 800px) {
          margin: 0px 20px 32px 20px;
          font-size: 17px;
        }
      }
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: normal;
      font-family: @font-family;
      max-width: 1200px;
      text-align: center;
      margin: 0px 0px 48px 0px;
      -webkit-animation-name: bounceIn;
      -moz-animation-name: bounceIn;
      -o-animation-name: bounceIn;
      animation-name: bounceIn;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;

      @media screen and (max-width: 800px) {
        font-size: 30px;
        margin: 0px 0px 20px 0px;
      }

      @media screen and (max-width: 500px) {
        font-size: 22px;
      }
    }

    .accelerator-text:hover {
      background-color: #57d22c !important;
      border-color: #57d22c;
      color: white !important;
    }

    .accelerator-text-selected {
      background-color: #57d22c !important;
      color: white;
      width: 100%;
      max-width: 592px;
      border: 1px solid #eaeaea;
      border-radius: 12px;

      h3 {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        margin: 64px auto 32px auto;
        width: 90%;
        text-align: center;

        @media screen and (max-width: 800px) {
          margin: 26px auto;
          font-size: 22px;
        }
      }

      p {
        font-family: @font-family-secondary;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        margin: 0px 40px 64px 40px;

        @media screen and (max-width: 800px) {
          margin: 0px 20px 32px 20px;
          font-size: 17px;
        }
      }
    }

    .button-accelerator-holder {
      -webkit-animation-name: fadeInDown;
      -moz-animation-name: fadeInDown;
      -o-animation-name: fadeInDown;
      animation-name: fadeInDown;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 800px) {
        padding: 10px 10px;
        display: block;
      }

      .accelerator-text:first-of-type {
        margin-right: 17px;

        @media screen and (max-width: 800px) {
          margin-bottom: 17px;
        }
      }

      .accelerator-text-selected:first-of-type {
        margin-right: 17px;

        @media screen and (max-width: 600px) {
          margin-bottom: 17px;
        }
      }

      div {
        cursor: pointer;
      }
    }
  }

  .button-landing {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    padding-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid #d4d4d4;
    right: 0;
    margin-bottom: @layout-footer-height;

    @media screen and (max-width: 800px) {
      padding-bottom: 7px;
      padding-top: 7px;
    }

    .signIn {
      -webkit-animation-name: fadeInUp;
      -moz-animation-name: fadeInUp;
      -o-animation-name: fadeInUp;
      animation-name: fadeInUp;
      animation-duration: 1s;
      animation-delay: 2s;
      animation-fill-mode: both;
      animation-direction: alternate;
    }

    .buttonMakeAcc {
      -webkit-animation-name: fadeInUp;
      -moz-animation-name: fadeInUp;
      -o-animation-name: fadeInUp;
      animation-name: fadeInUp;
      animation-duration: 1s;
      animation-delay: 2s;
      animation-fill-mode: both;
      animation-direction: alternate;
    }
  }


  /*ANIMATIONS*/

  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }

  @-moz-keyframes fadeInUp {
    0% {
      opacity: 0;
      -moz-transform: translateY(20px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateY(0);
    }
  }

  @-o-keyframes fadeInUp {
    0% {
      opacity: 0;
      -o-transform: translateY(20px);
    }

    100% {
      opacity: 1;
      -o-transform: translateY(0);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }

  @-moz-keyframes fadeInDown {
    0% {
      opacity: 0;
      -moz-transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateY(0);
    }
  }

  @-o-keyframes fadeInDown {
    0% {
      opacity: 0;
      -o-transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      -o-transform: translateY(0);
    }
  }

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @-webkit-keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(.3);
    }

    50% {
      -webkit-transform: scale(1.05);
    }

    70% {
      -webkit-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }

  @-moz-keyframes bounceIn {
    0% {
      opacity: 0;
      -moz-transform: scale(.3);
    }

    50% {
      -moz-transform: scale(1.05);
    }

    70% {
      -moz-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -moz-transform: scale(1);
    }
  }

  @-o-keyframes bounceIn {
    0% {
      opacity: 0;
      -o-transform: scale(.3);
    }

    50% {
      -o-transform: scale(1.05);
    }

    70% {
      -o-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -o-transform: scale(1);
    }
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(.3);
    }

    50% {
      transform: scale(1.05);
    }

    70% {
      transform: scale(.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}