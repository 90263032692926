@import '../../../assets/style/_variables.less';

body::-webkit-scrollbar {
  width: 0.5px;
}

.modal-content-loan {
  .button-landing {
    margin: 48px auto;
    display: block;
    text-align: center;

    @media screen and (max-width: 800px) {
      margin: 32px auto;
    }

    @media screen and (max-width: 400px) {
      margin: 24px auto;
    }

    .signIn {
      min-width: 220px;

      @media screen and (max-width: 400px) {
        font-size: 16px;
        min-width: 180px;
      }
    }

    .buttonMakeAcc {
      min-width: 220px;

      @media screen and (max-width: 400px) {
        font-size: 16px;
        min-width: 180px;
      }
    }
  }

  .contentImage {
    background: #EFE6FC;
    padding: 10px;
    padding-top: 23px;
    padding-bottom: 19px;

    img {
      margin: 0px auto;
      display: block;
      max-width: 150px;

      @media screen and (max-width: 800px) {
        max-width: 100px;
      }

      @media screen and (max-width: 400px) {
        max-width: 70px;
      }
    }
  }

  .contentHeader {
    h3 {
      font-family: @font-family-secondary;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 32px;
      margin: 48px auto;
      text-align: center;
      color: red;
      padding: 10px;

      @media screen and (max-width: 800px) {
        font-size: 26px;
        line-height: 26px;
        margin: 32px auto;
      }

      @media screen and (max-width: 400px) {
        font-size: 18px;
        line-height: 18px;
        margin: 24px auto;
      }
    }
  }

  .contentDescription {
    font-family: @font-family-secondary;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    max-width: 355px;
    width: 100%;
    margin: 0px auto;
    padding: 10px;

    @media screen and (max-width: 800px) {
      font-size: 16px;
      line-height: 18px;
    }

    @media screen and (max-width: 400px) {
      padding-top: 0;
    }
  }

  .ant-btn-primary {
    color: white;
    background-color: #7b35df;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #7b35df;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 7px !important;
    margin: 5px auto;
    padding: 10px 15px !important;
    height: auto !important;
    width: auto;
    min-width: 150px;
    font-size: 20px !important;
    text-transform: uppercase;
  }

  .ant-btn-action {
    display: none !important;
  }

  .ant-modal-footer {
    display: none;
  }

  .industry-backg-holder {
    width: 100%;
    max-width: 600px;
    height: 200px;
    background: #efe6fc;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #7b35df;
    display: grid;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    grid-template-columns: auto;
    align-items: flex-start;

    img {
      max-width: 200px;
      width: 100%;
      height: auto;
    }
  }

  .ant-modal {
    max-width: 570px;
    width: 96% !important;
    top: 30px;
    padding-bottom: 30px !important;
  }

  .ant-modal-body {
    padding: 0;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    background: #ffffff;
  }

  .ant-modal-content {
    border-radius: 26px;
    border: 1px solid @primary-color;
  }


}