@import '../../../assets/style/_variables.less';

.board-info {
  width: 100%;
  margin: 0px auto;
  display: grid;
  align-content: center;
  background-image: url('../../../assets/images/right-bubble.png'), url('../../../assets/images/left-bubble.png');
  background-position: right bottom, left top;
  background-repeat: no-repeat, no-repeat;
  background-size: contain, contain;
  background-color: white;

  .board-info-section {
    width: 95%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: @layout-footer-height * 2 + 94px;

    @media screen and (max-width: 800px) {
      margin-top: @layout-footer-height;
      margin-bottom: @layout-footer-height * 2 + 59px;
    }

    @media screen and (max-width: 800px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    .board-info-text {
      color: black;
      width: 100%;
      padding-bottom: 30px;
    }

    .text-info {
      width: 100%;
      padding: 0px 32px 0px;

      .board-info-talk-mentor {
        display: inline-grid;
        grid-template-columns: auto 1fr;
        grid-gap: 5px;
        font-family: @font-family-secondary;
        font-size: 18px;
        width: 100%;

        @media screen and (max-width: 600px) {
          font-size: 16px;
          display: block;
          text-align: center;
        }

        .avatars-backg-holder {
          width: 82px;
          height: 82px;
          padding-top: 22px;
          border-radius: 20px;
          margin: 0px auto;
          margin-right: 19px;
          grid-column: 1;
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-delay: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;

          @media screen and (max-width: 600px) {
            margin: 0px auto;
            margin-bottom: 20px;
          }

          img {
            width: 100%;
            max-width: 50px;
          }
        }

        .hi-msg {
          grid-column: 1 / 2;
          text-align: left;
          background-color: @primary-color;
          color: #ffffff;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-right-radius: 26px;
          max-width: 660px;
          align-self: baseline;
          display: grid;
          align-items: center;
          height: 100%;
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-delay: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;

          @media screen and (max-width: 600px) {
            padding: 16px;
            border-radius: 26px;
          }
        }

        .expl-msg {
          grid-column: 2;
          text-align: left;
          background-color: @primary-color;
          color: #ffffff;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-right-radius: 26px;
          max-width: fit-content;
          line-height: 26px;
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-delay: 4.5s;
          animation-fill-mode: both;
          animation-direction: alternate;

          @media screen and (max-width: 600px) {
            padding: 16px;
          }
        }
      }

      .board-info-talk-user {
        display: inline-grid;
        grid-template-columns: 1fr auto;
        grid-gap: 5px;
        font-family: @font-family-secondary;
        font-size: 18px;
        line-height: 21px;
        width: 100%;

        @media screen and (max-width: 600px) {
          font-size: 16px;
        }

        .avatars-backg-holder {
          width: 82px;
          height: 82px;
          padding-top: 22px;
          border-radius: 20px;
          margin: 0px auto;
          margin-left: 19px;
          grid-column: 2;
          grid-row: 1;
          max-width: 82px;
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-delay: 7.5s;
          animation-fill-mode: both;
          animation-direction: alternate;

          img {
            width: 100%;
            max-width: 50px;
          }
        }

        .hi-msg-user {
          grid-column: 1;
          grid-row: 1;
          justify-self: end;
          text-align: left;
          background-color: #EFE6FC;
          color: #000000;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-left-radius: 26px;
          max-width: fit-content;
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-delay: 7.5s;
          animation-fill-mode: both;
          animation-direction: alternate;

          @media screen and (max-width: 600px) {
            padding: 16px;
          }
        }
      }

      .infoTalkMentor2 {
        display: inline-grid;
        grid-template-columns: auto 1fr;
        grid-gap: 5px;
        font-family: @font-family-secondary;
        font-size: 18px;
        width: 100%;
        margin-top: 10px;

        @media screen and (max-width: 600px) {
          font-size: 16px;
          display: block;
          text-align: center;
        }

        .avatars-backg-holder {
          width: 82px;
          height: 82px;
          padding-top: 22px;
          border-radius: 20px;
          margin: 0px auto;
          margin-right: 19px;
          grid-column: 1;
          -webkit-animation-name: bounceIn;
          -moz-animation-name: bounceIn;
          -o-animation-name: bounceIn;
          animation-name: bounceIn;
          animation-duration: 1s;
          animation-delay: 9.5s;
          animation-fill-mode: both;
          animation-direction: alternate;

          @media screen and (max-width: 600px) {
            margin: 0px auto;
            margin-bottom: 20px;
          }

          img {
            width: 100%;
            max-width: 50px;
          }
        }

        .hi-msg {
          grid-column: 1 / 2;
          text-align: left;
          background-color: @primary-color;
          color: #ffffff;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-right-radius: 26px;
          max-width: 660px;
          align-self: baseline;
          display: grid;
          align-items: center;
          height: 100%;
          -webkit-animation-name: bounceIn;
          -moz-animation-name: bounceIn;
          -o-animation-name: bounceIn;
          animation-name: bounceIn;
          animation-duration: 1s;
          animation-delay: 9.5s;
          animation-fill-mode: both;
          animation-direction: alternate;


          @media screen and (max-width: 600px) {
            padding: 16px;
            border-radius: 26px;
          }
        }

        .expl-msg {
          grid-column: 2;
          text-align: left;
          background-color: @primary-color;
          color: #ffffff;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-right-radius: 26px;
          max-width: fit-content;
          line-height: 26px;

          @media screen and (max-width: 600px) {
            padding: 16px;
          }
        }
      }
    }

    .board-info-text2 {
      color: black;
      width: 100%;
      padding: 38px;
      margin-top: 32px;

      .fadeLeft {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-duration: 1s;
        animation-delay: 5.5s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }

      .fadeRight {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-duration: 1s;
        animation-delay: 5.5s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }

      h3 {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 32px;
        text-align: left;
        color: #000000;
        margin: 6px 0px 32px 0px;

        @media screen and (max-width: 800px) {
          font-size: 22px;
        }
      }

      p {
        text-align: left;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: normal;
      font-family: @font-family;
      max-width: 1200px;
      text-align: center;
      margin: 48px auto;
      width: 100%;
      -webkit-animation-name: bounceInRight;
      -moz-animation-name: bounceInRight;
      -o-animation-name: bounceInRight;
      animation-name: bounceInRight;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;

      @media screen and (max-width: 800px) {
        font-size: 30px;
        margin: 0px 0px 20px 0px;
      }

      @media screen and (max-width: 500px) {
        font-size: 22px;
      }
    }

    .button-landing {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;
      right: 0;
      margin-bottom: @layout-footer-height;

      @media screen and (max-width: 800px) {
        padding-bottom: 7px;
        padding-top: 7px;
      }

      .signIn {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-duration: 1s;
        animation-delay: 9.5s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }

      .buttonMakeAcc {
        -webkit-animation-name: bounceIn;
        -moz-animation-name: bounceIn;
        -o-animation-name: bounceIn;
        animation-name: bounceIn;
        animation-duration: 1s;
        animation-delay: 9.5s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }
    }

    .board-info-holder {
      animation-duration: 2s;
      animation-fill-mode: both;
      display: flex;
      justify-content: space-between;
      max-width: 828px;
      width: 100%;
      flex-flow: column;

      @media screen and (max-width: 800px) {
        padding: 10px 10px;
        display: block;
      }
    }
  }

  /*ANIMATIONS*/

  @-webkit-keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(.3);
    }

    50% {
      -webkit-transform: scale(1.05);
    }

    70% {
      -webkit-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }

  @-moz-keyframes bounceIn {
    0% {
      opacity: 0;
      -moz-transform: scale(.3);
    }

    50% {
      -moz-transform: scale(1.05);
    }

    70% {
      -moz-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -moz-transform: scale(1);
    }
  }

  @-o-keyframes bounceIn {
    0% {
      opacity: 0;
      -o-transform: scale(.3);
    }

    50% {
      -o-transform: scale(1.05);
    }

    70% {
      -o-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -o-transform: scale(1);
    }
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(.3);
    }

    50% {
      transform: scale(1.05);
    }

    70% {
      transform: scale(.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
    }

    60% {
      -webkit-transform: translateX(20px);
    }

    80% {
      -webkit-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -moz-transform: translateX(-2000px);
    }

    60% {
      -moz-transform: translateX(20px);
    }

    80% {
      -moz-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -o-transform: translateX(-2000px);
    }

    60% {
      opacity: 1;
      -o-transform: translateX(20px);
    }

    80% {
      -o-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      transform: translateX(-2000px);
    }

    60% {
      transform: translateX(20px);
    }

    80% {
      transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes bounceInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
    }

    60% {
      -webkit-transform: translateX(-20px);
    }

    80% {
      -webkit-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInRight {
    0% {
      opacity: 0;
      -moz-transform: translateX(2000px);
    }

    60% {
      -moz-transform: translateX(-20px);
    }

    80% {
      -moz-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInRight {
    0% {
      opacity: 0;
      -o-transform: translateX(2000px);
    }

    60% {
      -o-transform: translateX(-20px);
    }

    80% {
      -o-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInRight {
    0% {
      opacity: 0;
      transform: translateX(2000px);
    }

    60% {
      transform: translateX(-20px);
    }

    80% {
      transform: translateX(5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }



}