@import '../style/_variables.less';

.avatars-backg-holder {
  width: 100%;
  max-width: 204px;
  background: #efe6fc;
  margin: 5px;
  border-radius: 26px;
  border: 1px solid #7b35df;
  display: flex;
  justify-content: center;
  padding-top: 27px;
  height: 190px;
  position: relative;

  img {
    max-width: 147px;
    position: absolute;
    bottom: 0;
  }
}

.industry-backg-holder {
  width: 100%;
  max-width: 250px;
  max-height: 200px;
  background: #efe6fc;
  border-radius: 15px;
  border: 1px solid #7b35df;
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;

  .ecofriend {
    max-width: 30px !important;
    width: 100% !important;
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.selected {
  position: relative;

  .ecofriend {
    max-width: 30px !important;
    width: 100% !important;
    position: absolute;
    right: 5px;
    top: 5px;
  }
}