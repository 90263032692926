.content-switch {
    width: 100%;
    margin-bottom: 2%;

    @media screen and (max-width: 500px) {
        width: 80%;
        z-index: 1;
        right: 0;
    }

    @media screen and (max-width: 350px) {
        width: 75%;
    }
}