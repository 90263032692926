@import '../../../assets/style/_variables.less';


.modal-content-survey{
  text-align: center;

  body::-webkit-scrollbar {
    width: 0.5px;
  }

  .button-landing {
    left: 0;
  }
  .ant-form-item-label{
    text-align: center;
  }

  .ant-btn-primary {
    color: white;
    background-color: #7b35df;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #7b35df;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 7px !important;
    margin: 5px auto;
    padding: 10px 15px !important;
    height: auto !important;
    width: auto;
    min-width: 150px;
    font-size: 20px !important;
    text-transform: uppercase;
  }

  .ant-btn-action {
    display: none !important;
  }

  .ant-modal-footer {
    text-align: center;
    border: none;
    padding: 0;
    padding-bottom: 32px;
    margin: 24px auto 0px auto;
  }

  .ant-modal {
    // max-width: 6000px;
    width: 96% !important;
    top: 30px;
    padding-bottom: 30px !important;
    text-align: center;
  }

  .ant-modal-body {
    padding: 0;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    background: #ffffff;
  }

  .ant-modal-content {
    border-radius: 26px;
    border: 1px solid @primary-color;
  }
}