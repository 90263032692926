@import '../../assets/style/_variables.less';


  .button-landing {
    @media  screen and (max-width: 530px) {
    margin-bottom: 45px!important;
      }
  }


.start-logo {
  img {
    width: 90%;
  }
}
body {
  overflow: overlay;

 &::-webkit-scrollbar {
    width: 20px!important;
  }
  
 &::-webkit-scrollbar-track {
    background: #f4e7f8;
    border: 3px solid #7b35df;
    // border-radius: px;
    border-top: 0px;
    border-bottom: 0px;
    z-index: 11111;
  }
  
 &::-webkit-scrollbar-thumb {
    background-image: url('../../assets/images/Scrollbarnew.png');
    background-size: 72%;
    background-repeat: no-repeat;
    background-position: center center;
    max-height: 20px;
    overflow: hidden;
  }
}


.section-logo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 70vh;
  position: relative;
}

.ant-layout {
    background-color: white!important;

  .absoulute-back-left {
    position: absolute;
    left: 0;
    width: 40%;
  }

  .absoulute-back-right {
    position: absolute;
    right: 0;
    width: 40%;
    top: 50%;
  }
}

body {
  background-color: #FAFAFA;
}

.footer-landing {
  background: @primary-color;
  border-top: 1px solid #6724C8;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 111;
  position: fixed;
  bottom: 0;

  .footer-holder {
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .left-corner-footer {
    margin-left: 10px;
    float: left;
    display: flex;
    vertical-align: middle;
    position: relative;

    p {
      align-self: center;
      vertical-align: middle;
      position: relative;
      max-width: 250px;
      margin: 0px;
      font-family: @font-family;
      font-size: 12px;
      line-height: 14px;
      color: white;
    }
  }

  .right-corner-footer {
    display: flex;
    margin-right: 10px;
    // max-width: 200px;
    position: relative;
    text-align: right;
    gap: 20px;

    a {
      color: #fff;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }

    p {
      margin: 0px;
      font-family: @font-family;
      font-size: 12px;
      line-height: 14px;
      color: white;
    }
  }
}

.section {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  position: relative;
  display: grid;
  height: auto;
  min-height: calc(90vh - (30px + 65px)) !important;

  .avatar-holder>.avatars-backg-holder {
    max-width: 158px;
    min-height: 158px;
    height: 100%;
    width: 100%;
    position: relative;

    img {
      max-width: 113px;
      position: absolute;
      bottom: 0;
    }
  }

  .section-one {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 200px;

    @media screen and (max-width: 1000px) {
      display: block;
    }

    .button-landing {
      margin-top: 64px;

      @media screen and (max-width: 425px) {
        max-width: 250px;
        margin: 0px auto;
        margin-top: 64px;

        .buttonMakeAcc {
          width: 100%;
          max-width: 200px;
          min-width: 150px;
          font-size: 20px;
          text-transform: uppercase;
        }

        .signIn {
          width: 100%;
          max-width: 200px;
          min-width: 150px;
          font-size: 20px;
          text-transform: uppercase;
        }
      }
    }

    h2 {
      font-weight: bold;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      font-family: @font-family;
      max-width: 754px;
      width: 100%;
      text-align: center;
      margin: 64px auto;

      @media screen and (max-width: 800px) {
        font-size: 30px;
        line-height: normal;
        margin-top: 32px;
      }

      @media screen and (max-width: 400px) {
        font-size: 20px;
        line-height: normal;
        margin-top: 24px;
        max-width: 90%;
      }
    }

    .avatar-holder {
      display: flex;
      width: 100%;
      max-width: 1200px;
      justify-content: center;
      margin: 0px auto;
      flex-wrap: wrap;
      align-items: center;
    }

    .avatars-backg-holder {
      @media screen and (max-width: 425px) {
        max-width: 98px;
        max-height: 98px;
        min-height: 98px;
        border-radius: 18px;

        img {
          max-width: 72px;
        }
      }

      &:nth-child(1) {
        max-width: 98px;
        max-height: 98px;
        min-height: 98px;

        @media screen and (max-width: 940px) {
          max-width: 68px;
          max-height: 68px;
          min-height: 68px;
          border-radius: 18px;
        }

        @media screen and (max-width: 800px) {
          display: none;
        }

        img {
          max-width: 70px;

          @media screen and (max-width: 940px) {
            max-width: 50px;
          }
        }
      }

      &:nth-child(2) {
        max-width: 118px;
        max-height: 118px;
        min-height: 118px;

        @media screen and (max-width: 940px) {
          max-width: 98px;
          max-height: 98px;
          min-height: 98px;
        }

        @media screen and (max-width: 650px) {
          display: none;
        }

        img {
          max-width: 82px;

          @media screen and (max-width: 940px) {
            max-width: 62px;
          }
        }
      }

      &:nth-child(3) {
        max-width: 138px;
        max-height: 138px;
        min-height: 138px;

        @media screen and (max-width: 940px) {
          max-width: 118px;
          max-height: 118px;
          min-height: 118px;
        }

        @media screen and (max-width: 425px) {
          max-width: 80px;
          max-height: 80px;
          min-height: 80px;
          border-radius: 18px;
        }

        img {
          max-width: 100px;

          @media screen and (max-width: 940px) {
            max-width: 80px;
          }

          @media screen and (max-width: 425px) {
            max-width: 50px;
          }
        }
      }

      &:nth-child(5) {
        max-width: 138px;
        max-height: 138px;
        min-height: 138px;

        @media screen and (max-width: 940px) {
          max-width: 118px;
          max-height: 118px;
          min-height: 118px;
        }

        @media screen and (max-width: 425px) {
          max-width: 80px;
          max-height: 80px;
          min-height: 80px;
          border-radius: 18px;
        }

        img {
          max-width: 100px;

          @media screen and (max-width: 940px) {
            max-width: 80px;
          }

          @media screen and (max-width: 425px) {
            max-width: 50px;
          }
        }
      }

      &:nth-child(6) {
        max-width: 118px;
        max-height: 118px;
        min-height: 118px;

        @media screen and (max-width: 940px) {
          max-width: 98px;
          max-height: 98px;
          min-height: 98px;
        }

        @media screen and (max-width: 650px) {
          display: none;
        }

        img {
          max-width: 82px;

          @media screen and (max-width: 940px) {
            max-width: 62px;
          }
        }
      }

      &:nth-child(7) {
        max-width: 98px;
        max-height: 98px;
        min-height: 98px;

        @media screen and (max-width: 940px) {
          max-width: 68px;
          max-height: 68px;
          min-height: 68px;
          border-radius: 18px;
        }

        @media screen and (max-width: 800px) {
          display: none;
        }

        img {
          max-width: 70px;

          @media screen and (max-width: 940px) {
            max-width: 50px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 485px) {
    .section {
      height: auto;
    }
  }

  .section-two {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin-bottom: 20px;
    margin-top: 64px;
    flex-wrap: wrap-reverse;

    h2 {
      font-family: @font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 64px;

      @media screen and (max-width:1000px) {
        font-size: 38px;
        margin-bottom: 32px;
      }
    }

    .left-part-about {
      text-align: left;
      font-family: @font-family-secondary;
      max-width: 433px;
      width: 98%;
      padding: 10px;

      @media screen and (max-width:1000px) {
        max-width: 333px;
        width: 98%;
      }

      @media screen and (max-width:800px) {
        max-width: 568px;
        width: 95%;
      }
    }

    .right-part-about {
      position: relative;

      img {
        max-width: 568px;
        width: 98%;
        border-bottom-left-radius: 26px;
        border-bottom-right-radius: 26px;
        z-index: 1;
        position: relative;

        @media screen and (max-width:1100px) {
          max-width: 468px;
          width: 90%;
        }

        @media screen and (max-width:800px) {
          max-width: 568px;
          width: 95%;
        }
      }
    }

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;

      @media screen and (max-width:1000px) {
        font-size: 15px;
      }

      @media screen and (max-width:800px) {
        font-size: 18px;
      }
    }
  }

  .section-three {
    width: 98%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin-top: 64px;
    margin-bottom: 20px;
    background: @primary-color;
    border-radius: 74px;
    color: white;
    position: relative;
    z-index: 1;

    @media screen and (max-width:800px) {
      height: auto;
      border-radius: unset;
      width: 100%;
      flex-wrap: wrap-reverse;
    }

    .company-holder {
      width: 100%;
      max-width: 250px;
    }

    .features-images {
      display: flex;
      justify-content: space-around;
      align-content: center;
      width: 100%;
      flex-flow: column;
      max-width: 500px;
      text-align: left;

      @media screen and (max-width: 1000px) {
        padding-bottom: 24px;
        max-width: 420px;
      }

      @media screen and (max-width:800px) {
        max-width: 500px;
        width: 95%;
      }

      .text-features {
        display: flex;

        span {
          margin-right: 10px;
        }
      }
    }

    h2 {
      font-family: @font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 48px;
      margin-top: 32px;
      margin-bottom: 64px;
      color: white;

      @media screen and (max-width: 1000px) {
        font-size: 36px;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 800px) {
        text-align: center;
        padding-top: 32px;
      }
    }

    .left-image-features {
      position: relative;
      max-width: 600px;
      align-self: flex-end;
      padding-top: 40px;

      img {
        width: 100%;
        max-width: 491px;

        @media screen and (max-width: 1000px) {
          max-width: 300px;
        }

        @media screen and (max-width:800px) {
          width: 100%;
          max-width: 500px;
          width: 95%;
        }
      }
    }

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      text-align: left;

      @media screen and (max-width: 1000px) {
        font-size: 18px;
      }

      @media screen and (max-width: 440px) {
        font-size: 16px;
      }
    }
  }


  .section-four {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin-bottom: 15%;
    margin-top: 10%;
    color: black;
    flex-flow: column;

    .company-holder {
      width: 100%;
      max-width: 250px;
    }

    .features-images {
      display: flex;
      justify-content: space-around;
      align-content: center;
      width: 100%;
      flex-flow: column;
      max-width: 500px;
      text-align: left;
    }

    h2 {
      font-family: @font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 64px;
      color: black;
      max-width: 550px;
    }

    .left-image-features {
      position: relative;
      max-width: 600px;
      align-self: flex-end;

      img {
        width: 100%;
        max-width: 491px;
      }
    }

    .ready {
      font-family: @font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      color: @primary-color;
    }
  }

}

.section-five {
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
  position: fixed;
  bottom: 25px;
  text-align: center;
  color: white;
  align-items: center;
  flex-flow: column;
  height: 105px;
  background-color: white;

  @media screen and (max-width: 300px) {
    height: 90px;
  }

  p {
    width: 100%;
    background: #9063D3;
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .image-second {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .image-main {
    z-index: 1;
    max-width: 300px;

    @media screen and (max-width: 500px) {
      max-width: 150px;
    }

    @media screen and (max-width: 300px) {
      max-width: 110px;
    }
  }
}

.CookieConsent {
  display: flex;
  align-items: center;
  width: 80%;
  bottom: 130px;
  background-color: #fff;

  .cookie-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #000;
    width: 100%;

    p.cookie-policy {
      margin: 0;
      text-align: left;

      a {
        color: @primary-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    img {
      margin-right: 50px;
    }

    button {
      border-radius: 8px !important;
    }

    @media (max-width: 700px) {
      flex-wrap: wrap;
      justify-content: center;

      p {
        text-align: center;
      }

      img {
        margin: 0;
      }
    }
  }

  @media (max-width: 700px) {
    justify-content: center !important;
    padding: 0 !important;
  }
}