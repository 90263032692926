@import '../../assets/style/_variables.less';

.header-main {
  width: 100%;
  background-color: white;
  padding: 5px;
  //border-bottom: 1px solid #d4d4d4;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;

  .signIn {
    border: none;
    color: black;
  }

  .header-holder {
    max-width: 1200px;
    margin: 0px auto;
    height: auto;
    position: relative;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    flex-flow: nowrap;
  }

  .left-corner {
    float: left;
    position: relative;
    display: flex;
    vertical-align: middle;
    position: relative;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .game-sound {
    display: flex;
    max-width: 100px;
    justify-content: space-around;
    width: 100%;
    margin-left: 10px;

    @media screen and (max-width: 600px) {
      max-width: 80px;
      margin-left: 0px;
    }

    .onmusic {
      background: none;
      padding: 6px;
      border-radius: 6px;
      border: none;

      img {
        @media screen and (max-width: 600px) {
          max-width: 20px;
        }
      }

      &:hover {
        box-shadow: inset 0 0 10px #c0c0c0;
        border-radius: 6px;
        outline: none;
        cursor: pointer;
      }

      &:focus {
        box-shadow: inset 0 0 10px #c0c0c0;
        border-radius: 6px;
        outline: none;
        cursor: pointer;
      }

      &::selection {
        box-shadow: inset 0 0 10px #c0c0c0;
        border-radius: 6px;
        outline: none;
        cursor: pointer;
      }

    }

    .offmusic {
      background: none;
      padding: 6px;
      border-radius: 6px;
      border: none;

      img {
        @media screen and (max-width: 600px) {
          max-width: 20px;
        }
      }

      &:hover {
        box-shadow: inset 0 0 10px #c0c0c0;
        border-radius: 6px;
        outline: none;
        cursor: pointer;
      }

      &:focus {
        box-shadow: inset 0 0 10px #c0c0c0;
        border-radius: 6px;
        outline: none;
        cursor: pointer;
      }

      &::selection {
        box-shadow: inset 0 0 10px #c0c0c0;
        border-radius: 6px;
        outline: none;
        cursor: pointer;
      }

    }
  }

  .left-corner img {
    align-self: center;
    position: relative;
    vertical-align: middle;
    position: relative;
    max-width: 80px;

    @media screen and (max-width: 450px) and (min-width: 300px) {
      max-width: 70px;
    }

    @media screen and (max-width: 300px) and (min-width: 200px) {
      max-width: 60px;
    }
  }

  .right-corner {
    display: inline-flex;
    width: 100%;
    position: relative;
    text-align: right;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 600px) {
      justify-content: space-between;
    }

    .no-see {
      display: none;
    }

    .budget {
      background: @box-items-bg;
      color: #000000;
      border: 1px solid @primary-color;
      border-radius: 12px;
      padding: 11px;
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 600px) {
        border-radius: 8px;
        padding: 10px;
        font-size: 16px;
        line-height: 15px;
        margin-left: 25px;
      }
      @media screen and (max-width: 500px) {
        margin-left: 0px;
      }

      img {
        max-width: 30px;
        margin-left: 10px;

        @media screen and (max-width: 600px) {
          max-width: 20px;
        }
      }
    }

    .avatars-backg-holder {
      width: 50px;
      height: 50px;
      border-radius: 12px;
    }

    img {
      max-width: 40px;

      @media screen and (max-width: 550px) and (min-width: 200px) {
        max-width: 30px;
      }
    }

    .avatars-backg-holder {
      width: 50px;
      height: 50px;
      border-radius: 12px;

      @media screen and (max-width: 550px) and (min-width: 200px) {
        max-width: 46px;
        height: 46px;
      }
    }
  }

  @media screen and (max-width: 450px) and (min-width: 300px) {
    .signIn {
      margin: 3px;
      padding: 6px 10px;
      font-size: 14px;
      font-weight: bold;
    }

    .signUp {
      margin: 3px;
      padding: 6px 10px;
      font-size: 14px;
      font-weight: bold;
    }

    .right-corner {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 300px) and (min-width: 200px) {
    .signIn {
      margin: 2px;
      padding: 4px 8px;
      font-size: 13px;
      font-weight: bold;
    }

    .signUp {
      margin: 2px;
      padding: 4px 8px;
      font-size: 13px;
      font-weight: bold;
    }

    .right-corner {
      max-width: 100%;
    }
  }

  .logged-icon {
    max-width: 58px;
    width: 100%;

    @media screen and (max-width: 450px) and (min-width: 200px) {
      max-width: 45px;
    }
  }

  .logged-icon:first-child {
    margin-right: 10px;
    max-width: 20px;
  }
}

.progress-bars {
  display: flex;
  gap: 1px;
  width: calc(100% - 20px);

  .progress {
    width: 33.33%;
    border-radius: 8px;
    overflow: hidden;
    padding: 2px;
    height: auto;

    &:nth-child(1) {
      border: 3px solid #b596d9;

      &.filled {
        border-color: #732ada;
      }
    }

    &:nth-child(2) {
      border: 3px solid #e8c9f8;

      &.filled {
        border-color: #d362ff;
      }
    }

    &:nth-child(3) {
      border: 3px solid #b596d9;
      &.filled {
        border-color: #732ada;
      }
    }

    @media(max-width: 720px) {
      // display: none;
      width: 10%;
      .RSPBprogressBarText {
        display: none;
      }

      &.active {
        width: 80%;
        display: block;
        .RSPBprogressBarText {
          display: block;
        }
      }
    }
  }

  .RSPBprogressBar {
    width: 100%;
    height: 30px;
    border-radius: 5px;

    .RSPBprogression {
      transition: width 1s ease;
      border-radius: 5px;
    }

    .RSPBprogressBarText {
      left: 10px;
      transform: translate(0%, -50%);
      font-size: 16px;
    }
  }
}