@import '../../../assets/style/_variables.less';

.select-proffesion {
  width: 100%;
  margin: 0px auto;
  display: grid;
  align-content: center;

  .select-proffesion-section {
    width: 95%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: @layout-footer-height * 2 + 94px;

    @media screen and (max-width: 800px) {
      margin-bottom: @layout-footer-height * 2 + 59px;
    }

    @media screen and (max-width: 800px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    .proffesion-text {
      color: white;
      width: 100%;
      max-width: 592px;
      border-radius: 12px;
      font-family: @font-family;
      font-weight: bold;

      label {
        color: #C0B2D4;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-transform: uppercase;
      }

      input {
        border: 1px solid #7B35DF;
        border-radius: 12px;
        height: 58px;
        font-size: 20px;
      }

      .ant-input[disabled] {
        background-color: @primary-color;
        cursor: not-allowed;
        opacity: 1;
        color: #ffffff !important;
        font-weight: bold !important;
        -webkit-text-fill-color: #ffffff;
opacity: 1; /* required on iOS */
      }

      .ant-input[enabled] {
        color: @primary-color  !important;
        font-weight: bold !important;
      }

      .colored {
        input {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
        }

        label {
          -webkit-animation-name: bounceInRight;
          -moz-animation-name: bounceInRight;
          -o-animation-name: bounceInRight;
          animation-name: bounceInRight;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-direction: alternate;
        }

        input::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
          color: @primary-color  !important;
          font-weight: bold !important;
        }

        input:-moz-placeholder,
        textarea:-moz-placeholder {
          color: @primary-color  !important;
          font-weight: bold !important;
        }

        input::-moz-placeholder,
        textarea::-moz-placeholder {
          color: @primary-color  !important;
          font-weight: bold !important;
        }

        input:-ms-input-placeholder,
        textarea:-ms-input-placeholder {
          color: @primary-color  !important;
          font-weight: bold !important;
        }
      }
    }

    h2 {
      font-weight: bold;
      font-size: 30px;
      line-height: normal;
      font-family: @font-family;
      max-width: 900px;
      text-align: center;
      margin: 48px auto;
      -webkit-animation-name: bounceIn;
      -moz-animation-name: bounceIn;
      -o-animation-name: bounceIn;
      animation-name: bounceIn;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-direction: alternate;

      @media screen and (max-width: 800px) {
        font-size: 30px;
        margin: 56px auto;
      }

      @media screen and (max-width: 500px) {
        font-size: 22px;
        margin: 24px auto;
      }
    }

    .button-landing {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;
      right: 0;
      margin-bottom: @layout-footer-height;

      @media screen and (max-width: 800px) {
        padding-bottom: 7px;
        padding-top: 7px;
      }

      .signIn {
        -webkit-animation-name: fadeInUp;
        -moz-animation-name: fadeInUp;
        -o-animation-name: fadeInUp;
        animation-name: fadeInUp;
        animation-delay: 1.5s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }

      .buttonMakeAcc {
        -webkit-animation-name: fadeInUp;
        -moz-animation-name: fadeInUp;
        -o-animation-name: fadeInUp;
        animation-name: fadeInUp;
        animation-delay: 1.5s;
        animation-fill-mode: both;
        animation-direction: alternate;
      }
    }

    .button-proffesion-holder {
      animation-duration: 2s;
      animation-fill-mode: both;
      display: flex;
      justify-content: space-between;
      max-width: 450px;
      width: 100%;

      @media screen and (max-width: 800px) {
        padding: 10px 10px;
        display: block;
      }
    }
  }


  /*ANIMATIONS*/

  @-webkit-keyframes bounceIn {
    0% {
      opacity: 0;
      -webkit-transform: scale(.3);
    }

    50% {
      -webkit-transform: scale(1.05);
    }

    70% {
      -webkit-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }

  @-moz-keyframes bounceIn {
    0% {
      opacity: 0;
      -moz-transform: scale(.3);
    }

    50% {
      -moz-transform: scale(1.05);
    }

    70% {
      -moz-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -moz-transform: scale(1);
    }
  }

  @-o-keyframes bounceIn {
    0% {
      opacity: 0;
      -o-transform: scale(.3);
    }

    50% {
      -o-transform: scale(1.05);
    }

    70% {
      -o-transform: scale(.9);
    }

    100% {
      opacity: 1;
      -o-transform: scale(1);
    }
  }

  @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(.3);
    }

    50% {
      transform: scale(1.05);
    }

    70% {
      transform: scale(.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
    }

    60% {
      -webkit-transform: translateX(20px);
    }

    80% {
      -webkit-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -moz-transform: translateX(-2000px);
    }

    60% {
      -moz-transform: translateX(20px);
    }

    80% {
      -moz-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -o-transform: translateX(-2000px);
    }

    60% {
      opacity: 1;
      -o-transform: translateX(20px);
    }

    80% {
      -o-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      transform: translateX(-2000px);
    }

    60% {
      transform: translateX(20px);
    }

    80% {
      transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes bounceInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
    }

    60% {
      -webkit-transform: translateX(-20px);
    }

    80% {
      -webkit-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInRight {
    0% {
      opacity: 0;
      -moz-transform: translateX(2000px);
    }

    60% {
      -moz-transform: translateX(-20px);
    }

    80% {
      -moz-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInRight {
    0% {
      opacity: 0;
      -o-transform: translateX(2000px);
    }

    60% {
      -o-transform: translateX(-20px);
    }

    80% {
      -o-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInRight {
    0% {
      opacity: 0;
      transform: translateX(2000px);
    }

    60% {
      transform: translateX(-20px);
    }

    80% {
      transform: translateX(5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translateY(20px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }

  @-moz-keyframes fadeInUp {
    0% {
      opacity: 0;
      -moz-transform: translateY(20px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateY(0);
    }
  }

  @-o-keyframes fadeInUp {
    0% {
      opacity: 0;
      -o-transform: translateY(20px);
    }

    100% {
      opacity: 1;
      -o-transform: translateY(0);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

}