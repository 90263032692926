@import '../../../assets/style/_variables.less';

.ant-layout {
  background: none;

}

.game-finish {
  width: 100%;
  margin: 0px auto;

  .absoulute-back-left {
    position: absolute;
    left: 0;
    width: 250px;
    top: 0;

    @media screen and (max-width: 800px) {
      width: 150px;
    }
  }

  .absoulute-back-right {
    position: absolute;
    right: 0;
    width: 350px;
    top: 0;

    @media screen and (max-width: 800px) {
      width: 250px;
    }
  }

  .chest-end {
    max-width: 500px !important;
    margin-top: 32px;

    @media screen and (max-width: 800px) {
      width: 100px;
      margin-top: 24px;
    }
  }

  .game-finish-complete {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
    margin-bottom: @layout-footer-height * 2 + 94px;
    z-index: 11;

    @media screen and (max-width: 600px) {
      margin-bottom: @layout-footer-height * 2 + 59px;

      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 48px;
      line-height: normal;
      font-family: @font-family;
      max-width: 1200px;
      width: 98%;
      text-align: center;
      margin: 96px auto;
      margin-bottom: 24px;

      @media screen and (max-width: 800px) {
        font-size: 30px;
        margin: 24px auto;
      }

      @media screen and (max-width: 400px) {
        font-size: 26px;
      }
    }

    p {
      margin: 32px auto;
      width: 80%;

      @media screen and (max-width: 800px) {
        margin: 0px auto 24px auto;
      }
    }

    .game-finish-holder {
      margin-bottom: 90px;
      width: 100%;
      max-width: 800px;
      width: 80%;
    }
  }

  .button-landing {
    z-index: 11;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    padding-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid #d4d4d4;
    right: 0;
    margin-bottom: @layout-footer-height;

    @media screen and (max-width: 600px) {
      padding-bottom: 7px;
      padding-top: 7px;

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }
  }
}