@import '../../../assets/style/_variables.less';

.bonus-chest {
  width: 100%;
  margin: 0px auto;
  display: grid;
  align-content: center;

  .bonus-chest-section {
    width: 95%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: @layout-footer-height * 2 + 94px;

    @media screen and (max-width: 800px) {
      margin-top: @layout-footer-height;
      margin-bottom: @layout-footer-height * 2 + 59px;
    }

    @media screen and (max-width: 800px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    .sequence-board-info-talk-mentor {
      display: inline-grid;
      grid-template-columns: auto 1fr;
      grid-gap: 5px;
      font-family: @font-family;
      font-size: 18px;
      width: 100%;
      margin-bottom: 24px;
      justify-content: center;
      align-content: center;
      align-items: center;
      max-width: 600px;

      .avatars-backg-holder {
        width: 120px;
        height: 120px;
        max-width: 550px;
        display: block;
        display: grid;
        padding-top: 17px;
        border-radius: 20px;
        margin: 0px auto;
        margin-right: 19px;
        grid-column: 1;
        align-items: end;
        animation-name: bounceInLeft;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-delay: 1s;
        animation-direction: alternate;

        img {
          width: 100%;
          max-width: 80px;
          position: relative;
        }
      }

      .hi-msg {
        grid-column: 0.5;
        text-align: left;
        background-color: #7b35df;
        color: #ffffff;
        padding: 24px;
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;
        border-bottom-right-radius: 26px;
        max-width: -moz-fit-content;
        max-width: 660px;
        align-self: center;
        display: grid;
        align-items: center;
        animation-name: bounceInLeft;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-delay: 1s;
        animation-direction: alternate;
      }
    }


    h2 {
      font-weight: bold;
      font-size: 48px;
      line-height: normal;
      font-family: @font-family;
      text-align: center;
      margin: 96px auto;
      margin-bottom: 24px;
      width: 100%;
      animation-name: bounceInLeft;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-delay: 0.5s;
      animation-direction: alternate;

      @media screen and (max-width: 800px) {
        font-size: 30px;
        margin: 0px 0px 20px 0px;
      }

      @media screen and (max-width: 500px) {
        font-size: 22px;
      }
    }

    .bonus-chest-holder {
      animation-duration: 2s;
      animation-fill-mode: both;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 16px;
      width: 100%;
      justify-items: center;

      @media screen and (max-width: 900px) {
        grid-gap: 10px;
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 600px) {
        grid-gap: 7px;
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 400px) {
        grid-gap: 7px;
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .bonus-text {
      border: 1px solid @primary-color;
      color: black;
      width: 100%;
      max-width: 288px;
      border-radius: 12px;
      min-height: 214px;
      background: #efe6fc;
      display: flex;
      flex-wrap: wrap;
      opacity: 0.4;
      cursor: not-allowed;

      &:nth-child(1) {
        opacity: 1;
        cursor: pointer;
      }

      @media screen and (max-width: 1200px) {
        max-width: 230px;
        min-height: 156px;

        img {
          max-width: 80px;
        }
      }

      .nexth3 {
        margin-top: -8px;
      }

      p {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
        margin: 0px auto;
        text-align: left;
        padding: 15px;
        color: @primary-color;
        width: 100%;
        max-height: 60px;

        @media screen and (max-width: 800px) {
          font-size: 20px;
          max-height: 40px;
        }
      }

      img {
        max-width: 120px;
        width: 100%;
        align-self: end;
        margin: 0px auto;
        padding-bottom: 51px;

        @media screen and (max-width: 1200px) {
          max-width: 80px;
          padding-bottom: 31px;
        }
      }
    }

    .button-landing {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;
      right: 0;
      margin-bottom: @layout-footer-height;

      @media screen and (max-width: 800px) {
        padding-bottom: 7px;
        padding-top: 7px;
      }
    }
  }

  .fadeInBottom-bonus {
    animation-name: fadeInBottom-payment;
    animation-delay: 2s;
  }

  @keyframes fadeInBottom-payment {
    from {
      opacity: 0;
      transform: translateY(10%);
    }

    to {
      opacity: 1;
    }
  }

}