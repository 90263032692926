@import '../../../../assets/style/_variables.less';

.form-link {
    max-width: 450px;

    .inline-preview {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 450px;
        align-items: center;
        margin-top: 25px;

        .link-preview {
            color: @primary-color;
            font-size: 22px;
            text-decoration: underline;
        }
    }

    .explanation {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-transform: uppercase;
        color: #C0B2D4;
    }

    .link-holder {
        color: #7b35df;
        border: 1px solid #7b35df;
        border-radius: 12px;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        padding: 20px;
        width: 100%;
        text-align: left;
        display: block;
        margin-top: 24px;
        outline: none;
    }

    ::-webkit-input-placeholder {
        color: #7b35df;
    }

    ::-moz-placeholder {
        color: #7b35df;
    }

    :-ms-input-placeholder {
        color: #7b35df;
    }

    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    input:focus:-moz-placeholder {
        color: transparent;
    }

    /* FF 4-18 */
    input:focus::-moz-placeholder {
        color: transparent;
    }

    /* FF 19+ */
    input:focus:-ms-input-placeholder {
        color: transparent;
    }

    /* IE 10+ */

    .button-save {
        color: white;
        background-color: #7b35df;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid #7b35df;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        border-radius: 7px;
        margin: 5px;
        padding: 10px 15px;
        height: auto;
        width: auto;
        min-width: 150px;
        font-size: 20px;
        text-transform: uppercase;
        margin-left: 0;
    }

    @media screen and (max-width: 500px) {
        .explanation {
            font-size: 16px !important;
        }

        .link-holder {
            font-size: 11px !important;
            padding: 10px !important;
        }

        .button-save {
            padding: 5px 7px !important;
            min-width: 110px !important;
            font-size: 15px !important;
        }
    }
}


.imageOnHover {
    max-width: 350px;
    width: 100%;
    margin: 20px 0px;
    float: right;
    position: relative;

    &.elevator-pitch {
        img {
            @media(max-width: 600px) {
                width: 100% !important;
            }
        }

    }
}
.onHoverText {
    display: none;
    position: absolute;
    right: 50px;
    top: -100px;
    background: #7b35df;
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 12px;
}
.imageOnHover:hover {
    cursor: pointer;
}

.imageOnHover:hover > .onHoverText {
    display: block!important;
}
