@import '../../assets/style/_variables.less';

.footer-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 11;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  border-top: 1px solid #d4d4d4;

  .footer-holder {
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;

    @media screen and (max-width: 280px) {
      justify-content: center;
      width: 95%;
    }
  }

  .left-corner-footer {
    margin-left: 10px;
    float: left;
    display: flex;
    vertical-align: middle;
    position: relative;
    margin: 0px auto;
  }

  .left-corner-footer p {
    align-self: center;
    vertical-align: middle;
    position: relative;
    max-width: 250px;
    margin: 0px;
    font-family: @font-family-secondary;
    font-size: 12px;
    line-height: 14px;
    color: #9a9a9a;
  }

  .right-corner-footer {
    display: flex;
    margin-right: 10px;
    // max-width: 200px;
    position: relative;
    text-align: right;
    gap: 20px;
    margin: 0px auto;

    a {
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .right-corner-footer p {
    margin: 0px;
    font-family: @font-family-secondary;
    font-size: 12px;
    line-height: 14px;
    color: #9a9a9a;
  }
}