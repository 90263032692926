@import '../../../../assets/style/_variables.less';

.enable-edit {
    border: 1px solid @primary-color;
    color: black;
    width: 100%;
    max-width: 288px;
    border-radius: 12px;
    min-height: 214px;
    background: #efe6fc;
    display: flex;
    flex-wrap: wrap;
    opacity: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // cursor: not-allowed;
    .content-wrapper{
        width: 100%;
        height: 100%;
    
    @media screen and (max-width: 1200px) {
        max-width: 230px;
        min-height: 156px;

        img {
          max-width: 80px;
        }
      }

      .nexth3 {
        margin-top: -8px;
      }

      p {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
        // margin: 0px auto;
        text-align: left;
        padding: 15px;
        color: @primary-color;
        width: 100%;
        max-height: 60px;

        @media screen and (max-width: 800px) {
          font-size: 20px;
        //   max-height: 40px;
        }
      }

      img {
        max-width: 120px;
        width: 100%;
        align-self: end;
        margin: 0px auto;
        padding-bottom: 51px;

        @media screen and (max-width: 1200px) {
          max-width: 80px;
          padding-bottom: 31px;
        }
      }
    }
}

.disable-edit{
    border: 1px solid @primary-color;
    color: black;
    width: 100%;
    max-width: 288px;
    border-radius: 12px;
    min-height: 214px;
    background: #efe6fc;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0.4;
    cursor: not-allowed;
    .content-wrapper{
        width: 100%;
        height: 100%;
    
    @media screen and (max-width: 1200px) {
        max-width: 230px;
        min-height: 156px;

        img {
          max-width: 80px;
        }
      }

      .nexth3 {
        margin-top: -8px;
      }

      p {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
        // margin: 0px auto;
        text-align: left;
        padding: 15px;
        color: @primary-color;
        width: 100%;
        max-height: 60px;

        @media screen and (max-width: 800px) {
          font-size: 20px;
        //   max-height: 40px;
        }
      }

      img {
        max-width: 120px;
        width: 100%;
        align-self: end;
        margin: 0px auto;
        padding-bottom: 51px;

        @media screen and (max-width: 1200px) {
          max-width: 80px;
          padding-bottom: 31px;
        }
      }
    }
}