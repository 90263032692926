@import '../../../assets/style/_variables.less';

body::-webkit-scrollbar {
  width: 0.5px;
}

.modal-chart-pie {
  .ant-btn-primary {
    color: white;
    background-color: #7b35df;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #7b35df;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 7px !important;
    margin: 5px auto;
    padding: 10px 15px !important;
    height: auto !important;
    width: auto;
    min-width: 150px;
    font-size: 20px !important;
    text-transform: uppercase;
  }

  .ant-btn-action {
    display: none !important;
  }

  .industry-backg-holder {
    width: 100%;
    max-width: 600px;
    height: 200px;
    background: #efe6fc;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #7b35df;
    display: grid;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    grid-template-columns: auto;
    align-items: flex-start;

    img {
      max-width: 200px;
      width: 100%;
      height: auto;
    }
  }

  .ant-modal {
    max-width: 570px;
    width: 96% !important;
    top: 30px;
    padding-bottom: 30px !important;
  }

  .ant-modal-footer {
    text-align: center;
    padding-bottom: 30px;
    border: none;

    button {
      margin: 0px auto;
      font-size: 16px !important;
      padding: 10px !important;
    }
  }

  .ant-modal-body {
    padding: 0;
    border-top-right-radius: 26px;
    border-top-left-radius: 26px;
    background: #ffffff;
  }

  .ant-modal-content {
    border-radius: 26px;
    border: 1px solid @primary-color;
  }


}