@import '../../../../assets/style/_variables.less';

.filePreview {
    margin-top: 20px;

    span {
        display: block;
        margin-bottom: 10px;
        font-size: 20px;
        color: @primary-color;
    }

    .signUp {
        padding: 10px;
        font-size: 16px;
        margin: 0px;
        display: block;
        width: 100%;
        max-width: 150px;
        text-align: center;
        &:hover {
            background: white !important;
            color: #7b35df !important;

            a {
                color: @primary-color;
            }
        }

        &:focus {
            color: white;

            a {
                color: @primary-color;
            }
        }
    }

    a {
        color: white;

        &:hover {
            color: @primary-color;
        }

        &:focus {
            color: white;
        }
    }
}

.ant-upload.ant-upload-drag p.ant-upload-text {
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    color: @primary-color;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: @primary-color;
    font-size: 48px;
}

.ant-upload-drag {
    &:hover {
        border: 3px solid @primary-color  !important;
    }
}

.ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #fafafa;
    border: 3px dashed @primary-color;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
    max-width: 500px;
    min-height: 250px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.form-link-bussines {
    max-width: 450px;

    .explanation {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-transform: uppercase;
        color: #C0B2D4;
    }

    .link-holder {
        color: #7b35df;
        border: 1px solid #7b35df;
        border-radius: 12px;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        padding: 20px;
        width: 100%;
        text-align: left;
        display: block;
        margin-top: 24px;
    }

    ::-webkit-input-placeholder {
        color: #7b35df;
    }

    ::-moz-placeholder {
        color: #7b35df;
    }

    :-ms-input-placeholder {
        color: #7b35df;
    }

    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    input:focus:-moz-placeholder {
        color: transparent;
    }

    /* FF 4-18 */
    input:focus::-moz-placeholder {
        color: transparent;
    }

    /* FF 19+ */
    input:focus:-ms-input-placeholder {
        color: transparent;
    }

    /* IE 10+ */

    .button-save {
        color: white;
        background-color: #7b35df;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid #7b35df;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        border-radius: 7px;
        margin: 5px;
        padding: 10px 15px;
        height: auto;
        width: auto;
        min-width: 150px;
        font-size: 20px;
        text-transform: uppercase;
    }

    @media screen and (max-width: 500px) {
        .explanation {
            font-size: 16px !important;
        }

        .link-holder {
            font-size: 11px !important;
            padding: 10px !important;
        }

        .button-save {
            padding: 5px 7px !important;
            min-width: 110px !important;
            font-size: 15px !important;
        }
    }
}