@import '../../../../assets/style/_variables.less';

.general-info {
    .red {
        color: red;
    }

    .filePreviewTable {
        margin-top: 20px;
        .ant-table-title {
            text-align: center;
            background: #7b35df;
            color: white;
            font-size: 20px;
        }
        .ant-table-thead {
            background: #703bbd!important;
            color: white!important;
            border: none;
            font-size: 16px;
            border-left: 1px solid #703bbd;
            border-right: 0.05rem solid #703bbd;
            .ant-table-cell{
                color: white;
            }
        }
        .ant-table-cell{
            background: transparent!important;
        }
        .ant-table-wrapper {
            clear: both;
            width: 100%;
            max-width: 450px;
        } 
        .ant-table-title {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        .ant-table-row {
            border-left: 1px solid #703bbd;
            border-right: 0.05rem solid #703bbd;
        }
        .ant-table-tbody {
            border-collapse: collapse;
        }
        .ant-table table {
            border-collapse: collapse;
            border-spacing: 0;
        }
        .ant-table-tbody > tr > td {
            border: 0.1rem solid #703bbd;
        }
    }

    .startup-name {
        width: 100%;
        max-width: 450px;
        text-transform: uppercase;
        margin: 24px 0 0;
        font-family: @font-family;

        p {
            color: #C0B2D4;
            font-weight: bold;
            font-size: 18px;
            line-height: 18px;
        }

        span {
            color: @primary-color;
            border: 1px solid @primary-color;
            border-radius: 12px;
            font-weight: bold;
            font-size: 20px;
            line-height: 20px;
            padding: 20px;
            width: 100%;
            text-align: left;
            display: block;
        }
    }

    .equipmentList {
        max-width: 450px;
        text-align: left;
        margin-top: 64px;

        .equipmentListHolder {
            display: flex;
            font-size: 18px;
            margin: 0;
            padding: 0;

            .bolded {
                font-weight: bold;
                padding-left: 20px;
            }
        }

        h2 {
            margin: 48px auto;
        }

        .industry-backg-holder {
            display: none;
        }

        .green {
            display: none;
        }

        .device-per-eqi {
            display: flex;
            padding: 5px 10px 5px 10px;
            align-items: center;
            padding-left: 0;
            margin: 0;
        }
    }

    .emplyeesList {
        h2 {
            margin: 48px auto;
        }

        .inline-holder {
            display: flex;
            justify-content: space-between;
            max-width: 650px;

            @media screen and (max-width:900px) {
                flex-wrap: wrap;
                max-width: 450px;
            }

            .raising {
                max-width: 154px;
                width: 100%;
                text-align: center;

                @media screen and (max-width:900px) {
                    margin-bottom: 20px;
                }

                .money-count {
                    margin: 0px auto;
                }

                .signUp {
                    @media screen and (max-width:800px) {
                        padding: 7px 10px;
                        min-width: 110px;
                        font-size: 12px;

                    }
                }
            }

            .avatars-backg-holder {
                max-width: 154px;
                border-radius: 20px;
                padding-top: 27px;
                height: 154px;

                @media screen and (max-width:800px) {
                    max-width: 94px;
                    border-radius: 16px;
                    padding-top: 17px;
                    height: 94px;
                    margin: 0px auto;
                }

                img {
                    max-width: 104px;

                    @media screen and (max-width:800px) {
                        max-width: 64px;
                        height: 64px;
                    }
                }
            }
        }
    }


    .buildingList {
        h2 {
            margin: 48px auto;
        }

        .industry-backg-holder {
            width: 100%;
            max-width: 200px;
            display: block;
            padding-top: 25px;
            padding-bottom: 25px;
            height: 155px;
            position: relative;

            .ecofriend {
                max-width: 30px !important;
                width: 100% !important;
                position: absolute;
                right: 5px;
                top: 5px;
            }

            img {
                width: 100%;
                margin: 0px auto;
                position: absolute;
                bottom: 20px;

                @media screen and (max-width:500px) {
                    width: 100%;
                    max-width: 200px;
                }
            }
        }

        .building-holder {
            max-width: 200px;

            .building-text {
                text-align: center;

                h2 {
                    margin: 15px auto;
                }

                p {
                    margin: 0px auto;
                    font-size: 24px;
                    margin-bottom: 15px;
                    color: #57D22C;
                    font-weight: bold;

                    img {
                        max-width: 25px;
                        margin-left: 10px;
                        vertical-align: initial;
                    }
                }
            }

            .more-info-building {
                text-align: center;
            }
        }
    }


    @media screen and (max-width: 500px) {
        .avatars-backg-holder {
            width: 100%;
            max-width: 95px;
            height: 90px;
            border-radius: 18px;
        }

        img {
            max-width: 60px;
        }

        .startup-name p {
            font-size: 16px;
        }

        .startup-name span {
            font-size: 16px;
            padding: 12px;
        }

        .explanation {
            font-size: 16px !important;
        }

        .link-holder {
            font-size: 11px !important;
            padding: 10px !important;
        }

        .button-save {
            padding: 5px 7px !important;
            min-width: 110px !important;
            font-size: 15px !important;
        }
    }
}