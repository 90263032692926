@import '../../../../../../assets/style/_variables.less';

.answer-section {
    margin: 48px auto;

    @media screen and (max-width:560px) {
        max-width: 400px;
    }

    @media screen and (max-width:410px) {
        max-width: 300px;
    }

    .anwer-correct {
        .answerTrueFalse{
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 500px;
            justify-content: space-around;
            grid-column: 2;
            -webkit-animation-name: bounceInLeft;
            -moz-animation-name: bounceInLeft;
            -o-animation-name: bounceInLeft;
            animation-name: bounceInLeft;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-delay: 2s;
            animation-direction: alternate;
            .coinsAchivement {
                display: flex;
                align-items: center;
                font-size: 28px;
                max-width: 150px;
                width: 100%;
                height: 100%;
                margin: 0;
                img {
                    max-width: 40px;
                    margin-left: 10px;
                    width: 100%;
                }
            }
        }
        .sequence-board-info-talk-user {
            display: inline-grid;
            grid-template-columns: 1fr auto;
            grid-gap: 5px;
            font-family: @font-family-secondary;
            font-size: 18px;
            line-height: 21px;
            width: 100%;
            margin-bottom: 20px;

            @media screen and (max-width: 600px) {
                font-size: 16px;
            }

            .avatars-backg-holder {
                width: 120px;
                height: 120px;
                max-width: 550px;
                display: block;
                display: grid;
                padding-top: 17px;
                border-radius: 20px;
                margin: 0px auto;
                margin-left: 19px;
                grid-column: 2;
                align-items: end;
                -webkit-animation-name: bounceInRight;
                -moz-animation-name: bounceInRight;
                -o-animation-name: bounceInRight;
                animation-name: bounceInRight;
                animation-duration: 1s;
                animation-fill-mode: both;
                animation-delay: 1s;
                animation-direction: alternate;

                @media screen and (max-width:800px) {
                    width: 100px;
                    height: 100px;
                    margin-left: 10px;
                }

                @media screen and (max-width:600px) {
                    width: 70px;
                    height: 70px;
                }

                img {
                    width: 100%;
                    max-width: 80px;
                    position: relative;

                    @media screen and (max-width:800px) {
                        max-width: 60px;
                    }

                    @media screen and (max-width:600px) {
                        width: 40px;
                    }
                }
            }

            .hi-msg-user {
                grid-column: 1;
                grid-row: 1;
                justify-self: end;
                text-align: left;
                background-color: #57D22C;
                color: #ffffff;
                padding: 24px;
                border-top-left-radius: 26px;
                border-top-right-radius: 26px;
                border-bottom-left-radius: 26px;
                max-width: 660px;
                align-self: center;
                display: grid;
                align-items: center;
                -webkit-animation-name: bounceInRight;
                -moz-animation-name: bounceInRight;
                -o-animation-name: bounceInRight;
                animation-name: bounceInRight;
                animation-duration: 1s;
                animation-fill-mode: both;
                animation-delay: 1s;
                animation-direction: alternate;

                @media screen and (max-width:800px) {
                    font-size: 17px;
                    line-height: 22px;
                }

                @media screen and (max-width: 600px) {
                    font-size: 17px;
                    line-height: 22px;
                }

                @media screen and (max-width:420px) {
                    font-size: 14px;
                    line-height: 22px;
                    padding: 15px;
                }
            }
        }

        .sequence-board-info-talk-mentor {
            display: flex;
            width: 100%;
            margin: 0px auto;
            justify-content: center;

            @media screen and (max-width: 600px) {
                font-size: 16px;
            }

            .avatars-backg-holder {
                width: 120px;
                height: 120px;
                max-width: 550px;
                display: block;
                margin: 0px auto;
                display: grid;
                padding-top: 17px;
                border-radius: 20px;
                margin: 0px auto;
                margin-right: 19px;
                grid-column: 1;
                align-items: end;
                -webkit-animation-name: bounceInLeft;
                -moz-animation-name: bounceInLeft;
                -o-animation-name: bounceInLeft;
                animation-name: bounceInLeft;
                animation-duration: 1s;
                animation-fill-mode: both;
                animation-delay: 2s;
                animation-direction: alternate;

                @media screen and (max-width:600px) {
                    width: 100px;
                    height: 100px;
                    margin-right: 10px;
                }

                @media screen and (max-width:600px) {
                    width: 70px;
                    height: 70px;
                }

                img {
                    width: 100%;
                    max-width: 80px;
                    position: relative;

                    @media screen and (max-width:800px) {
                        max-width: 60px;
                    }

                    @media screen and (max-width:600px) {
                        width: 40px;
                    }
                }
            }

            .hi-msg {
                grid-column: 1 / 2;
                text-align: left;
                background-color: @primary-color;
                color: #ffffff;
                padding: 24px;
                border-top-left-radius: 26px;
                border-top-right-radius: 26px;
                border-bottom-right-radius: 26px;
                max-width: 660px;
                align-self: center;
                display: grid;
                align-items: center;
                -webkit-animation-name: bounceInLeft;
                -moz-animation-name: bounceInLeft;
                -o-animation-name: bounceInLeft;
                animation-name: bounceInLeft;
                animation-duration: 1s;
                animation-fill-mode: both;
                animation-delay: 2s;
                animation-direction: alternate;

                @media screen and (max-width:800px) {
                    font-size: 17px;
                    line-height: 22px;
                }

                @media screen and (max-width: 600px) {
                    font-size: 17px;
                    line-height: 22px;
                }

                @media screen and (max-width:420px) {
                    font-size: 14px;
                    line-height: 22px;
                    padding: 15px;
                }
            }
        }


        .sequence-board-info-talk-mentor2 {
            display: inline-grid;
            grid-template-columns: auto 1fr;
            grid-gap: 5px;
            font-family: Ubuntu, monospace;
            font-size: 18px;
            width: 100%;
            align-items: start;
            margin-top: 24px;

            @media screen and (max-width: 600px) {
                font-size: 16px;
            }

            .avatars-backg-holder {
                width: 120px;
                height: 120px;
                max-width: 550px;
                display: block;
                margin: 0px auto;
                display: grid;
                padding-top: 17px;
                border-radius: 20px;
                margin: 0px auto;
                margin-right: 19px;
                grid-column: 1;
                align-items: end;
                -webkit-animation-name: bounceInLeft;
                -moz-animation-name: bounceInLeft;
                -o-animation-name: bounceInLeft;
                animation-name: bounceInLeft;
                animation-duration: 1s;
                animation-fill-mode: both;
                animation-delay: 3s;
                animation-direction: alternate;

                @media screen and (max-width:600px) {
                    width: 100px;
                    height: 100px;
                    margin-right: 10px;
                }

                @media screen and (max-width:600px) {
                    width: 70px;
                    height: 70px;
                }

                img {
                    width: 100%;
                    max-width: 80px;
                    position: relative;

                    @media screen and (max-width:800px) {
                        max-width: 60px;
                    }

                    @media screen and (max-width:600px) {
                        width: 40px;
                    }
                }
            }

            .hi-msg {
                grid-column: 1 / 2;
                text-align: left;
                background-color: @primary-color;
                color: #ffffff;
                padding: 24px;
                border-top-left-radius: 26px;
                border-top-right-radius: 26px;
                border-bottom-right-radius: 26px;
                max-width: 660px;
                align-self: center;
                display: grid;
                align-items: center;
                -webkit-animation-name: bounceInLeft;
                -moz-animation-name: bounceInLeft;
                -o-animation-name: bounceInLeft;
                animation-name: bounceInLeft;
                animation-duration: 1s;
                animation-fill-mode: both;
                animation-delay: 3s;
                animation-direction: alternate;

                @media screen and (max-width:800px) {
                    font-size: 17px;
                    line-height: 22px;
                }

                @media screen and (max-width: 600px) {
                    font-size: 17px;
                    line-height: 22px;
                }

                @media screen and (max-width:420px) {
                    font-size: 14px;
                    line-height: 22px;
                    padding: 15px;
                }
            }
        }
    }

    .answer-style {
        max-width: 550px;
        border: 1px solid @primary-color;
        border-radius: 12px;
        margin: 0px auto;
        margin-bottom: 12px;
        background: #EFE6FC;
        font-family: @font-family-secondary;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: black;
        text-align: left;
        padding: 16px;
        cursor: pointer;

        &:hover {
            background-color: blueviolet;
            color: white;
        }

        @media screen and (max-width:410px) {
            padding: 12px;
            font-size: 14px;
        }
    }

    .answer-style-selected {
        background-color: blueviolet;
        max-width: 550px;
        border: 1px solid @primary-color;
        border-radius: 12px;
        margin: 0px auto;
        margin-bottom: 12px;
        font-family: @font-family-secondary;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: white;
        text-align: left;
        padding: 16px;
        cursor: pointer;

        @media screen and (max-width:410px) {
            padding: 15px;
            font-size: 18px;
        }
    }

    .button-landing {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: white;
        padding-bottom: 15px;
        padding-top: 15px;
        border-top: 1px solid #d4d4d4;
        right: 0;
        margin-bottom: @layout-footer-height;

        @media screen and (max-width: 500px) {
            padding-bottom: 7px;
            padding-top: 7px;
            margin-bottom: 45px;

            .signUp {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }

            .buttonMakeAcc {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }
        }
    }

    /*ANIMATIONS*/

    @-webkit-keyframes bounceInRight {
        0% {
            opacity: 0;
            -webkit-transform: translateX(2000px);
        }

        60% {
            -webkit-transform: translateX(-20px);
        }

        80% {
            -webkit-transform: translateX(5px);
        }

        100% {
            opacity: 1;
            -webkit-transform: translateX(0);
        }
    }

    @-moz-keyframes bounceInRight {
        0% {
            opacity: 0;
            -moz-transform: translateX(2000px);
        }

        60% {
            -moz-transform: translateX(-20px);
        }

        80% {
            -moz-transform: translateX(5px);
        }

        100% {
            opacity: 1;
            -moz-transform: translateX(0);
        }
    }

    @-o-keyframes bounceInRight {
        0% {
            opacity: 0;
            -o-transform: translateX(2000px);
        }

        60% {
            -o-transform: translateX(-20px);
        }

        80% {
            -o-transform: translateX(5px);
        }

        100% {
            opacity: 1;
            -o-transform: translateX(0);
        }
    }

    @keyframes bounceInRight {
        0% {
            opacity: 0;
            transform: translateX(2000px);
        }

        60% {
            transform: translateX(-20px);
        }

        80% {
            transform: translateX(5px);
        }

        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @-webkit-keyframes fadeInUp {
        0% {
            opacity: 0;
            -webkit-transform: translateY(20px);
        }

        100% {
            opacity: 1;
            -webkit-transform: translateY(0);
        }
    }

    @-moz-keyframes fadeInUp {
        0% {
            opacity: 0;
            -moz-transform: translateY(20px);
        }

        100% {
            opacity: 1;
            -moz-transform: translateY(0);
        }
    }

    @-o-keyframes fadeInUp {
        0% {
            opacity: 0;
            -o-transform: translateY(20px);
        }

        100% {
            opacity: 1;
            -o-transform: translateY(0);
        }
    }

    @keyframes fadeInUp {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }

        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

}