.d-none {
    display: none !important;
}

.videoHolderParent {
    .button-landing {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: none;
        padding-bottom: 15px;
        padding-top: 15px;
        border: none;
        right: 0;
        // margin-bottom: @layout-footer-height;
        z-index: 1111111;


        .buttonMakeAcc {
            float: right;
            margin-right: 20px;
        }

        @media screen and (max-width: 600px) {
            padding-bottom: 7px;
            padding-top: 7px;

            .signIn {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }

            .buttonMakeAcc {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }
        }
    }

    .videoContainer {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../../assets/images/bg8.svg');
        background-attachment: fixed;
        background-color: white;
        background-size: contain;
        min-width: 100%;
        min-height: 100%;
        z-index: 111111;
    }

    .videoContainer video.video {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
    }

    .videoContainer video.video:focus {
        outline: none;
    }


    video::-webkit-media-controls {
        display: none;
    }

}