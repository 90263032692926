@import '../../../assets/style/_variables.less';

.ant-menu-submenu-popup {
  width: 100%;
  max-width: 220px;
  border-radius: 12px;
  top: 80px !important;
  border-top-color: #fff;
  z-index: 11111;
}

.ant-menu {
  background: none;
}

.ant-menu-submenu>.ant-menu {
  background-color: #fff;
  border-radius: 12px;
  border-top-color: #fff;
  border: 1px solid #EFE6FC;
  margin-left: -10px;
  box-shadow: 0px 0px 15px 1px #e0e0e0;

  @media screen and (max-width: 1465px) {
    margin-left: 0px;
  }
}

.ant-menu-item-selected {
  color: @primary-color  !important;
  border: none !important;
}

.ant-menu-vertical>.ant-menu-item-only-child {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  font-family: @font-family-secondary;
  border-bottom: 1px solid #EFE6FC;
  padding: 15px 0px 15px 0px;
  height: auto;
}

.ant-menu-vertical {
  padding: 0px 20px 0px 20px !important;

  li {
    &:last-child {
      border: none !important;
    }
  }
}

.ant-menu-vertical::before {
  content: '';
  position: absolute;
  background: white;
  background-image: unset !important;
  width: 0 !important;
  height: 0 !important;
  border-style: solid;
  border-width: 16px 0 4px 20px;
  border-color: #ffffff transparent transparent transparent;
  transition: transform 0.3s ease-in-out !important;
  transform: rotate(-45deg) !important;
  border-radius: 0 !important;
  left: 15px;
  top: -8px;
  box-shadow: 4px -4px 15px 0px #e0e0e0;
  z-index: 1111111;
  animation: fadeIn 1s;

  @media screen and (max-width: 1465px) {
    left: 175px;
    top: -8px;
    box-shadow: 1px -1px 0px 0px #EFE6FC;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  content: none !important;
}

.ant-menu-item:hover,
.ant-menu-submenu:hover,
.ant-menu-submenu-active,
.ant-menu-item-open,
.ant-menu-submenu-open,
.ant-menu-item-selected,
.ant-menu-submenu-selected {
  color: @primary-color  !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffffff;
  border-bottom: 1px solid #EFE6FC !important;

  &:last-child {
    border: none !important;
  }
}

.lang-selection {
  height: 80px !important;
}

.ant-select-dropdown {
  img {
  max-width: 50px!important;
  width: 100%;
}
// .ant-select-arrow{
//   display: none;
// }
}

#lng-switch {
  display: flex;
  margin-right: 20px;
  @media screen and (max-width: 500px) {
    margin-right: 5px;
  }
  img {
    max-width: 30px!important;
    width: 100%;
    cursor: pointer;
    margin-right: 5px;
    @media screen and (max-width: 500px) {
      max-width: 25px!important;
      width: auto;
    }
  }
  .inline {
    display: flex;
  }

  .active {
    border: 3px solid #7c36df;
    border-radius: 20px;
    max-width: 32px!important;
}

  // .image-second {
  //   display: block;
  //   max-width: 50px!important;
  //   margin-right: 5px;
  // }


  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 25px;
    padding: 0 11px;
    border: 0;
    padding-left: 0;
}

  .ant-select-open {
    box-shadow: 0px 0px 4px 4px white!important;
  }
  .ant-select-selection-item {
    box-shadow: 0px 0px 4px 4px white!important;
  }
  .ant-select-selector {
  box-shadow: 0px 0px 4px 4px white!important;
  }
}
html {-webkit-tap-highlight-color: rgba(0, 0, 0, 0);}

.header-main {


  .budget-section {
    display: none;
  }

  .ant-menu-horizontal {
    border: none !important;
  }

  .ant-menu {
    position: relative;
    max-width: 85px;
    right: 0;
    box-shadow: none;
    width: 100% !important;
    padding: 0px;
    background: none;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 600px) {
      display: block;
      max-width: 70%;

      .ant-menu-item-only-child {
        float: left;
        padding-top: 7px;
      }
    }

    .ant-menu-sub {
      position: absolute;
      box-shadow: 0px 1px 6px #d8d8d8;
    }

    .avatars-backg-holder {
      width: 50px;
      height: 50px;
      border-radius: 12px;

      @media screen and (max-width: 600px) {
        width: 40px;
        height: 40px;
        border-radius: 8px;
      }

      img {
        max-width: 35px;

        @media screen and (max-width: 600px) {
          max-width: 25px;
          bottom: -1px;
        }
      }
    }

    .logged-icon:first-child {
      margin-right: 10px;
      width: 100% !important;
      max-width: 47px;
    }
  }

  .ant-menu-submenu-selected {
    color: transparent !important;
    border: none !important;
  }

  .ant-menu-horizontal>.ant-menu-item,
  .ant-menu-horizontal>.ant-menu-submenu {
    margin-top: 0px !important;
    top: 0 !important;
    border: none !important;
    margin: 0 10px !important;
  }

  .signIn {
    border: none;
    color: black;
  }

  @media screen and (max-width: 450px) and (min-width: 300px) {
    .signIn {
      margin: 3px;
      padding: 6px 10px;
      font-size: 14px;
      font-weight: bold;
    }

    .signUp {
      margin: 3px;
      padding: 6px 10px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 300px) and (min-width: 200px) {
    .signIn {
      margin: 2px;
      padding: 4px 8px;
      font-size: 13px;
      font-weight: bold;
    }

    .signUp {
      margin: 2px;
      padding: 4px 8px;
      font-size: 13px;
      font-weight: bold;
    }
  }

}