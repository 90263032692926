@import '../../assets/style/_variables.less';

#root {
    overflow-x: hidden;
}

.test {
    display: flex;
    width: 100%;

    .ant-menu-vertical::before {
        display: none !important;
    }

    .ant-layout-header {
        background: none;
    }

    .ant-layout-sider {
        background: #e4e4e4;
    }

    .ant-layout-sider-collapsed {
        @media screen and (max-width: 500px) {
            position: relative;
        }
    }

    .ant-menu.ant-menu-dark,
    .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub {
        color: black;
        background: none;
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: @primary-color;
    }

    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item>a,
    .ant-menu-dark .ant-menu-item>span>a {
        color: black;
    }

    .ant-menu-item:hover,
    .ant-menu-submenu:hover,
    .ant-menu-submenu-active,
    .ant-menu-item-open,
    .ant-menu-submenu-open,
    .ant-menu-item-selected,
    .ant-menu-submenu-selected {
        color: grey !important;
    }

    .ant-layout-content {
        box-shadow: 0px 0px 5px -1px #d2d2d2;
        text-align: left;
        margin: 16px 16px !important;
    }

    .ant-menu-vertical {
        padding: 0px !important;
    }

    .ant-menu-inline-collapsed>.ant-menu-item,
    .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
    .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
    .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
        padding: 0 calc(50% - 24px / 2) !important;
    }

    .ant-menu-inline-collapsed>.ant-menu-item .anticon,
    .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon,
    .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon,
    .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon {
        font-size: 24px !important;
    }

    .ant-menu-item .anticon,
    .ant-menu-submenu-title .anticon {
        font-size: 20px;
    }

    .ant-menu-item .anticon+span,
    .ant-menu-submenu-title .anticon+span {
        font-size: 16px;
    }


    @media screen and (max-width: 500px) {

        .ant-menu-item .anticon,
        .test .ant-menu-submenu-title .anticon {
            font-size: 14px;
        }

        .ant-menu-item .anticon+span,
        .test .ant-menu-submenu-title .anticon+span {
            font-size: 14px;
        }

        .ant-menu-item {
            padding-left: 10px !important;
        }

        .ant-layout-sider-dark {
            height: 100%;
            z-index: 11;
            box-shadow: 8px 0px 9px -7px grey;
            max-width: 170px !important;
            min-width: 170px !important;
        }

        .ant-layout-sider-collapsed {
            max-width: 80px !important;
            min-width: 80px !important;
        }

        .ant-layout-header {
            height: 42px;
            line-height: 42px;
        }

        .ant-layout-content {
            padding: 14px !important;
        }
    }

    .button-landing {
        z-index: 111;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: white;
        padding-bottom: 5px;
        padding-top: 5px;
        border-top: 1px solid #d4d4d4;
        right: 0;
        margin-bottom: @layout-footer-height;

        .buttonMakeAcc {
            padding: 5px 10px;
            min-width: 80px;
            font-size: 12px;
        }

        @media screen and (max-width: 600px) {
            padding-bottom: 7px;
            padding-top: 7px;

            .buttonMakeAcc {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 12px;
            }
        }
    }

    @media(max-width: 500px) {
        margin-bottom: 80px;
    }
}

.ant-layout-has-sider {
    min-height: 100vh;
}