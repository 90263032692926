@import '../../../assets/style/_variables.less';

.office-buy {
  width: 100%;
  margin: 0px auto;

  .ant-tabs-nav-operations {
    display: none;
  }

  .ecofriend {
    max-width: 30px !important;
    width: 100% !important;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .office-buy-section {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: @layout-footer-height * 2 + 94px;

    @media screen and (max-width: 1100px) {
      width: 95%;
    }

    @media screen and (max-width: 600px) {
      margin-bottom: @layout-footer-height * 2 + 59px;
    }

    @media screen and (max-width: 600px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    .ant-tabs-nav-operations {
      display: none;
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: normal;
      font-family: @font-family;
      max-width: 800px;
      width: 98%;
      text-align: center;
      margin: 48px auto;

      @media screen and (max-width: 860px) {
        font-size: 30px;
        margin: 56px auto;
      }

      @media screen and (max-width: 540px) {
        font-size: 24px;
        margin: 24px auto;
      }

      @media screen and (max-width: 320px) {
        font-size: 24px;
      }
    }

    .button-landing {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;
      right: 0;
      margin-bottom: @layout-footer-height;

      @media screen and (max-width: 600px) {
        padding-bottom: 7px;
        padding-top: 7px;
      }
    }

    .office-buy-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 50px;
      width: 98%;
      margin: 0px auto;

      @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .office-holder {
      width: 100%;
      max-width: 300px;
      margin: 0px auto;
      animation-duration: 1s;
      animation-fill-mode: both;
      margin: 10px;
      cursor: pointer;
      position: relative;

      .tooltiptext {
        display: none;
        position: absolute;
        bottom: 45px;
        left:0;
        margin-top: -50px;
        margin-left: 25px;
      }
      
      .forHover:hover + .tooltiptext {
        cursor: pointer;
        display: block;
        max-width: 350px;
        width: 100%;
        line-height: 16px;
        padding: 8px;
        font-size: 13px;
        text-align: center;
        z-index: 999;
        color: white;
        background: #7b35df;
        border-radius: 5px;
        -webkit-transition: opacity 100ms ease-in;
        -o-transition: opacity 100ms ease-in;
        -moz-transition: opacity 100ms ease-in;
        transition: opacity 100ms ease-in;
        pointer-events: none;
        box-shadow: 4px 4px 7px -2px #7b35df;
        margin-left: 0px; 
      }
      
      .tooltiptext:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-width: 10px;
        border-style: solid;
        border-color: transparent #7b35df transparent transparent;
        top: -20px;
        left: 48%;
        transform: rotate(90deg);
      }

      @media screen and (max-width: 980px) {
        .tooltiptext {
          display: none;
          position: absolute;
          bottom: 35px;
          left:0;
          margin-left: 0px; 
        }
        
      }

      @media screen and (max-width: 1200px) {
        max-width: 284px;
      }

      h2 {
        font-size: 22px;
        font-weight: bold;
        margin: 24px auto;
      }

      .industry-backg-holder {
        width: 100%;
        max-width: 300px;
        height: 100%;
        min-height: 220px;
        max-height: 220px;
        background: #efe6fc;
        border-radius: 15px;
        border: 1px solid #7b35df;
        display: flex;
        justify-content: center;
        padding-top: 25px;
        padding-bottom: 25px;
        margin: 0px auto;
        align-items: center;
        position: relative;

        @media screen and (max-width: 1200px) {
          width: 100%;
          max-width: 284px;
          height: 100%;
          min-height: 170px;
          max-height: 170px;

          img {
            max-width: 220px;
          }
        }

        @media screen and (max-width: 320px) {
          width: 100%;
          max-width: 224px;
          height: 100%;
          min-height: 120px;
          max-height: 120px;

          img {
            max-width: 180px;
          }
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .selected-building {
        background: #57D22C;
        width: 100%;
        max-width: 300px;
        height: 100%;
        min-height: 220px;
        max-height: 220px;
        border-radius: 15px;
        border: 1px solid #57D22C;
        display: flex;
        justify-content: center;
        padding-top: 25px;
        padding-bottom: 25px;
        margin: 0px auto;
        margin: 0px auto;
        align-items: center;
        position: relative;

        @media screen and (max-width: 1200px) {
          width: 100%;
          max-width: 284px;
          height: 100%;
          min-height: 170px;
          max-height: 170px;

          img {
            max-width: 220px;
          }
        }

        @media screen and (max-width: 320px) {
          width: 100%;
          max-width: 224px;
          height: 100%;
          min-height: 120px;
          max-height: 120px;

          img {
            max-width: 180px;
          }
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      p {
        font-family: @font-family-secondary;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        margin: 32px 32px 32px 32px;

        img {
          max-width: 25px;
          margin-left: 5px;
        }

        @media screen and (max-width: 654px) {
          margin: 0px 15px 16px 15px;
          font-size: 17px;
        }
      }

      .green {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: #57d22c;
      }

    }

    .fadeInBottom-building {
      animation-name: fadeInBottom-building;
    }

    @keyframes fadeInBottom-building {
      from {
        opacity: 0.7;
        transform: translateY(5%);
      }

      to {
        opacity: 1;
      }
    }
  }

  .ant-tabs {
    margin: 0px auto;
    color: black !important;

    .ant-tabs-ink-bar {
      position: absolute;
      background: @primary-color;
      pointer-events: none;
    }

    .ant-tabs-nav,
    .ant-tabs>div>.ant-tabs-nav {
      max-width: 400px;
      margin: 0px auto;
      margin-bottom: 20px;
    }

    .ant-tabs-tab {
      font-size: 22px;
      line-height: 26px;
      font-weight: bold;
      margin: 0px;
      padding: 12px;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
    font-weight: bold;
  }

  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: black !important;
  }

  .ant-tabs-tab:hover {
    color: black;
  }
}
