@import '../../../../assets/style/_variables.less';

.updateForm {
    max-width: 450px;

    .signIn {
        color: white;
        background-color: @primary-color;
        font-weight: bold;
        cursor: pointer;
        border: 1px solid @primary-color;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        border-radius: 7px;
        padding: 10px 15px;
        height: auto;
        width: auto;
        min-width: 150px;
        font-size: 20px;
        text-transform: uppercase;
    }

    .ant-form {
        text-align: left;
        padding-bottom: 0;
    }

    .ant-col-offset-8 {
        margin-left: 0px;
    }

    .ant-input {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: 'tnum', 'tnum', 'tnum';
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 17px 13px;
        color: @primary-color;
        background-color: #fff;
        background-image: none;
        border: 1px solid @primary-color;
        border-radius: 5px;
        transition: all 0.3s;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
    }

    .ant-input-affix-wrapper {
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 17px 13px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: 1px solid @primary-color;
        border-radius: 5px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }

    .ant-input-number-input-wrap {
        border: 1px solid @primary-color;
        border-radius: 5px;
        padding: 10px 10px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 1.5715;
    }

    .ant-input-number-input {
        height: auto;
    }

    label {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-transform: uppercase;
        color: #c0b2d4 !important;
    }

    .ant-select-selector {
        border: 1px solid @primary-color  !important;
        border-radius: 5px !important;
        padding: 6px 5px !important;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        height: auto !important;
    }

    .ant-form-item {
        margin-bottom: 20px;
    }

    .ant-select-arrow {
        color: rgb(87 36 179);
    }

    .ant-form-item-label {
        text-align: left !important;
    }

    span {
        font-weight: bold;
    }

    ::-webkit-input-placeholder {
        color: @primary-color;
    }

    ::-moz-placeholder {
        color: @primary-color;
    }

    :-ms-input-placeholder {
        color: @primary-color;
    }

    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

    input:focus:-moz-placeholder {
        color: transparent;
    }

    /* FF 4-18 */
    input:focus::-moz-placeholder {
        color: transparent;
    }

    /* FF 19+ */
    input:focus:-ms-input-placeholder {
        color: transparent;
    }

    .ant-select-selection-item {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: 'tnum', 'tnum', 'tnum', "tnum";
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 9px 7px !important;
        color: @primary-color;
        background-color: #fff;
        background-image: none;
        border-radius: 5px;
        transition: all 0.3s;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
    }

    .ant-spin-spinning {
        display: inline-block;
        opacity: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 50%;
        text-align: center;
        z-index: 111;
    }

    .spinner-back {
        background-color: fade(white, 60%);
        width: 100%;
        display: inline-block;
        opacity: 1;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        text-align: center;
        z-index: 111;
        height: 100vh;
    }

    .spinner-non-back {
        display: none;
    }

    .ant-spin-dot-item {
        background-color: @primary-color;
    }

    @media screen and (max-width: 500px) {
        label {
            font-size: 14px;
            line-height: 14px;
        }

        .ant-input {
            padding: 7px 7px;
            font-size: 16px;
            line-height: 16px;
        }

        .ant-select-selection-item {
            font-size: 15px;
            line-height: 15px !important;
        }
    }
}