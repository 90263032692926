@import '../../../assets/style/_variables.less';


.device-per-eqi {
  display: flex;
  padding: 24px 32px 24px 32px;
  align-items: center;

  .industry-backg-holder {
    max-width: 120px;

    img {
      max-width: 50px;

      @media screen and (max-width: 550px) {
        max-width: 30px;
      }
    }

    @media screen and (max-width: 550px) {
      max-width: 70px;
      border-radius: 12px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .right-number {
    text-align: right;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;

    @media screen and (max-width: 550px) {
      font-size: 16px;
    }
  }

  p {
    padding: 0px !important;
    text-align: left;
    width: 95%;
    text-indent: 20px;

    @media screen and (max-width: 550px) {
      font-size: 16px;
    }
  }
}


.checkout {
  width: 100%;
  margin: 0px auto;
  display: grid;
  align-content: center;
  background-image: url('../../../assets/images/bg8.svg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: white;

  .checkout-section {
    width: 95%;
    display: block;
    margin: 0px auto;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: @layout-footer-height * 2 + 94px;

    @media screen and (max-width: 800px) {
      margin-bottom: @layout-footer-height * 2 + 59px;
    }

    @media screen and (max-width: 800px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    .counting-price {
      display: flex;
      max-width: 650px;
      margin: 0px auto;
      justify-content: space-around;

      @media screen and (max-width: 800px) {
        display: block;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: normal;
      font-family: @font-family;
      max-width: 1200px;
      text-align: center;
      margin: 48px auto;

      @media screen and (max-width: 800px) {
        font-size: 30px;
        margin: 56px auto;
      }

      @media screen and (max-width: 500px) {
        font-size: 22px;
        margin: 24px auto;
      }
    }

    .button-checkout-holder {
      animation-duration: 2s;
      animation-fill-mode: both;

      @media screen and (max-width: 800px) {
        padding: 10px 10px;
        display: block;
      }
    }

    .checkout-text {
      background-color: #ffffff;
      color: #000000;
      width: 100%;
      max-width: 592px;
      border: 1px solid #eaeaea;
      border-radius: 12px;
      text-align: left;
      margin: 0px auto;

      h4 {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 26px;
        text-align: left;
        color: #000000;
        padding: 24px 0px 24px 33px;
        border-bottom: 1px solid #eaeaea;
        margin: 0;

        @media screen and (max-width: 800px) {
          margin: 16px auto;
          font-size: 22px;
        }
      }

      p {
        font-family: @font-family-secondary;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        margin: 0px auto;
        padding: 24px 0px 0px 33px;

        @media screen and (max-width: 800px) {
          font-size: 16px;
        }
      }

      .green {
        color: #57D22C;
        font-weight: bold;
        font-style: normal;
        font-size: 20px;
        line-height: 20px;
        padding-left: 14px;
        display: flex;
        justify-content: space-around;
        max-width: 90px;
        align-items: center;

        @media screen and (max-width: 550px) {
          font-size: 16px;
          padding-left: 7px;
        }

        img {
          max-width: 20px;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }

    .devices-complete-holder {
      border-bottom: 1px solid #eaeaea;

      &:nth-last-child(1) {
        border: none !important;
      }
    }

    .totalCount {
      display: inline-block;
      margin: 24px auto;
      width: 100%;
      max-width: 450px;
      animation-duration: 2s;
      animation-fill-mode: both;
      cursor: pointer;

      @media screen and (max-width: 800px) {
        margin: 12px auto;
      }

      .total-count-text {
        background-color: #ecdfff;
        max-width: 186px;
        padding: 5px;
        margin: 0px auto;
        margin-top: 32px;
        color: @primary-color;
        font-size: 18px;
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;
        font-weight: bold;
      }

      .total-count-text-selected {
        background-color: @primary-color;
        max-width: 186px;
        padding: 5px;
        margin: 0px auto;
        margin-top: 32px;
        color: white;
        font-size: 18px;
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;
        font-weight: bold;
      }

      .total-count-number {
        max-width: 244px;
        padding: 17px;
        border: 1px solid @primary-color;
        border-radius: 14px;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
        color: #57d22c;
        margin: 0px auto;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 25px;
          margin-left: 10px;
        }

        @media screen and (max-width: 600px) {
          padding: 24px;
        }
      }
    }

    .button-landing {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;
      right: 0;
      margin-bottom: @layout-footer-height;

      @media screen and (max-width: 800px) {
        padding-bottom: 7px;
        padding-top: 7px;
      }
    }
  }

  .fadeInBottom-checkout {
    animation-name: fadeInBottom-legal;
  }

  @keyframes fadeInBottom-legal {
    from {
      opacity: 0;
      transform: translateY(30%);
    }

    to {
      opacity: 1;
    }
  }

  .fadeInBottom-total {
    animation-name: fadeInBottom-total;
  }

  @keyframes fadeInBottom-total {
    from {
      opacity: 0;
      transform: translateY(10%);
    }

    to {
      opacity: 1;
    }
  }

}