@import "../../../assets/style/_variables.less";

.employee-selection {
    width: 100%;
    margin: 0px auto;

    .ant-rate {
        vertical-align: super;
    }

    .employee-selection-section {
        width: 95%;
        display: block;
        margin: 0px auto;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1200px;
        margin-bottom: @layout-footer-height * 2 + 94px;

        @media screen and (max-width: 600px) {
            margin-bottom: @layout-footer-height * 2 + 59px;

            .signIn {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }

            .signUp {
                color: white;
                background-color: #7b35df;
                font-weight: bold;
                cursor: pointer;
                border: 1px solid #7b35df;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
                border-radius: 7px;
                margin: 0px auto;
                padding: 5px 10px;
                height: auto;
                width: auto;
                min-width: 100px;
                font-size: 16px;
                text-transform: uppercase;
            }

            .buttonMakeAcc {
                padding: 5px 10px;
                min-width: 80px;
                font-size: 14px;
            }

            .ant-tabs-nav-list {
                position: relative;
                display: -ms-flexbox;
                display: grid !important;
                -webkit-transition: -webkit-transform 0.3s;
                transition: -webkit-transform 0.3s;
                transition: transform 0.3s;
                transition: transform 0.3s, -webkit-transform 0.3s;
                flex-flow: column;
                text-align: center;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
                grid-auto-rows: minmax(30px, auto);
            }

            .ant-tabs-tab-btn:focus,
            .ant-tabs-tab-btn:active,
            .ant-tabs-tab-btn:checked,
            .ant-tabs-tab-btn:enabled {
                border-bottom: 2px solid @primary-color;
                display: block;
                margin-bottom: -2px;
            }

            .ant-tabs-ink-bar {
                display: none;
            }

            .ant-tabs-tab {
                color: black;
                text-align: center;
                margin: 0px auto;
            }

            .ant-tabs-tab {
                color: black;
                padding: 0px 15px 0px 15px;
            }
        }

        @media screen and (max-width: 400px) {
            .ant-tabs-nav-list {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 10px;
                grid-auto-rows: minmax(20px, auto);
                padding-bottom: 10px;
            }

            .ant-tabs-tab {
                color: black;
            }

            .ant-tabs-tab-btn {
                padding: 5px 0px 5px 0px;
            }
        }

        h2 {
            font-weight: bold;
            font-size: 36px;
            line-height: normal;
            font-family: @font-family;
            max-width: 1200px;
            width: 98%;
            text-align: center;
            margin: 48px auto;
            -webkit-animation-name: bounceIn;
            -moz-animation-name: bounceIn;
            -o-animation-name: bounceIn;
            animation-name: bounceIn;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-direction: alternate;

            @media screen and (max-width: 800px) {
                font-size: 30px;
                margin-bottom: 48px;
                margin-top: @layout-footer-height * 2;
            }

            @media screen and (max-width: 500px) {
                font-size: 25px;
                margin-bottom: 24px;
                margin-top: @layout-footer-height;
            }
        }

        p {
            font-size: 28px;

            @media screen and (max-width: 720px) {
                font-size: 20px;
            }
        }

        .button-landing {
            position: fixed;
            bottom: 0;
            width: 100%;
            background-color: white;
            padding-bottom: 15px;
            padding-top: 15px;
            border-top: 1px solid #d4d4d4;
            right: 0;
            margin-bottom: @layout-footer-height;

            @media screen and (max-width: 600px) {
                padding-bottom: 7px;
                padding-top: 7px;
            }

            .signIn {
                -webkit-animation-name: fadeInDown;
                -moz-animation-name: fadeInDown;
                -o-animation-name: fadeInDown;
                animation-name: fadeInDown;
                animation-duration: 0.5s;
                animation-delay: 1.5s;
                animation-fill-mode: both;
                animation-direction: alternate;
            }

            .buttonMakeAcc {
                -webkit-animation-name: fadeInDown;
                -moz-animation-name: fadeInDown;
                -o-animation-name: fadeInDown;
                animation-name: fadeInDown;
                animation-duration: 0.5s;
                animation-delay: 1.5s;
                animation-fill-mode: both;
                animation-direction: alternate;
            }
        }

        .employee-holder {
            width: 100%;
            max-width: 800px;
            text-align: center;
        }

        .ant-tabs {
            margin: 0px auto;
            color: black !important;

            .ant-tabs-ink-bar {
                position: absolute;
                background: @primary-color;
                pointer-events: none;
            }

            .ant-tabs-nav,
            .ant-tabs > div > .ant-tabs-nav {
                max-width: 800px;
                margin: 0px auto;
                margin-bottom: 20px;
            }

            .ant-tabs-tab {
                line-height: 26px;
                font-weight: bold;
                font-size: 20px;
                margin: 0px auto;
                justify-content: center;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                max-width: 180px;
                white-space: pre-line;
                margin-left: 10px;
                word-break: break-word;

                @media screen and (max-width: 800px) {
                    font-size: 15px;
                    line-height: 20px;
                    margin: 0px;
                }

                @media (max-width: 600px) {
                    &.ant-tabs-tab-active {
                        div {
                            border-bottom: 2px solid @primary-color;
                        }
                    }
                }
            }

            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: black;
                font-weight: bold;
            }

            .ant-tabs-tab-btn:focus,
            .ant-tabs-tab-remove:focus,
            .ant-tabs-tab-btn:active,
            .ant-tabs-tab-remove:active {
                color: black !important;
            }

            .ant-tabs-tab:hover {
                color: black;
            }

            .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
            .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
                justify-content: center;
            }

            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: black;
                font-weight: bold;
            }

            .ant-tabs-tab:hover {
                color: black;
            }

            .ant-tabs-tab {
                color: black;
            }

            .ant-tabs-ink-bar {
                position: absolute;
                background: @primary-color;
                pointer-events: none;
            }

            .ant-tabs-tab-btn:focus,
            .ant-tabs-tab-remove:focus,
            .ant-tabs-tab-btn:active,
            .ant-tabs-tab-remove:active {
                color: black;
            }
        }

        .worker-holder {
            max-width: 180px;
            width: 100%;
            text-align: center;
            cursor: pointer;
            display: flex;
            flex-flow: column;

            @media screen and (max-width: 800px) {
                .signIn {
                    font-size: 16px;
                }

                p {
                    font-size: 16px !important;
                }

                .money-count {
                    font-size: 16px !important;

                    img {
                        margin-left: 5px;
                    }
                }
            }

            @media screen and (max-width: 420px) {
                max-width: 130px;
            }

            p {
                font-size: 20px;
                font-weight: bold;
                max-width: 160px;
                width: 100%;
                margin: 0px auto;
                margin-bottom: 5px;
            }

            .money-count > img {
                max-width: 25px;
            }

            .money-count {
                font-weight: bold;
                color: #57d22c;
                font-size: 24px;
            }
        }

        .all-workers-holder {
            display: grid;
            padding: 20px;
            animation-duration: 1s;
            animation-fill-mode: both;
            border: 1px solid #efe6fc;
            border-radius: 26px;
            background: white;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;
            grid-auto-rows: minmax(100px, auto);
            justify-items: center;

            @media screen and (max-width: 800px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media screen and (max-width: 600px) {
                padding: 10px;
            }

            @media screen and (max-width: 300px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .avatars-backg-holder {
                @media screen and (max-width: 800px) {
                    max-width: 180px;
                    height: 180px;
                    margin: 0px auto;

                    img {
                        max-width: 70%;
                    }
                }

                @media screen and (max-width: 420px) {
                    max-width: 120px;
                    min-height: 120px;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .selected {
            width: 100%;
            max-width: 220px;
            background: @primary-color;
            margin: 5px;
            border-radius: 26px;
            border: 1px solid @primary-color;
            display: flex;
            justify-content: center;
            padding-top: 27px;
            height: 100%;
            height: 190px;
            position: relative;

            img {
                max-width: 147px;
                position: absolute;
                bottom: 0;
            }

            @media screen and (max-width: 800px) {
                max-width: 180px;
                height: 180px;
                margin: 0px auto;

                img {
                    max-width: 70%;
                }
            }

            @media screen and (max-width: 420px) {
                max-width: 120px;
                min-height: 120px;
                width: 100%;
                height: 100%;
            }
        }

        .selected-team {
            display: grid;
            padding: 20px;
            animation-duration: 1s;
            animation-fill-mode: both;
            border: 1px solid #efe6fc;
            border-radius: 26px;
            background: white;
            width: 100%;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 10px;
            grid-auto-rows: minmax(100px, auto);
            justify-items: center;

            @media screen and (max-width: 720px) {
                display: inline-flex;
                justify-content: center;
            }
        }

        .selected-team-holder {
            border-radius: 20px;
            display: flex;
            justify-content: center;
            width: 120px;
            height: 110px;
            position: relative;
            cursor: pointer;

            @media screen and (max-width: 720px) {
                width: 100px;
                height: 90px;
                border-radius: 10px;

                img {
                    width: 60% !important;
                }
            }

            @media screen and (max-width: 620px) {
                width: 90px;
                height: 80px;
                border-radius: 10px;

                img {
                    width: 60% !important;
                }
            }

            @media screen and (max-width: 550px) {
                width: 90px;
                height: 80px;
                border-radius: 10px;

                img {
                    width: 60% !important;
                }

                &:nth-child(2) {
                    margin-left: -45px;
                }

                &:nth-child(3) {
                    margin-left: -45px;
                }

                &:nth-child(4) {
                    margin-left: -45px;
                }

                &:nth-child(5) {
                    margin-left: -45px;
                }
            }

            @media screen and (max-width: 350px) {
                width: 80px;
                height: 70px;
                border-radius: 10px;

                img {
                    width: 60% !important;
                }

                &:nth-child(2) {
                    margin-left: -50px;
                }

                &:nth-child(3) {
                    margin-left: -50px;
                }

                &:nth-child(4) {
                    margin-left: -50px;
                }

                &:nth-child(5) {
                    margin-left: -50px;
                }
            }

            .avatars-backg-holder {
                margin-bottom: 0;
                margin-top: 0;
                border-radius: 16px;
                height: 110px;

                @media screen and (max-width: 720px) {
                    height: 90px;
                    align-self: center;
                }

                @media screen and (max-width: 620px) {
                    height: 80px;
                    align-self: center;
                }

                @media screen and (max-width: 550px) {
                    height: 70px;
                    align-self: center;
                }
            }

            img {
                width: 70%;
                position: absolute;
                bottom: 0;
            }
        }

        .selected-employees {
            margin-top: 2%;

            p {
                margin: 0px auto;
                background: @primary-color;
                border-radius: 26px 26px 0px 0px;
                color: white;
                max-width: 180px;
                width: 100%;
                text-align: center;
                font-size: 18px;
                text-transform: uppercase;
                padding-bottom: 5px;
                padding-top: 5px;

                @media screen and (max-width: 320px) {
                    font-size: 15px;
                    max-width: 160px;
                }
            }
        }

        .tabs-holder {
            -webkit-animation-name: bounceIn;
            -moz-animation-name: bounceIn;
            -o-animation-name: bounceIn;
            animation-name: bounceIn;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-direction: alternate;
        }

        /*ANIMATIONS*/

        @-webkit-keyframes bounceIn {
            0% {
                opacity: 0;
                -webkit-transform: scale(0.3);
            }

            50% {
                -webkit-transform: scale(1.05);
            }

            70% {
                -webkit-transform: scale(0.9);
            }

            100% {
                opacity: 1;
                -webkit-transform: scale(1);
            }
        }

        @-moz-keyframes bounceIn {
            0% {
                opacity: 0;
                -moz-transform: scale(0.3);
            }

            50% {
                -moz-transform: scale(1.05);
            }

            70% {
                -moz-transform: scale(0.9);
            }

            100% {
                opacity: 1;
                -moz-transform: scale(1);
            }
        }

        @-o-keyframes bounceIn {
            0% {
                opacity: 0;
                -o-transform: scale(0.3);
            }

            50% {
                -o-transform: scale(1.05);
            }

            70% {
                -o-transform: scale(0.9);
            }

            100% {
                opacity: 1;
                -o-transform: scale(1);
            }
        }

        @keyframes bounceIn {
            0% {
                opacity: 0;
                transform: scale(0.3);
            }

            50% {
                transform: scale(1.05);
            }

            70% {
                transform: scale(0.9);
            }

            100% {
                opacity: 1;
                transform: scale(1);
            }
        }

        @-webkit-keyframes fadeInDown {
            0% {
                opacity: 0;
                -webkit-transform: translateY(-20px);
            }

            100% {
                opacity: 1;
                -webkit-transform: translateY(0);
            }
        }

        @-moz-keyframes fadeInDown {
            0% {
                opacity: 0;
                -moz-transform: translateY(-20px);
            }

            100% {
                opacity: 1;
                -moz-transform: translateY(0);
            }
        }

        @-o-keyframes fadeInDown {
            0% {
                opacity: 0;
                -o-transform: translateY(-20px);
            }

            100% {
                opacity: 1;
                -o-transform: translateY(0);
            }
        }

        @keyframes fadeInDown {
            0% {
                opacity: 0;
                transform: translateY(-20px);
            }

            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    .spinner-back {
        // background-color: fade(white, 60%);
        background-color: #fff;
        width: 100%;
        display: inline-block;
        opacity: 1;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        text-align: center;
        z-index: 111;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .spinner-non-back {
        display: none;
    }

    .ant-spin-dot-item {
        background-color: @primary-color;
    }
}
