@import '../../../../assets/style/_variables.less';

.office-equipment {
  width: 100%;
  margin: 0px auto;

  .office-equipment-section {
    width: 100%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: 40px;
    margin-bottom: @layout-footer-height + 94px;

    .signUp {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 1100px) {
      width: 95%;
    }

    @media screen and (max-width: 600px) {
      margin-bottom: @layout-footer-height + 59px;
    }

    @media screen and (max-width: 600px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: normal;
      font-family: @font-family;
      max-width: 800px;
      width: 98%;
      text-align: center;
      margin: 48px auto;
      // margin-bottom: 32px;

      @media screen and (max-width: 720px) {
        font-size: 30px;
      }

      @media screen and (max-width: 500px) {
        font-size: 24px;
        margin: 48px auto;
      }
    }

    .alert-p {
      width: 100%;
      max-width: 570px;
      border: 1px solid #FA4B3F;
      border-radius: 26px;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      color: black;
      padding: 32px;
      margin-bottom: 96px;
    }

    .allow-p {
      width: 100%;
      max-width: 570px;
      border: 1px solid #12df1c;
      border-radius: 26px;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: black;
      padding: 32px;
      margin-bottom: 96px;
    }

    .button-landing {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;
      right: 0;
      margin-bottom: @layout-footer-height;

      @media screen and (max-width: 600px) {
        padding-bottom: 7px;
        padding-top: 7px;
      }
    }

    .office-equipment-holder {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      grid-auto-rows: minmax(100px, auto);
      margin-bottom: 50px;
      width: 100%;
      max-width: 1100px;

      @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 400px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .equipment-holder {
      width: 100%;
      max-width: 250px;
      margin: 0px auto;
      cursor: pointer;

      img {
        max-width: 120px;
      }

      h2 {
        font-size: 20px;
        font-weight: bold;
        margin: 24px auto;
        margin-top: 0;
      }

      .selected {
        background: #57D22C;
        width: 100%;
        max-width: 250px;
        border-radius: 15px;
        border: 1px solid #57D22C;
        display: flex;
        justify-content: center;
        padding-top: 25px;
        padding-bottom: 25px;
        margin: 0px auto;
        max-height: 200px;
      }

      .green {
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 26px;
        color: #57d22c;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 35px;
          vertical-align: baseline;
          margin-left: 5px;
        }
      }

      .industry-backg-holder {
        padding-bottom: 40px;
      }

      .selected {
        padding-bottom: 40px;
      }
    }
  }
}