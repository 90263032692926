@import '../../../assets/style/_variables.less';

.verify-awards {
  width: 100%;
  margin: 0px auto;
  display: grid;
  align-content: center;

  .verify-section-awards {
    width: 95%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;

    h2 {
      font-weight: bold;
      font-size: 48px;
      line-height: 48px;
      font-family: @font-family;
      max-width: 794px;
      width: 100%;
      text-align: center;
      margin: 96px auto 24px auto;

      @media screen and (max-width: 800px) {
        font-size: 30px;
        line-height: 28px;
        margin: 56px auto 24px auto;
      }

      @media screen and (max-width: 500px) {
        font-size: 25px;
        margin: 24px auto 24px auto;
      }

      @media screen and (max-width: 400px) {
        font-size: 18px;
      }
    }

    p {
      font-size: 28px;

      @media screen and (max-width: 720px) {
        font-size: 20px;
      }
    }

    .text-holder {
      border: 1px solid #57d22c;
      border-radius: 24px;
      padding: 10px;
      margin-top: 0;
      background-color: white;
      animation-duration: 2s;
      animation-fill-mode: both;
      max-width: 1200px;
      width: 100%;

      @media screen and (max-width: 800px) {
        padding: 20px;
      }
    }

    .signUp {
      margin: 0px auto 96px auto;

      @media screen and (max-width: 800px) {
        margin: 0px auto 56px auto;
      }

      @media screen and (max-width: 500px) {
        font-size: 15px;
        margin: 0px auto 24px auto;
      }
    }

  }

  .fadeInBottom-c {
    animation-name: fadeInBottom-c;
  }

  @keyframes fadeInBottom-c {
    from {
      opacity: 0;
      transform: translateY(50%);
    }

    to {
      opacity: 1;
    }
  }

}