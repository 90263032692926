@import '../../../assets/style/_variables.less';

.sequence-board-info {
  width: 100%;
  margin: 0px auto;
  display: grid;
  align-content: center;
  background-image: url('../../../assets/images/bg10.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: white;
  background-attachment: fixed;

  .sequence-board-info-section {
    width: 95%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-bottom: @layout-footer-height * 2 + 94px;

    @media screen and (max-width: 800px) {
      margin-top: @layout-footer-height;
      margin-bottom: @layout-footer-height * 2 + 59px;
    }

    @media screen and (max-width: 800px) {
      .signIn {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }

      .buttonMakeAcc {
        padding: 5px 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    h2 {
      -webkit-animation-name: bounceInLeft;
      -moz-animation-name: bounceInLeft;
      -o-animation-name: bounceInLeft;
      animation-name: bounceInLeft;
    }

    .fadeFirst {
      -webkit-animation-name: bounceInRight;
      -moz-animation-name: bounceInRight;
      -o-animation-name: bounceInRight;
      animation-name: bounceInRight;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-delay: 1s;
      animation-direction: alternate;
    }

    .sequence-board-info-text {
      color: black;
      width: 100%;
      padding-bottom: 30px;

      h3 {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 22px;
        text-align: left;
        color: #ffffff;
        margin: 0px 0px 32px 0px;
        padding: 32px;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;

        @media screen and (max-width: 800px) {
          font-size: 22px;
        }
      }

      .fadeFirst {
        -webkit-animation-name: bounceInRight;
        -moz-animation-name: bounceInRight;
        -o-animation-name: bounceInRight;
        animation-name: bounceInRight;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-delay: 1s;
        animation-direction: alternate;
      }

      @-webkit-keyframes fadeFirst {
        from {
          opacity: 0;
          transform: translateY(20%);
        }

        to {
          opacity: 1;
        }
      }

      @keyframes fadeFirst {
        from {
          opacity: 0;
          transform: translateY(20%);
        }

        to {
          opacity: 1;
        }
      }
    }

    .fadeSecond {
      animation-name: fadeSecond;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-delay: 3s;
      animation-direction: alternate;
    }

    @-webkit-keyframes fadeSecond {
      from {
        opacity: 0;
        transform: translateY(20%);
      }

      to {
        opacity: 1;
      }
    }

    @keyframes fadeSecond {
      from {
        opacity: 0;
        transform: translateY(20%);
      }

      to {
        opacity: 1;
      }
    }

    .text-info {
      width: 100%;
      padding: 0px 32px 0px;

      .sequence-board-info-talk-mentor {
        display: inline-grid;
        grid-template-columns: auto 1fr;
        grid-gap: 5px;
        font-family: @font-family-secondary;
        font-size: 18px;
        width: 100%;

        @media screen and (max-width: 600px) {
          font-size: 16px;
        }

        .avatars-backg-holder {
          width: 82px;
          height: 82px;
          display: grid;
          padding-top: 17px;
          border-radius: 20px;
          margin: 0px auto;
          margin-right: 19px;
          grid-column: 1;
          align-items: end;
          -webkit-animation-name: bounceInRight;
          -moz-animation-name: bounceInRight;
          -o-animation-name: bounceInRight;
          animation-name: bounceInRight;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-delay: 1s;
          animation-direction: alternate;

          @-webkit-keyframes fadeFirst {
            from {
              opacity: 0;
              transform: translateY(20%);
            }

            to {
              opacity: 1;
            }
          }

          @keyframes fadeFirst {
            from {
              opacity: 0;
              transform: translateY(20%);
            }

            to {
              opacity: 1;
            }
          }

          img {
            width: 100%;
            max-width: 50px;
            // top: 1.5px;
            position: relative;
          }
        }

        .hi-msg {
          grid-column: 1 / 2;
          text-align: left;
          background-color: @primary-color;
          color: #ffffff;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-right-radius: 26px;
          max-width: 660px;
          align-self: baseline;
          display: grid;
          align-items: center;
          height: auto;

          @media screen and (max-width: 600px) {
            padding: 16px;
            font-size: 14px;
          }
        }

        .expl-msg {
          grid-column: 2;
          text-align: left;
          background-color: @primary-color;
          color: #ffffff;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-right-radius: 26px;
          max-width: 660px;
          line-height: 21px;

          @media screen and (max-width: 600px) {
            padding: 16px;
            font-size: 14px;
          }
        }
      }

      .sequence-board-info-talk-user {
        display: inline-grid;
        grid-template-columns: 1fr auto;
        grid-gap: 5px;
        font-family: @font-family-secondary;
        font-size: 18px;
        line-height: 21px;
        width: 100%;

        @media screen and (max-width: 600px) {
          font-size: 16px;
        }

        .avatars-backg-holder {
          width: 82px;
          height: 82px;
          display: grid;
          padding-top: 17px;
          align-items: end;
          border-radius: 20px;
          margin: 0px auto;
          margin-left: 19px;
          grid-column: 2;
          grid-row: 1;
          max-width: 82px;
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-delay: 3s;
          animation-direction: alternate;

          img {
            width: 100%;
            max-width: 50px;
            position: relative;
          }
        }

        .fadeThird {
          -webkit-animation-name: bounceInLeft;
          -moz-animation-name: bounceInLeft;
          -o-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
          animation-duration: 1s;
          animation-fill-mode: both;
          animation-delay: 3s;
          animation-direction: alternate;
        }

        .hi-msg-user {
          grid-column: 1;
          grid-row: 1;
          justify-self: end;
          text-align: left;
          background-color: #EFE6FC;
          color: #000000;
          padding: 24px;
          border-top-left-radius: 26px;
          border-top-right-radius: 26px;
          border-bottom-left-radius: 26px;
          max-width: 660px;

          @media screen and (max-width: 600px) {
            padding: 16px;
            font-size: 14px;
          }
        }
      }
    }

    .sequence-board-info-text2 {
      color: black;
      width: 100%;
      padding: 38px;
      margin-top: 32px;

      .info-timer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 55px;

        @media screen and (max-width: 500px) {
          flex-wrap: wrap;
          justify-content: center;
        }

        .timer-image {
          max-width: 200px;
          width: 100%;
          display: flex;
          align-items: center;

          img {
            margin: 0px auto;

            @media screen and (max-width: 500px) {
              max-width: 80px;
              margin: 0px auto;
            }
          }

          @media screen and (max-width: 800px) {
            max-width: 120px;
          }
        }

        .timer-text {
          max-width: 500px;
          width: 100%;
          margin-left: 20px;

          @media screen and (max-width: 500px) {
            margin-left: 0px;
          }
        }
      }

      .fadeFourth {
        -webkit-animation-name: fadeFirst;
        -moz-animation-name: fadeFirst;
        -o-animation-name: fadeFirst;
        animation-name: fadeFirst;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-delay: 8s;
        animation-direction: alternate;
      }

      .fadeFifth {
        -webkit-animation-name: bounceInLeft;
        -moz-animation-name: bounceInLeft;
        -o-animation-name: bounceInLeft;
        animation-name: bounceInLeft;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-delay: 10s;
        animation-direction: alternate;
      }

      .fadeSixs {
        -webkit-animation-name: bounceInRight;
        -moz-animation-name: bounceInRight;
        -o-animation-name: bounceInRight;
        animation-name: bounceInRight;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-delay: 12s;
        animation-direction: alternate;
      }

      .fadeSeven {
        -webkit-animation-name: bounceInLeft;
        -moz-animation-name: bounceInLeft;
        -o-animation-name: bounceInLeft;
        animation-name: bounceInLeft;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-delay: 14s;
        animation-direction: alternate;
      }

      h3 {
        font-family: @font-family;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 32px;
        text-align: left;
        color: #000000;
        margin: 0px 0px 32px 0px;

        @media screen and (max-width: 800px) {
          font-size: 18px;
        }

        @media screen and (max-width: 500px) {
          text-align: center;
          margin: 24px 0px 16px 0px;
        }
      }

      p {
        text-align: left;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        margin: 0;

        @media screen and (max-width: 800px) {
          font-size: 18px;
        }

        @media screen and (max-width: 500px) {
          text-align: center;
        }
      }
    }

    h2 {
      font-weight: bold;
      font-size: 48px;
      line-height: normal;
      font-family: @font-family;
      max-width: 1200px;
      text-align: center;
      margin: 48px auto;
      animation-duration: 2s;
      animation-fill-mode: both;
      width: 100%;

      @media screen and (max-width: 800px) {
        font-size: 30px;
        margin: 0px 0px 20px 0px;
      }

      @media screen and (max-width: 500px) {
        font-size: 22px;
      }
    }

    .button-landing {
      position: fixed;
      bottom: 0;
      width: 100%;
      background-color: white;
      padding-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #d4d4d4;
      right: 0;
      margin-bottom: @layout-footer-height;

      @media screen and (max-width: 800px) {
        padding-bottom: 7px;
        padding-top: 7px;
      }
    }

    .fadeInlast {
      animation-name: fadeFirst;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-delay: 15s;
      animation-direction: alternate;
    }

    @-webkit-keyframes fadeFirst {
      from {
        opacity: 0;
        transform: translateY(20%);
      }

      to {
        opacity: 1;
      }
    }

    @keyframes fadeFirst {
      from {
        opacity: 0;
        transform: translateY(20%);
      }

      to {
        opacity: 1;
      }
    }

    .sequence-board-info-holder {
      animation-duration: 2s;
      animation-fill-mode: both;
      display: flex;
      justify-content: space-between;
      max-width: 828px;
      width: 100%;
      flex-flow: column;

      @media screen and (max-width: 800px) {
        padding: 10px 10px;
        display: block;
      }
    }
  }


  @-webkit-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-2000px);
    }

    60% {
      -webkit-transform: translateX(20px);
    }

    80% {
      -webkit-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -moz-transform: translateX(-2000px);
    }

    60% {
      -moz-transform: translateX(20px);
    }

    80% {
      -moz-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInLeft {
    0% {
      opacity: 0;
      -o-transform: translateX(-2000px);
    }

    60% {
      opacity: 1;
      -o-transform: translateX(20px);
    }

    80% {
      -o-transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInLeft {
    0% {
      opacity: 0;
      transform: translateX(-2000px);
    }

    60% {
      transform: translateX(20px);
    }

    80% {
      transform: translateX(-5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }


  @-webkit-keyframes bounceInRight {
    0% {
      opacity: 0;
      -webkit-transform: translateX(2000px);
    }

    60% {
      -webkit-transform: translateX(-20px);
    }

    80% {
      -webkit-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  @-moz-keyframes bounceInRight {
    0% {
      opacity: 0;
      -moz-transform: translateX(2000px);
    }

    60% {
      -moz-transform: translateX(-20px);
    }

    80% {
      -moz-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -moz-transform: translateX(0);
    }
  }

  @-o-keyframes bounceInRight {
    0% {
      opacity: 0;
      -o-transform: translateX(2000px);
    }

    60% {
      -o-transform: translateX(-20px);
    }

    80% {
      -o-transform: translateX(5px);
    }

    100% {
      opacity: 1;
      -o-transform: translateX(0);
    }
  }

  @keyframes bounceInRight {
    0% {
      opacity: 0;
      transform: translateX(2000px);
    }

    60% {
      transform: translateX(-20px);
    }

    80% {
      transform: translateX(5px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }


}