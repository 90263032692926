@import '../../../assets/style/_variables.less';

.verify-email {
  width: 100%;
  margin: 0px auto;
  display: grid;
  align-content: center;

  .verify-email-section {
    width: 95%;
    display: block;
    margin: 0px auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin-top: @layout-footer-height *2;
    margin-bottom: @layout-footer-height * 2;

    @media screen and (max-width: 500px) {
      margin-bottom: @layout-footer-height * 2;
    }

    h2 {
      font-weight: bold;
      font-size: 26px;
      line-height: 36px;
      font-family: @font-family;
      max-width: 865px;
      text-align: center;
      margin: 24px auto 24px auto;
      margin-bottom: 24px;

      @media screen and (max-width: 1000px) {
        font-size: 35px;
        max-width: 665px;
      }

      @media screen and (max-width: 720px) {
        font-size: 30px;
        max-width: 480px;
        margin: 56px auto 24px auto;
        line-height: 28px;
      }

      @media screen and (max-width: 500px) {
        font-size: 25px;
        margin: 24px auto;
      }
    }

    p {
      font-size: 28px;
      line-height: 33px;
      margin: 0px auto;
      margin-bottom: 24px;
      font-weight: bold;
      font-family: @font-family-secondary;
      font-style: normal;
      text-align: center;
      max-width: 865px;
      width: 100%;

      @media screen and (max-width: 1000px) {
        font-size: 25px;
        max-width: 665px;
      }

      @media screen and (max-width: 720px) {
        font-size: 20px;
        max-width: 480px;
      }

      @media screen and (max-width: 500px) {
        font-size: 17px;
        line-height: 28px;
      }
    }

    .verify-holder {
      background: #ffffff;
      border: 1px solid #efe6fc;
      border-radius: 24px;
      animation-duration: 2s;
      animation-fill-mode: both;
      max-width: 1200px;
      width: 100%;

      @media screen and (max-width: 800px) {
        padding: 10px;
      }
    }

    .buttonMakeAcc {
      margin: 0px auto 96px auto;

      @media screen and (max-width: 720px) {
        margin: 0px auto 56px auto;
      }

      @media screen and (max-width: 500px) {
        margin: 0px auto 24px auto;
      }
    }

    .fadeInBottom-verify {
      animation-name: fadeInBottom-verify;
    }

    @keyframes fadeInBottom-verify {
      from {
        opacity: 0;
        transform: translateY(100%);
      }

      to {
        opacity: 1;
      }
    }

  }
}