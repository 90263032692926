.ant-modal {
  width: 600px !important;
}

.ant-modal-body {
  background: #f4f6f9;
}

.modal-title-container {
  display: flex;
  align-items: center;
}

.modal-input-container {
  margin-top: 20px;
  
  .modal-input-label {
    color: #000;
    font-size: 15px;
    margin-left: 20px;
  }

  .modal-input {
    background: #ffffff; 
    padding: 15px; 
    margin-top: 10px;
  }
}

.modal-checkbox-container {
  .ant-checkbox-wrapper {
    font-size: 17px;
    margin: 20px 10px;
    color: #000;
  }
}

.modal-switch-container {
    font-size: 17px;
    margin: 20px 10px;
    color: #000;
}

